/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  select: {
    '& span': {
      fontWeight: '600'
    }
  },
  selectTargetType: {
    width: '100%',
    '& .MuiSelect-root': {
      padding: '9px 0 1px 0px'
    }
  },

  selectTargetTypeInWorkoutPlan: {
    width: '100%',
    '& .MuiSelect-root': {
      padding: '2px 0 1px 0px'
    }
  },

  formControl: {
    width: '100%',
    marginTop: '7px'
  },
  unitsBGColor: {
    // backgroundColor: '#A4A4A4',
    borderRadius: '0px 4px 4px 0px'
  },
  priamryTextColor: {
    color: theme.palette.primary.main
  },
  secondaryTextColor: {
    color: theme.palette.secondary.main
  }
}));

const convertSecondsToPace = totalSeconds => {
  if (isNaN(totalSeconds)) return totalSeconds;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const paddedSeconds = seconds < 10 ? '0' + seconds : seconds;
  return `${minutes}:${paddedSeconds}`;
};

const convertPaceToSeconds = (minutes, seconds) => minutes * 60 + seconds;

function WorkoutInputFields(props) {
  const { step, isRunner } = props;

  // const { step, trigger } = props;
  // useEffect(() => {
  //   if (
  //     !step.durationType &&
  //     !step.durationValue &&
  //     !step.durationValueType &&
  //     !step.targetType
  //   ) {
  //     setDurationType('');
  //     setUnits('');
  //     setDuration('');
  //     // setPace('EASY');
  //     // setHR(1);
  //     setTargetType('OPEN');
  //   }
  // }, [trigger]);

  const classes = useStyles();
  const [durationType, setDurationType] = useState(step?.durationType);
  const [units, setUnits] = useState(step?.durationValueType);
  const [duration, setDuration] = useState(step?.durationValue);
  const [targetType, setTargetType] = useState(step?.targetType);
  const [HR, setHR] = useState(step?.targetValue || 1);
  const [pace, setPace] = useState(step?.targetValue || 'EASY');
  const [runnerPace, setRunnerPace] = useState(
    convertSecondsToPace(step.runnerPace)
  );

  useEffect(() => {
    setDurationType(step?.durationType || '');
    setUnits(step?.durationValueType || '');
    setDuration(step?.durationValue || '');
    setTargetType(step?.targetType || 'OPEN');
    setHR(step?.targetValue || 1);
    setPace(step?.targetValue || 'EASY');
  }, [JSON.stringify(step)]);

  const handleDurationTypeChange = event => {
    step.durationType = event.target.value;
    if (step.durationType == 'TIME') {
      setUnits('MINUTE');
      step.durationValueType = 'MINUTE';
    }
    if (step.durationType == 'DISTANCE') setUnits(step.durationValueType || '');

    if (step.durationType == 'OPEN') {
      setUnits('');
      setDuration('');
    }
    step.durationValue = '';
    setDuration('');
    setDurationType(event.target.value);
  };

  const handleDurationChange = event => {
    const durationValue = event.target.value;
    step.durationValue = durationValue;
    setDuration(durationValue);
  };

  const handleRunnerPaceChange = event => {
    const runnerPaceValue = event.target.value;
    setRunnerPace(runnerPaceValue);
    const [mins, secs] = runnerPaceValue.split(':');
    const runnerPaceInSeconds = convertPaceToSeconds(
      parseInt(mins),
      parseInt(secs)
    );
    if (runnerPaceInSeconds) step.runnerPace = runnerPaceInSeconds;
  };

  const handleUnitsChange = event => {
    step.durationValueType = event.target.value;
    setUnits(event.target.value);
  };

  const handleTargetTypeChange = event => {
    step.targetValue = event.target.value === 'HEART_RATE' ? 1 : 'EASY';
    step.targetType = event.target.value;
    setTargetType(event.target.value);
  };

  const handleTargetValueChange = event => {
    step.targetValue = event.target.value;
    if (targetType == 'PACE') setPace(event.target.value);
    if (targetType == 'HEART_RATE') setHR(event.target.value);
    // if (targetType == 'OPEN') setTargetValue('');

    const HRZones = [
      'Zone 1 (Warm-Up)',
      'Zone 2 (Easy)',
      'Zone 3 (Aerobic)',
      'Zone 4 (Threshold)',
      'Zone 5 (Maximum)'
    ];

    if (step.runnerPace) step.runnerPace = HRZones[event.target.value - 1];
  };

  // const handlePaceChange = event => {
  //   step.targetType = 'PACE';
  //   step.targetValue = event.target.value;
  //   setPace(event.target.value);
  // };

  if (step.targetValue == 0) step.targetValue = '0';

  const PaceSelect = () => {
    if (
      ![
        '',
        'EASY',
        'REST',
        60,
        55,
        50,
        45,
        40,
        35,
        30,
        25,
        20,
        15,
        10,
        5,
        '0',
        0,
        -5,
        -10,
        -15,
        -20,
        -25,
        -30,
        -35,
        -40,
        -45,
        -50,
        -55,
        -60
      ].includes(pace)
    )
      return <></>;

    return (
      <div className={classes.select}>
        {/* <Typography variant="h6">Pace</Typography> */}
        <FormControl variant="outlined" className={classes.formControl}>
          {/* <InputLabel id="workoutInputFields-setPace-inputLabel" margin="dense">
            Set Pace
          </InputLabel> */}
          <Select
            data-test={'workoutInputFields-setPace-select'}
            labelId="workoutInputFields-setPace-select"
            id="workoutInputFields-setPace-select"
            margin="dense"
            label="Set Pace"
            value={pace}
            disabled={isRunner}
            onChange={handleTargetValueChange}>
            <MenuItem
              data-test={'workoutInputFields-setPace-menuItem-easy'}
              className={classes.secondaryTextColor}
              value={'EASY'}>
              Easy
            </MenuItem>
            <MenuItem
              data-test={'workoutInputFields-setPace-menuItem-rest'}
              className={classes.secondaryTextColor}
              value={'REST'}>
              Rest
            </MenuItem>
            <Divider />
            <MenuItem className={classes.secondaryTextColor} value={60}>
              +60s
            </MenuItem>
            <MenuItem className={classes.secondaryTextColor} value={55}>
              +55s
            </MenuItem>
            <MenuItem className={classes.secondaryTextColor} value={50}>
              +50s
            </MenuItem>
            <MenuItem className={classes.secondaryTextColor} value={45}>
              +45s
            </MenuItem>
            <MenuItem className={classes.secondaryTextColor} value={40}>
              +40s
            </MenuItem>
            <MenuItem className={classes.secondaryTextColor} value={35}>
              +35s
            </MenuItem>
            <MenuItem className={classes.secondaryTextColor} value={30}>
              +30s
            </MenuItem>
            <MenuItem className={classes.secondaryTextColor} value={25}>
              +25s
            </MenuItem>
            <MenuItem className={classes.secondaryTextColor} value={20}>
              +20s
            </MenuItem>
            <MenuItem className={classes.secondaryTextColor} value={15}>
              +15s
            </MenuItem>
            <MenuItem
              data-test={'workoutInputFields-setPace-menuItem-+10s'}
              className={classes.secondaryTextColor}
              value={10}>
              +10s
            </MenuItem>
            <MenuItem
              data-test={'workoutInputFields-setPace-menuItem-+5s'}
              className={classes.secondaryTextColor}
              value={5}>
              +5s
            </MenuItem>
            <MenuItem
              data-test={'workoutInputFields-setPace-menuItem-basePace'}
              className={classes.secondaryTextColor}
              value={0}>
              0 (Base Pace)
            </MenuItem>
            <MenuItem
              data-test={'workoutInputFields-setPace-menuItem--5s'}
              className={classes.secondaryTextColor}
              value={-5}>
              -5s
            </MenuItem>
            <MenuItem
              data-test={'workoutInputFields-setPace-menuItem--10s'}
              className={classes.secondaryTextColor}
              value={-10}>
              -10s
            </MenuItem>
            <MenuItem className={classes.secondaryTextColor} value={-15}>
              -15s
            </MenuItem>
            <MenuItem className={classes.secondaryTextColor} value={-20}>
              -20s
            </MenuItem>
            <MenuItem className={classes.secondaryTextColor} value={-25}>
              -25s
            </MenuItem>
            <MenuItem className={classes.secondaryTextColor} value={-30}>
              -30s
            </MenuItem>
            <MenuItem className={classes.secondaryTextColor} value={-35}>
              -35s
            </MenuItem>
            <MenuItem className={classes.secondaryTextColor} value={-40}>
              -40s
            </MenuItem>
            <MenuItem className={classes.secondaryTextColor} value={-45}>
              -45s
            </MenuItem>
            <MenuItem className={classes.secondaryTextColor} value={-50}>
              -50s
            </MenuItem>
            <MenuItem className={classes.secondaryTextColor} value={-55}>
              -55s
            </MenuItem>
            <MenuItem className={classes.secondaryTextColor} value={-60}>
              -60s
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  };

  const HeartRateZoneSelect = value => {
    if (!['', 1, 2, 3, 4, 5].includes(HR)) return <></>;

    return (
      <div className={classes.select}>
        {/* <Typography variant="h6">Heart Rate Zone</Typography> */}
        <FormControl variant="outlined" className={classes.formControl}>
          {/* <InputLabel
            id="workoutInputFields-setHRZone-inputLabel"
            margin="dense">
            Set Heart Rate Zone
          </InputLabel> */}
          <Select
            data-test={'workoutInputFields-setHRZone-select'}
            labelId="workoutInputFields-setHRZone-select"
            id="workoutInputFields-setHRZone-select"
            margin="dense"
            label="Set HR Zone"
            value={HR}
            disabled={isRunner}
            onChange={handleTargetValueChange}>
            <MenuItem
              data-test={'workoutInputFields-setHRZones-menuItem-zone1'}
              className={classes.secondaryTextColor}
              value={1}>
              Zone 1 (Warm-Up)
            </MenuItem>
            <MenuItem
              data-test={'workoutInputFields-setHRZones-menuItem-zone2'}
              className={classes.secondaryTextColor}
              value={2}>
              Zone 2 (Easy)
            </MenuItem>
            <MenuItem
              data-test={'workoutInputFields-setHRZones-menuItem-zone3'}
              className={classes.secondaryTextColor}
              value={3}>
              Zone 3 (Aerobic)
            </MenuItem>
            <MenuItem
              data-test={'workoutInputFields-setHRZones-menuItem-zone4'}
              className={classes.secondaryTextColor}
              value={4}>
              Zone 4 (Threshold)
            </MenuItem>
            <MenuItem
              data-test={'workoutInputFields-setHRZones-menuItem-zone5'}
              className={classes.secondaryTextColor}
              value={5}>
              Zone 5 (Maximum)
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <div className={classes.select}>
          <Typography variant="h6">Duration Type</Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              id="workoutInputFields-selectDurationType-inputLabel"
              margin="dense">
              Select Duration Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="workoutInputFields-selectDurationType-select"
              data-test="workoutInputFields-selectDurationType-select"
              margin="dense"
              label="Select Duration Type"
              disabled={isRunner}
              value={durationType || ''}
              onChange={handleDurationTypeChange}>
              <MenuItem
                data-test={'workoutInputFields-durationType-distance'}
                value={'DISTANCE'}>
                Distance
              </MenuItem>
              <MenuItem
                data-test={'workoutInputFields-durationType-time'}
                value={'TIME'}>
                Time
              </MenuItem>
              <MenuItem
                data-test={'workoutInputFields-durationType-open'}
                value={'OPEN'}>
                Until Lap Press
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </Grid>
      <Grid item xs={12} sm={4}>
        <div className={classes.select}>
          <Typography variant="h6">Duration</Typography>
          <Grid container spacing={0}>
            <Grid item xs={8} sm={8}>
              <TextField
                data-test={'workoutInputFields-setDuration'}
                disabled={
                  durationType == 'OPEN' ||
                  step.durationType == 'OPEN' ||
                  (!units && !step.durationValue)
                }
                fullWidth
                disabled={isRunner}
                variant="outlined"
                margin="dense"
                value={duration || ''}
                onChange={handleDurationChange}
                style={{ marginTop: '7px' }}
                color="primary"
                label={'Set Duration'}
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <FormControl
                variant="outlined"
                className={`${classes.formControl} ${classes.unitsBGColor}`}>
                <InputLabel
                  disabled={
                    !durationType ||
                    durationType == 'OPEN' ||
                    step.durationType == 'OPEN'
                  }
                  id="demo-simple-select-outlined-label"
                  margin="dense">
                  Units
                </InputLabel>
                <Select
                  data-test={'workoutInputFields-selectUnits-select'}
                  disabled={
                    isRunner || !durationType ||
                    durationType == 'OPEN' ||
                    step.durationType == 'OPEN'
                  }
                  labelId="workoutInputFields-selectUnits-select"
                  id="workoutInputFields-selectUnits-select"
                  margin="dense"
                  label="Units"
                  value={units || ''}
                  onChange={handleUnitsChange}>
                  {durationType === 'DISTANCE' && [
                    <MenuItem
                      key="meter"
                      data-test={'workoutInputFields-units-meter'}
                      className={classes.secondaryTextColor}
                      value={'METER'}>
                      meters
                    </MenuItem>,
                    <MenuItem
                      key="kilometer"
                      data-test={'workoutInputFields-units-kilometer'}
                      className={classes.secondaryTextColor}
                      value={'KILOMETER'}>
                      km
                    </MenuItem>
                  ]}
                  {durationType === 'TIME' && (
                    <MenuItem
                      key="minute"
                      data-test={'workoutInputFields-units-minute'}
                      className={classes.secondaryTextColor}
                      value={'MINUTE'}>
                      minutes
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} sm={4}>
        {!step.runnerPace ? (
          <FormControl
            className={classes.formControl}
            style={{ marginTop: '-7px' }}>
            <Select
              disabled={isRunner}
              data-test={'workoutInputFields-selectTargetType-select'}
              disabled={
                (step.durationType === 'DISTANCE' ||
                  step.durationType === 'TIME') &&
                !step.durationValue
              }
              value={targetType || 'OPEN'}
              onChange={handleTargetTypeChange}
              displayEmpty
              className={classes.selectTargetType}>
              <MenuItem value="" disabled>
                Target Type
              </MenuItem>
              <MenuItem
                data-test={'workoutInputFields-selectTargetType-menuItem-open'}
                value={'OPEN'}>
                No Target
              </MenuItem>
              <MenuItem
                data-test={'workoutInputFields-selectTargetType-menuItem-pace'}
                value={'PACE'}>
                Pace
              </MenuItem>
              <MenuItem
                data-test={
                  'workoutInputFields-selectTargetType-menuItem-heartRate'
                }
                value={'HEART_RATE'}>
                HR Zones
              </MenuItem>
            </Select>
            {targetType == 'OPEN' && <></>}
            {targetType == 'PACE' && <PaceSelect />}
            {targetType == 'HEART_RATE' && <HeartRateZoneSelect />}
          </FormControl>
        ) : (
          <></>
        )}
        {step.runnerPace ? (
          <>
            {/* <Typography className={classes.priamryTextColor} variant="h6">
              Pace
            </Typography> */}
            <Select
              data-test={'workoutInputFields-selectTargetType-select'}
              disabled={
                isRunner || (step.durationType === 'DISTANCE' ||
                  step.durationType === 'TIME') &&
                !step.durationValue
              }
              
              value={targetType || 'OPEN'}
              onChange={handleTargetTypeChange}
              displayEmpty
              className={classes.selectTargetTypeInWorkoutPlan}>
              <MenuItem value="" disabled>
                Target Type
              </MenuItem>
              <MenuItem
                data-test={'workoutInputFields-selectTargetType-menuItem-open'}
                value={'OPEN'}>
                No Target
              </MenuItem>
              <MenuItem
                data-test={'workoutInputFields-selectTargetType-menuItem-pace'}
                value={'PACE'}>
                Pace
              </MenuItem>
              <MenuItem
                data-test={
                  'workoutInputFields-selectTargetType-menuItem-heartRate'
                }
                value={'HEART_RATE'}>
                HR Zones
              </MenuItem>
            </Select>
            {targetType == 'OPEN' && <></>}
            {step.targetType == 'HEART_RATE' && <HeartRateZoneSelect />}
            {targetType == 'PACE' && (
              <TextField
                disabled={isRunner}
                fullWidth
                className={classes.priamryTextColor}
                data-test={'workoutInputFields-runnerPaceTextField'}
                variant="outlined"
                margin="dense"
                value={convertSecondsToPace(runnerPace)}
                onChange={handleRunnerPaceChange}
                style={{ marginTop: '7px' }}
                label={'Runner Pace'}
              />
            )}
          </>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
}

export default WorkoutInputFields;
