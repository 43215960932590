// dont worry if you dont understand this code, only worry about the defaults
// its made so you dont have to think about it
// it just works and wont need to be touched ever again

const defaults = {
  startDay: 0
};

export default class Preferences {
  #server;
  #allowServerSetting;
  #options;
  constructor(server) {
    this.#server = server;
    this.#allowServerSetting = false;
    const rawStoredPreferences = localStorage.getItem('preferences');
    this.#loadPreferences(rawStoredPreferences);
  }

  #loadPreferences = (json, useDefaults = true) => {
    const defaults = useDefaults ? this.defaults() : this.#options;
    let preferences;
    try {
      preferences = JSON.parse(json);
    } catch (error) {
      preferences = null;
    }

    if (!preferences) {
      this.#setPreference(defaults);

      preferences = defaults;
    }

    Object.keys(defaults).forEach(key => {
      if (preferences[key] === undefined) {
        preferences[key] = defaults[key];
      }
      if (typeof preferences[key] !== typeof defaults[key]) {
        preferences[key] = defaults[key];
      }
    });

    Object.keys(preferences).forEach(key => {
      const defaultKeys = Object.keys(defaults);
      if (!defaultKeys.includes(key)) {
        delete preferences[key];
      }
    });

    this.#setPreference(preferences);

    this.#options = { ...defaults, ...preferences };
  };

  #setPreference = preferences => {
    localStorage.setItem('preferences', JSON.stringify(preferences));

    if (this.#allowServerSetting) {
      this.#server.post('/preferences', preferences);
    }
  };

  loadUserPreferences() {
    this.#server.get('/preferences').then(res => {
      if (!res.success) {
        return;
      }

      this.#allowServerSetting = true;

      this.#loadPreferences(res.value, false);
    });
  }

  defaults() {
    return defaults;
  }
  set(key, value) {
    this.#options[key] = value;
    this.#setPreference(this.#options);
  }
  get(key) {
    return this.#options[key];
  }

  list() {
    return this.#options;
  }

  reset() {
    this.#options = this.defaults();
    this.#setPreference(this.#options);
  }
}
