import { Button, CardActions, CardContent, CardHeader, Divider, Card, Typography, Dialog, DialogActions, DialogContentText, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core'
import { ErrorRounded } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { displayNotification } from 'api/displayNotification'
import server from 'api/server'
import logOut from 'common/logOut'
import UserContext from 'context/User'
import React, { useEffect, useState } from 'react'


const DeleteAccount = ({subscriptionStatus, loading}) => {
  const [canDelete, setCanDelete] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [timeRemaining, setTimeRemaining] = useState(0)
  const [cancelling, setCancelling] = useState(false)
  const [isActiveSubscription, setIsActiveSubscription] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const user = React.useContext(UserContext)
  function Cancel() {
    setIsDialogOpen(false)
    if (timeRemaining <= 0) return
    setCancelling(true)
  }

  function openDialog() {
    if (cancelling) return
    setTimeRemaining(5)
    setCanDelete(false)
    setIsDialogOpen(true)
  }

  const deleteAccount = async () => {
    setIsDeleting(true)
    setIsDialogOpen(false)
    const response = await server.post('/deleteAccount', {}, { validateStatus: () => true })
    logOut()
  }

  useEffect(() => {
    if (cancelling) {
      setCancelling(false)
      return
    }
    if (timeRemaining > 0) {
      setTimeout(() => {
        setTimeRemaining(timeRemaining - 1)
      }, 1000)
    } else {
      setCanDelete(true)
    }
  }, [timeRemaining])

  useEffect(() => {
    setIsActiveSubscription(subscriptionStatus === 'ACTIVE')
  }, [subscriptionStatus])

  return (
    <>
    <Card >
      <CardHeader subheader="Delete your account from Sub.cx" title="Delete Acccount"/>
      <Divider />
      <CardContent style={{ display: 'grid', gridTemplateRows: '1fr auto' }}>
        <Typography variant="body1">
          Completely Delete your account from Sub.cx <br/>
          <span style={{ color: 'red' }}>Warning, this action cannot be undone</span>
        </Typography>
        { isActiveSubscription &&
        <Typography variant="body1" style={{ color: 'red' }}>
          you must unsubscribe before you delete your account
        </Typography>
        }
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          style={(loading || isActiveSubscription) ? {} : { color: 'red', borderColor: 'red' }}
          onClick={openDialog}
          disabled={loading || isActiveSubscription}
          variant="outlined">
          Delete Account
        </Button>
        {loading &&
        <CircularProgress size={20}/>
        }
      </CardActions>
    </Card>
    <Dialog open={isDialogOpen} >
      <DialogTitle><ErrorRounded/> Delete Account</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete your account? <br/>
          {user.role == 'trainer' &&
          <>
          <br/>
          ALL FOLLOWING DATA WILL BE DELETED: <br/>
            <ul style={{ listStyleType: 'circle' }}>
              <li>Your Runners List</li>
              <li>Activities</li>
              <li>Workouts</li>
              <li>Workout Templates</li>
              <li>Workout Plans</li>
            </ul>
          </>
          }
          <span style={{ color: 'red' }}>Warning! This action is irreversible!</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <Button
          style={canDelete || loading || isActiveSubscription ? { color: 'red', borderColor: 'red'} : {}}
          color="primary"
          disabled={!canDelete || loading || isActiveSubscription}
          onClick={() => deleteAccount()}
          variant="outlined">
          {canDelete ? 'Delete Account' : timeRemaining}
        </Button>
        <Button
          color="primary"
          onClick={Cancel}
          variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog
      open={isDeleting}
    >
      <DialogContent style={{ display: 'grid', placeItems: 'center'}}>
        <DialogContentText>
          Deleting your account...
        </DialogContentText>
        <CircularProgress style={{margin: 20}} />
      </DialogContent>
    </Dialog>
    </>   
  )
}

export default DeleteAccount