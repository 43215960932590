import { Button, Card, CardActions, CardContent, makeStyles, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import React from 'react'
import theme from 'theme';

const useStyles = makeStyles(() => ({
  root: {
    // gridColumn: '1 / 3',
    // [theme.breakpoints.down('sm')]: {
    //   gridColumn: '1 / 2'
    // },
  }
}));

const CardSkeleton = () => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Skeleton animation="wave" style={{marginLeft: 15}} height={60} width={200}/>
      <CardContent>
        <Skeleton animation="wave" width={400}/>
        <Skeleton animation="wave" width={160}/>
      </CardContent>
      <CardActions>
        <Skeleton animation="wave" height={50} width={120}/>
        
      </CardActions>
    </Card>
  )
}

export default CardSkeleton