import React from 'react';
import Graph from './Graph';

const Heartbeat = ({ samples }) => {
  const fortmatter = (sample)=>{
    return sample.heartRate
  }
  return (

    <Graph
      color={'#f92c50'}
      samples={samples}
      fill
      formatter={fortmatter}
      label={'Heart Rate'}
      unit={'BPM'}
    />
  );
};

export default Heartbeat;
