import { getAuth, signOut } from 'firebase/auth';

const logOut = () => {
  const auth = getAuth();

  signOut(auth)
    .then(() => {
      // Sign-out successful.
      console.log('Sign-out successful');
      window.location.replace('/');
    })
    .catch(error => {
      console.log(`Error: ${error}`);
      // An error happened.
    });
};

export default logOut;
