import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PreferencesContext from 'context/Preferences/PreferencesContext';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(theme => ({
  card: {
    '& .day': {
      padding: '5px 5px'
    },
    '& .short': {
      display: 'block'
    },
    '& .long': {
      display: 'none'
    },
    '@media (min-width: 500px)': {
      '& .short': {
        fontSize: '14px',
        display: 'block'
      },
      '& .day': {
        padding: '5px 15px'
      }
    },
    '@media (min-width: 750px)': {
      '& .short': {
        display: 'none'
      },
      '& .long': {
        display: 'block'
      }
    },
    [theme.breakpoints.up('md')]: {
      gridColumn: 'auto',
      '& .short': {
        display: 'block'
      },
      '& .long': {
        display: 'none'
      }
    },
    [theme.breakpoints.up('lg')]: {
      gridColumn: '1 / 3',
      '& .short': {
        display: 'block'
      },
      '& .long': {
        display: 'none'
      }
    },
    '@media (min-width: 1350px)': {
      '& .short': {
        display: 'none'
      },
      '& .long': {
        display: 'block'
      }
    },
    '@media (min-width: 1650px)': {
      gridColumn: '1 / 2',
      '& .short': {
        display: 'block'
      },
      '& .long': {
        display: 'none'
      }
    }
  }
}));

const StartDay = () => {
  const classes = useStyles();
  const preferences = React.useContext(PreferencesContext);
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];
  const [startDay, setStartDay] = useState(preferences.get('startDay'));

  useEffect(() => {
    preferences.set('startDay', startDay);
  }, [startDay]);

  const setDay = index => {
    setStartDay(index);
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        subheader="Which day of the week should you start on?"
        title="Week Start"
      />
      <Divider />
      <CardContent>
        <Typography variant="body1">
          this will be the first day of the week in both the monthly view and
          weekly view
        </Typography>
      </CardContent>
      <Divider />
      <CardActions>
        <ButtonGroup variant="outlined" color="primary">
          {days.map((day, index) => (
            <Button
              className="day"
              key={day}
              disabled={index === startDay}
              onClick={() => setDay(index)}>
              <div className="short">{day.substring(0, 3)}</div>
              <div className="long">{day}</div>
            </Button>
          ))}
        </ButtonGroup>
      </CardActions>
    </Card>
  );
};

export default StartDay;
