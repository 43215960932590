import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import WorkoutAnalysis from './WorkoutAnalysis';
import { Box, Card, CircularProgress } from '@material-ui/core';
import server from 'api/server';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  scrollable: {
    overflow: 'auto'
  },
  card: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '90%'
    }
  }
}));

const WorkoutAnalysisModal = props => {
  const {
    isModalOpen,
    handleModalClose,
    activityId,
    runnerName,
    dayData
  } = props;

  const classes = useStyles();
  // open is required for the MUI V4 modal component
  const open = isModalOpen;

  const [workoutAnalysisData, setWorkoutsAnalysisData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchWorkoutsAnalysisData = async () => {
    if (dayData) {

      const workoutIds = dayData.workouts.map(workout => workout.activityId);
      const detailedActivitiesResponse = await server.get(
        'activity/getDetailedActivities',
        {params: {
          workouts: workoutIds,
          runnerName: dayData.runnerName
        }}
      );
      return detailedActivitiesResponse.value;
    }

    if (!activityId) return;
    const response = await server.get(
      'activity/getDetailedActivity',
      { params: {
        activityId: activityId
      }
      }
    );

    let activityData = response.value;
    activityData.runnerName = runnerName || dayData.runnerName;
    return activityData;
  };

  useEffect(() => {
    const getWorkoutAnalysisData = async () => {
      setLoading(true);
      const activitiesData = await fetchWorkoutsAnalysisData();
      setWorkoutsAnalysisData(activitiesData);
      setLoading(false);
    };
    getWorkoutAnalysisData();
  }, [activityId, JSON.stringify(dayData)]);

  if (workoutAnalysisData) {
    return (
      <Modal
        className={`${classes.scrollable} ${classes.paper}`}
        onClose={handleModalClose}
        open={isModalOpen}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh">
            <Card style={{ padding: '20px' }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column">
                <CircularProgress />
                Loading...
              </Box>
            </Card>
          </Box>
        ) : (
          <Card className={classes.card}>
            {Array.isArray(workoutAnalysisData) ? (
              workoutAnalysisData.map((workout, index) => (
                <WorkoutAnalysis
                  key={index}
                  handleModalClose={handleModalClose}
                  workoutAnalysisData={workout}
                />
              ))
            ) : (
              <WorkoutAnalysis
                handleModalClose={handleModalClose}
                workoutAnalysisData={workoutAnalysisData}
              />
            )}
          </Card>
        )}
      </Modal>
    );
  }

  return null;
};

export default WorkoutAnalysisModal;
