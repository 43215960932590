/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import TemplateTable from './components/TemplateTable';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import server from 'api/server';
import CreateWorkout from 'views/CreateWorkoutPlan/components/CreateWorkout';
import { Add, AddCircleOutline, ArrowBackIosRounded, CloseRounded, } from '@material-ui/icons';
import { SubGroup } from 'components';
import UserContext from 'context/User';
import { displayNotification } from 'api/displayNotification';

const useStyles = makeStyles(theme => ({
  header: {
    borderBottom: '1px solid #eeeeee',
    paddingBottom: '10px'
  },
  formControl: {
    minWidth: 150
  },
  formControlMobile: {
    width: '100%' 
  },
  backButton: {
    fontSize: 25,
    minWidth: 0,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      // backgroundColor: '#a4a4a4a3'
      backgroundColor: theme.palette.secondary.main
    }
  }
}));


const Templates = () => {
  const user = React.useContext(UserContext);
  const classes = useStyles();

  const desktopThreshold = 768;

  const [selectedSubgroup, setSelectedSubgroup] = useState('General');
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > desktopThreshold);
  const [loading, setLoading] = useState(true);


  const handleTemplateSave = async (newTemplate, response) => {
    if (selectedTemplate.workoutName != newTemplate.workoutName) {
      const response = await server.post(
        'workout/removeTemplateFromDB',
        { template: selectedTemplate }
      );
    }
    setSelectedTemplate(null);
    displayNotification(response);
  };
  const handleTemplateDelete = async () => {
    setSelectedTemplate(null);
  };
  const handleCreateNewTemplate = () => {
    setSelectedTemplate({
      workoutName: '',
      description: null,
      sport: 'RUNNING',
      userEmail: user.email,
      steps: [],
      workoutNotes: '',
      subGroup: selectedSubgroup
    });
  };

  
  const handleWindowResize = () => {
    setIsDesktop(window.innerWidth > desktopThreshold);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <Container maxWidth="lg">
      { isDesktop ? <> 

      {/*  D E S K T O P    V I E W  */}
      
      <Box
        justifyContent={'space-between'}
        display={'flex'}
        mt={2}
        className={classes.header}>
        <Box display={'flex'} gridGap={12} alignItems={'center'}>
          {selectedTemplate && (
            <IconButton
              onClick={() => setSelectedTemplate(null)}
            >
              <ArrowBackIosRounded/>
            </IconButton>
          )}
          <Typography variant="h1">
            Templates <span style={{ color: 'gray', fontWeight: '100', fontSize: '20pt' }}>{selectedTemplate && ` > ${selectedSubgroup} > ${selectedTemplate.workoutName}`}</span>
          </Typography>{' '}
          {loading && <CircularProgress size={20} />}
        </Box>
        {!selectedTemplate && (
          <SubGroup
            selectedSubgroup={selectedSubgroup}
            setSelectedSubgroup={setSelectedSubgroup}
          />
        )}
      </Box>
      {!selectedTemplate && (
        <>
          <Box justifyContent={'end'} display={'flex'} mt={2}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={handleCreateNewTemplate}>
              <Add/> Create Template
            </Button>
          </Box>
          <TemplateTable
            subgroup={selectedSubgroup}
            setLoading={setLoading}
            setSelectedTemplate={setSelectedTemplate}
          />
        </>
      )}
      </>:<>

      {/*  M O B I L E    V I E W  */}

      {!selectedTemplate && (
        <Box
          justifyContent={'space-between'}
          display={'flex'}
          mt={2}>
          <SubGroup
            selectedSubgroup={selectedSubgroup}
            setSelectedSubgroup={setSelectedSubgroup}
            width={'100%'}
          />
        </Box>
      )}
      <Box
        justifyContent={'space-between'}
        display={'flex'}
        mt={2}
        className={classes.header}>
        <Box display={'flex'} gridGap={12} alignItems={'center'}>
          <Typography variant="h1">
            {selectedTemplate ? <><span style={{ color: 'gray', fontSize: '20pt' }}>{selectedSubgroup}</span> {'>'} {selectedTemplate.workoutName}</> : 'Templates'}
          </Typography>{' '}
          {loading && <CircularProgress size={20} />}
        </Box>
        {selectedTemplate ? (
          <IconButton
            onClick={() => setSelectedTemplate(null)}
          >
            <CloseRounded/>
          </IconButton>
        ):
          <IconButton
            onClick={handleCreateNewTemplate}>
            <AddCircleOutline fontSize="large" />
          </IconButton>
        }
      </Box>
      {!selectedTemplate &&
        <TemplateTable
          subgroup={selectedSubgroup}
          setLoading={setLoading}
          setSelectedTemplate={setSelectedTemplate}
          mobile
        />
      }
      </>
      }
      {selectedTemplate && (
        <CreateWorkout
          isTemplateSection
          template={selectedTemplate}
          onTemplateDelete={handleTemplateDelete}
          onTemplateSave={handleTemplateSave}
        />
      )}
    </Container>
  );
};

export default Templates;
