import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import App from './App';

import { loadAnalytics } from './Analytics';

if (process.env.REACT_APP_NODE_ENV === 'production') {
  loadAnalytics();
}

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
