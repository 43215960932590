//     <!-- Google tag (gtag.js) -->
//     <script
//       async
//       src="https://www.googletagmanager.com/gtag/js?id=G-SHFNQ2QXMM"
//     ></script>
//     <script>
//       window.dataLayer = window.dataLayer || [];
//       function gtag() {
//         dataLayer.push(arguments);
//       }
//       gtag('js', new Date());

//       gtag('config', 'G-SHFNQ2QXMM');
//     </script>
//     <!-- Hotjar Tracking Code for SubCx -->
//     <script>
//       (function(h, o, t, j, a, r) {
//         h.hj =
//           h.hj ||
//           function() {
//             (h.hj.q = h.hj.q || []).push(arguments);
//           };
//         h._hjSettings = { hjid: 3612993, hjsv: 6 };
//         a = o.getElementsByTagName('head')[0];
//         r = o.createElement('script');
//         r.async = 1;
//         r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
//         a.appendChild(r);
//       })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
//     </script>

const loadGoogleAnalytics = () => {
  const gaScript = document.createElement('script');
  gaScript.async = true;
  gaScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-SHFNQ2QXMM';
  document.head.appendChild(gaScript);

  gaScript.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-SHFNQ2QXMM');
  };
};

const loadHotjar = () => {
  (function(h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function() {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: 3612993, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
};

// prettier-ignore
const loadGoogleTagManagerToHead = () => {
  const gtmScript = document.createElement('script');
  gtmScript.innerHTML =
    '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\': new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src= \'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f); })(window,document,\'script\',\'dataLayer\',\'GTM-5WWMX8K4\');';
  document.head.appendChild(gtmScript);
}

const loadGoogleTagManagerToBody = () => {
  const gtmBody = document.createElement('noscript');
  gtmBody.innerHTML =
    '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5WWMX8K4" height="0" width="0" style="display:none;visibility:hidden"></iframe>';
  document.body.appendChild(gtmBody);
};

export const loadAnalytics = () => {
  loadGoogleAnalytics();
  loadHotjar();
  loadGoogleTagManagerToHead();
  loadGoogleTagManagerToBody();
};
