/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect, useRef } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Paper, Select, useMediaQuery } from '@material-ui/core'
import server from 'api/server';
import { makeStyles } from '@material-ui/core/styles'
import interactionPlugin from '@fullcalendar/interaction';
import WorkoutChangeModal from '../WorkoutChangeModal/WorkoutChangeModal'
import { getDayOfWeekAndDate } from 'views/WorkoutPlan/WorkoutPlan'
import UserContext from 'context/User';
import PreferencesContext from 'context/Preferences/PreferencesContext';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    position: 'absolute',
    top: 0,
    right: 0
  },
  menuItem: {
    height: 40
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
function RunnerSelector({runners, selectedRunner, setSelectedRunner, setRunnerName}) { 
  // calendar library doesn't support having custom elements in the header bar
  // time to cheese it and forcefully put one there
  
  const classes = useStyles()
  
  const handleChange = (event) => {
    runners.forEach((runner)=>{
      if (runner.id == event.target.value) {
        setRunnerName(`${runner.firstName} ${runner.lastName}`)
        setSelectedRunner(runner)
        return
      }
    }) 
  }
  return (
    <FormControl variant="outlined" className={classes.formControl} >
      <InputLabel>Select Runner</InputLabel>
      <Select label="Select Runner" onChange={handleChange} value={selectedRunner ? selectedRunner.id : ''}>
        {runners.map((value, index) => <MenuItem key={index} value={value.id} className={classes.menuItem} >{`${value.firstName} ${value.lastName}`}</MenuItem>)}
      </Select>
    </FormControl>
  )
}


function MonthView({updateWeekView}) { 
  const user = React.useContext(UserContext);

  const [selectedRunner, setSelectedRunner] = useState()
  const [runnerName, setRunnerName] = useState()
  
  const [selectedWorkout, setSelectedWorkout] = useState()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [calendarApi, setCalendarApi] = useState()
  const isMobile = !useMediaQuery('(min-width:1000px)');


  const [runners, setRunners] = useState([])
  const [events, setEvents] = useState([])
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const classes = useStyles()

  const RootBox = useRef(null)
  const Calendar = useRef(null)
  const onResize = ()=>{
    Calendar.current.getApi().render() // force render to update 
  }

  const incrementDay = value => (value + 1) % 7;
  const preferences = React.useContext(PreferencesContext);
  const startDate = preferences.get('startDay')
  
  const weekdays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];
  const initilizeCalendar = async ()=> {
    if (user.role == 'runner') {
      fetchData(new Date())
      return
    }
    setLoading(true)
    const runnersResponse = await server.get(
      'runners'
    )
    const runners = runnersResponse.value

    const parsedRunnerData = []
    for (let i = 0; i < runners.length; i++) {
      const runner = runners[i];
      
      if (!runner.isActive) continue
      parsedRunnerData.push({
        firstName: runner.firstName,
        lastName: runner.lastName,
        id: runner.runnerId
      })
    }
    
    setRunners(parsedRunnerData)
    if (parsedRunnerData.length>0) {
      setRunnerName(`${parsedRunnerData[0].firstName} ${parsedRunnerData[0].lastName}`)
      setSelectedRunner(parsedRunnerData[0])
    }
    fetchData(new Date())
  }
  
  const fetchData = async (fetchDate)=> {
    setLoading(true)
    if (!calendarApi) return
    const activeRange = calendarApi.currentDataManager.state.dateProfile.activeRange
    const response = await server.get(`workout/getWorkouts?from=${activeRange.start}&to=${activeRange.end}`);
    const data = response.value
    setLoading(false)
    setData(data)
  }
  const updateCalendar = () => {
    // filter workouts and format to work with calendar
    let filteredWorkouts = [] // filtered and parsed workouts variable
    if (!selectedRunner) {
      setEvents([])
      return
    }
    for (let i = 0; i < data.length; i++) {
      const workout = data[i];
      const runner = workout.runnerId

      const {dayOfWeek, formattedDate} = getDayOfWeekAndDate(new Date(workout.dateObject))

      if (user.role == 'trainer' && runner != selectedRunner.id) continue
      filteredWorkouts.push({
        id: workout._id,
        title: workout.workout.workoutName,
        start: new Date(workout.dateObject),
        allDay: true,
        extendedProps: {
          workout: {
            dayOfWeek,
            formattedDate,
            ...workout
          }
        }
      })
    }
    setEvents(filteredWorkouts)
  } 

  const handleEventClick = (info) =>  {
    setSelectedWorkout(info.event.extendedProps.workout)
    setIsModalOpen(true)
  }

  const handleModalClose = () => setIsModalOpen(false)
  const handleDateChange = (info) => {
    fetchData()
  }
  // initial calendar setup 
  //(async funtion so you have to call it in a none async funtion)
  useEffect(()=>{
    setCalendarApi(Calendar.current.getApi())
  }, [Calendar])

  useEffect(()=>{
    if (!calendarApi) return;
    
    initilizeCalendar()
    if (user.role == 'runner') {
      setSelectedRunner({
        firstName: user.firstName,
        lastName: user.lastName,
        id: user.runnerId
      })
      setRunnerName(`${user.firstName} ${user.lastName}`)
    }
  }, [calendarApi])

  // on calendar update 
  // (this is for automatic updates when selected runner is changed)
  useEffect(updateCalendar, [data, selectedRunner])


  // resize listener
  useEffect(() => {
    window.addEventListener('resize', onResize)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [RootBox, onResize])

  return (
    <Box ref={RootBox} padding={isMobile ? 0 : 4}>
      <Paper style={{maxWidth:'1000px', display: 'flex', justifyContent: 'center', width: '100vw', padding: '20px 0'}}>
        <Box position={'relative'} maxWidth={'900px'}>
          <FullCalendar 
            contentHeight='auto'
            firstDay={startDate} // 0 = sunday, 1 = monday...
            initialView="dayGridMonth"
            plugins={[ dayGridPlugin, interactionPlugin ]}
            ref={Calendar}
            headerToolbar={{
              start: 'prev title next',
              end: ''
            }}
            events={events}
            datesSet={handleDateChange}
            eventClick={handleEventClick}
          />
          { 
            (user.role == 'runner') ? <></> :
              ((user.role == 'trainer') && runners.length>0) ?
                <RunnerSelector
                  runners={runners}
                  selectedRunner={selectedRunner}
                  setSelectedRunner={setSelectedRunner}
                  setRunnerName={setRunnerName}
                />:
                (loading) ? 
                  <div className={classes.formControl}>Loading...</div> : 
                  <div className={classes.formControl}>You have No Runnners</div>
          }
          {loading && <div className={classes.formControl} style={{left: 0}}><CircularProgress size={'1rem'}/></div>}
         
        </Box>
      </Paper>
      <WorkoutChangeModal
        isModalOpen={isModalOpen}
        open={isModalOpen}
        handleModalClose={handleModalClose}
        user={user}
        type={''}
        setWorkout={()=>{}}
        setWorkoutName={()=>{}}
        setWorkoutSteps={()=>{}}
        updateWorkouts={()=>{updateWeekView(); fetchData()}}
        workout={selectedWorkout}
      />
    </Box>
  )
}

export default MonthView