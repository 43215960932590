import React, { useState } from 'react';
import server from 'api/server';

import oAuthGarmin from 'common/garminOAuth';

import { initiatedFirebaseApp } from '../../config/firebase-config';
import {
  getAuth,
  signInWithEmailAndPassword
} from 'firebase/auth';
import setUserLoadedContext from 'context/SetUserLoaded';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { displayNotification } from 'api/displayNotification';

let auth;
if (initiatedFirebaseApp) {
  auth = getAuth();
}

const useRegister = ({ formState, history, setUser }) => {
  const setUserLoaded = React.useContext(setUserLoadedContext);
  const { registerId } = useParams();
  

  // this function runs the registration is complete
  const complete = async response => {
    if (response.success) {
      // Registation was successful
      // Sign in to account
      try {
        await signInWithEmailAndPassword(auth, formState.values.email, formState.values.password);
        history.push('/dashboard');

        if (formState.values.role == 'trainer') return
        const toSyncGarmin = window.confirm(
          `Hi ${formState.values.firstName}, Do you want to sync your Garmin account?`
        );
        if (toSyncGarmin) return await oAuthGarmin(formState.values.email);
      } catch (error) {
        history.push('/sign-in');
        displayNotification('Account created, please sign in');
      }
    } else {
    // Registation was not successful
      displayNotification(response);
    }
  }
  const registerTrainer = async () => {
    setUserLoaded(false)
    // Register Trainer
    const response = await server.post('register/trainer',
      formState.values, 
      { validateStatus: () => true });
    await complete(response)
    
    setUserLoaded(true)
  }

  const registerRunner = async () => {
    // Register Runner
    setUserLoaded(false)
    const response = await server.post('register/runner',
      {
        registerId, 
        form: formState.values
      }, 
      { validateStatus: () => true });
    await complete(response)

    setUserLoaded(true)
  }


  const handleSignUp = async event => {
    event.preventDefault();
    try {
      const role = formState.values.role;
      if (role == 'trainer') {
        registerTrainer();
      } else if (role == 'runner') {
        registerRunner();
      }
    } catch (error) {
      console.error(error);
    }
  }
  return { handleSignUp };
};

export default useRegister;
