import { RouteWithLayoutDisplayNotification } from 'components/RouteWithLayout/RouteWithLayout';

/**
   * Displays a notification with the specified message and severity.
   *
   * @param {string} message - The message to be displayed in the notification.
   * @param {string} severity - The severity level of the notification (e.g. 'info', 'success', 'error', 'warning'). Defaults to 'info'.
   * @param {function} onclose - The function to handle the close event of the notification. Defaults to an empty function.
   * @return {void}
   */
export function displayNotification(severity='info',message, onclose=()=>{}) {
  RouteWithLayoutDisplayNotification(severity, message, onclose);
}