import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(theme => ({
  listItem: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 0,
    paddingBottom: 0
  },
  listPrimaryRow: { color: '#000000', fontWeight: 500, fontSize: 16 },
  listSecondaryRow: { color: 'blue', lineHeight: '14px', fontSize: 14 }
  //   listItemButton: {
  //     padding: 0,
  //     paddingRight: 5,
  //   },
  //   listItemButtonIcon: {
  //     fontSize: 18,
  //     color: '#3d3d3d',
  //   },
}));

export default function MobileWorkoutStepSetPace(props) {
  const { pace } = props;
  const classes = useStyles();

  return (
    <ListItem className={classes.listItem}>
      <ListItemText
        primary={
          <Typography type="body" className={classes.listPrimaryRow}>
            Set Pace
          </Typography>
        }
        secondary={
          <Typography className={classes.listSecondaryRow}>{pace}</Typography>
        }
      />
      {/* <ListItemSecondaryAction>
              <IconButton edge="end" className={classes.listItemButton}>
                <ArrowForwardIosIcon className={classes.listItemButtonIcon} />
              </IconButton>
            </ListItemSecondaryAction> */}
    </ListItem>
  );
}
