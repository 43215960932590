import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import schema from './schema';
import { Grid, Typography, Snackbar } from '@material-ui/core';
import useStyles from './styles';
import server from 'api/server';
import Alert from 'components/Alert';

import FormHeader from './components/FormHeader';
import FormFooter from './components/FormFooter/FormFooter';
import SelectRole from './components/FormFooter/SelectRole';
import useRegister from './useRegister';
import { displayNotification } from 'api/displayNotification';

const SignUp = props => {
  const { history, setUser } = props;
  const classes = useStyles();
  const [isInvitedByGroupLink, setIsInvitedByGroupLink] = useState(false)
  const [runner, setRunner] = useState({});


  const { registerId } = useParams();

  useEffect(() => {
    if (registerId) {
      server
        .get(`runner?id=${registerId}`)
        .then(res => {
          const userInfo = res.value;
          if (userInfo) {
            if (userInfo.role == 'trainer') {
              setIsInvitedByGroupLink(true)
              setFormState({
                ...formState,
                values: {
                  ...formState.values,
                  trainerUserId: registerId,
                  runningGroup: userInfo.runningGroup,
                  firstName: '',
                  lastName: '',
                  mobile: '',
                  email: '',
                  role: 'runner',
                  password: ''
                }
              })
              return
            }
            setRunner(userInfo);
            setFormState({
              ...formState,
              values: {
                ...formState.values,
                runnerId: userInfo._id,
                runningGroup: userInfo.runningGroup,
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                mobile: userInfo.mobile,
                role: 'runner'
              }
            });
          }
        })
        .catch(err => {
          const response = err.response;
          if ( response.status == 401 ) {
            history.push('/sign-in')
            displayNotification('User already Exists, please sign in')
          } else {
            displayNotification(response)
          }
        });
    }
  }, []);
  
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });


  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const { handleSignUp } = useRegister({
    formState,
    history,
    setUser
  });


  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <Typography variant="h1">Sign Up</Typography>
            </div>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignUp}>
                <SelectRole
                  formState={formState}
                  setFormState={setFormState}
                  runner={runner}
                  isInvitedByGroupLink={isInvitedByGroupLink}
                />
                <FormHeader
                  formState={formState}
                  setFormState={setFormState}
                  runner={runner}
                  isInvitedByGroupLink={isInvitedByGroupLink}
                />
                <FormFooter
                  formState={formState}
                />
              </form>
            </div>
          </div>
        </Grid>
        <Grid className={classes.imgContainer} item lg={5}>
          <div className={classes.img} />
        </Grid>
      </Grid>
    </div>
  );
};

SignUp.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignUp);
