/* eslint-disable react/no-multi-comp */
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/styles';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import theme from 'theme';
import SortableTableHead from '../SortableTableHead';
import Row from './Row';
import { TablePagination } from '@material-ui/core';
import server from 'api/server';
import { Snackbar } from '@material-ui/core';
import RunnerSettingsModal from './RunnerSettingsModal';
import InviteGroupModal from './inviteGroupModal';
import { displayNotification } from 'api/displayNotification';


const sortRunnersByActive = runners => {
  runners.sort((a, b) => {
    if (a.isActive && !b.isActive) {
      return -1; // a is active, b is inactive - a should come first
    } else if (!a.isActive && b.isActive) {
      return 1; // a is inactive, b is active - b should come first
    }
    return 0; // both runners have the same isActive status - maintain the order
  });

  return runners;
};



const useTableStyles = makeStyles({
  tableBody: {
    position: 'relatuve',
    margin: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      border: 'none'
    }
  },
  nowrap: {
    whiteSpace: 'nowrap'
  },
  spinnerContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(5)
  },
  error: {
    textAlign: 'center',
    padding: theme.spacing(5)
  }
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const RunnersList = props => {
  let { inviteGroupLinkTrigger, setInviteGroupLinkTrigger, inviteRunnerTrigger, setInviteRunnerTrigger } = props;

  const classes = useTableStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);

  const [
    runnersTableRenderTrigger,
    setRunnersTableRenderTrigger
  ] = React.useState(false);
  const [notificationSeverity, setNotificationSeverity] = React.useState(
    'info'
  );
  const [notificationMessage, setNotificationMessage] = React.useState('');
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const [runners, setRunners] = React.useState([]);

  const getRunners = async () => {
    const response = await server.get('runners');
    
    if (!response.success) {
      displayNotification(response)
    }
  
    //Add calculated pace total in seconds to array. Needed for sorting
    const runners = response.data.value.map(runner => {
      return {
        ...runner,
        pace:
          (runner.basePace?.minutes * 60 || 0) + (runner.basePace?.seconds || 0)
      };
    });
  
    const sortedRunnersByActive = await sortRunnersByActive(runners);
    return sortedRunnersByActive;
  };

  const refresh = () => {
    setRunners([])
    getRunners().then(res => setRunners(res));
  }

  React.useEffect(refresh, [runnersTableRenderTrigger]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  React.useEffect(() => {
    if (notificationMessage && notificationSeverity) {
      setOpenSnackbar(true);
    }
  }, [notificationMessage, notificationSeverity]);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, runners.length - page * rowsPerPage);

  const [isAccountDetailsModalOpen, setIsAccountDetailsModalOpen] = React.useState(false);
  const [isGroupInviteModalOpen, setIsGroupInviteModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (inviteGroupLinkTrigger) {
      setInviteGroupLinkTrigger(false);
      setIsGroupInviteModalOpen(true);
    }
  }, [inviteGroupLinkTrigger]);
  
  React.useEffect(() => {
    if (inviteRunnerTrigger) {
      setInviteRunnerTrigger(false);
      setIsAccountDetailsModalOpen(true);
    }
  }, [inviteRunnerTrigger]);

  const handleAccountDetailsModalClose = () => {
    setIsAccountDetailsModalOpen(false);
  };
  const handleGroupInviteModalClose = () => {
    setIsGroupInviteModalOpen(false);
  };

  const setRunner = runner => {
    setRunnersTableRenderTrigger(!runnersTableRenderTrigger);
  };

  const removeRunnerFromRunnersList = deletedRunnerId => {
    setRunners(prevRunners =>
      prevRunners.filter(runner => runner.runnerId !== deletedRunnerId)
    );
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
    setNotificationMessage('');
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={4000}
        data-testid="deleted-notification"
        onClose={handleCloseSnackbar}
        open={openSnackbar}
      >
        <Alert
          data-testid={notificationSeverity}
          onClose={handleCloseSnackbar}
          severity={notificationSeverity || 'error'}
        >
          {notificationMessage}
        </Alert>
      </Snackbar>
      <div>
        <Table aria-label="collapsible table">
          <SortableTableHead
            numSelected={selected.length}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            rowCount={runners.length}
          />
          <TableBody className={classes.tableBody} data-test="runners-list-table">
            {runners.map((row, index) => (
              <Row
                refresh={refresh}
                key={row.runnerId}
                removeRunnerFromRunnersList={removeRunnerFromRunnersList}
                row={row}
                setNotificationMessage={setNotificationMessage}
                setNotificationSeverity={setNotificationSeverity}
              />
            ))}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        component="div"
        count={runners.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <RunnerSettingsModal
        handleModalClose={handleAccountDetailsModalClose}
        isModalOpen={isAccountDetailsModalOpen}
        refresh={refresh}
        runner={runners}
        setRunner={setRunner}
      />
      <InviteGroupModal
        handleModalClose={handleGroupInviteModalClose}
        isModalOpen={isGroupInviteModalOpen}
        removeRunnerFromRunnersList={removeRunnerFromRunnersList}
        setNotificationMessage={setNotificationMessage}
        setNotificationSeverity={setNotificationSeverity}
      />
    </>
  );
};

export default RunnersList;
