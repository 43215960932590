export default (distance = null) => ({
  type: 'WorkoutStep',
  stepOrder: 100,
  intensity: 'COOLDOWN',
  description: null,
  durationType: distance ? 'DISTANCE' : null,
  durationValue: distance,
  durationValueType: null,
  targetType: null,
  targetValue: null,
  targetValueLow: null,
  targetValueHigh: null,
  targetValueType: null,
  name: 'Cool Down'
});
