import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import WorkoutInputFields from './WorkoutInputFields';
import theme from 'theme';

const useStyles = makeStyles(theme => ({
  box: {
    backgroundColor: '#F2F2F2',
    // borderStyle: 'solid',
    // borderWidth: '1px',
    // borderColor: theme.palette.primary.main,
    borderRadius: '14px',
    padding: '1rem .8rem',
    marginTop: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      padding: '1.4rem'
    }
  },
  box_top: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  box_title: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    marginBottom: '0.5rem'
  },
  select: {
    '& span': {
      fontWeight: '600'
    }
  },
  formControl: {
    width: '100%',
    marginTop: '7px'
  },
  priamryTextColor: {
    color: theme.palette.primary.main
  },
  secondaryTextColor: {
    color: theme.palette.secondary.main
  }
}));

function RepeatStep(props) {
  // const { removeStep, step, trigger } = props;
  const { removeStep, step, isRunner } = props;
  const classes = useStyles();
  const [repetitions, setRepetitions] = useState('');

  const hadleDeleteWorkout = () => removeStep(step);

  const handleRepetitionsChange = event => {
    step.repeatValue = event.target.value;
    setRepetitions(event.target.value);
  };

  // useEffect(() => {
  //   setRepetitions('');
  // }, [trigger]);

  return (
    <div data-test="createWorkout-repeatStep" className={classes.box}>
      <div className={classes.box_top}>
        <Typography
          className={`${classes.box_title} ${classes.priamryTextColor}`}
          variant="h5">
          Repeat Step
        </Typography>
        { (!isRunner) &&
        <DeleteOutlineIcon
          data-test="repeatStep-deleteButton"
          onClick={hadleDeleteWorkout}
          style={{ cursor: 'pointer', color: theme.palette.secondary.main }}
        />}
      </div>

      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          <div className={classes.select}>
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel
                // className={classes.priamryTextColor}
                id="repeatStep-reptitionsNumber-inputLabel"
                margin="dense">
                Select Number of Repetitions
              </InputLabel>
              <Select
                disabled={isRunner}
                data-test={'repeatStep-reptitionsNumber-select'}
                label="Select Number of Repetitions"
                margin="dense"
                onChange={handleRepetitionsChange}
                value={step.repeatValue ? `${step.repeatValue}` : repetitions}
                labelId="repeatStep-reptitionsNumber-select"
                // className={classes.priamryTextColor}
                id="repeatStep-reptitionsNumber-select">
                <MenuItem
                  data-test={'repeatStep-reptitionsNumber-menuItem-2'}
                  className={classes.secondaryTextColor}
                  value={2}>
                  2
                </MenuItem>
                <MenuItem
                  data-test={'repeatStep-reptitionsNumber-menuItem-3'}
                  className={classes.secondaryTextColor}
                  value={3}>
                  3
                </MenuItem>
                <MenuItem
                  data-test={'repeatStep-reptitionsNumber-menuItem-4'}
                  className={classes.secondaryTextColor}
                  value={4}>
                  4
                </MenuItem>
                <MenuItem
                  data-test={'repeatStep-reptitionsNumber-menuItem-5'}
                  className={classes.secondaryTextColor}
                  value={5}>
                  5
                </MenuItem>
                <MenuItem className={classes.secondaryTextColor} value={6}>
                  6
                </MenuItem>
                <MenuItem className={classes.secondaryTextColor} value={7}>
                  7
                </MenuItem>
                <MenuItem className={classes.secondaryTextColor} value={8}>
                  8
                </MenuItem>
                <MenuItem className={classes.secondaryTextColor} value={9}>
                  9
                </MenuItem>
                <MenuItem className={classes.secondaryTextColor} value={10}>
                  10
                </MenuItem>
                <MenuItem className={classes.secondaryTextColor} value={11}>
                  11
                </MenuItem>
                <MenuItem className={classes.secondaryTextColor} value={12}>
                  12
                </MenuItem>
                <MenuItem className={classes.secondaryTextColor} value={13}>
                  13
                </MenuItem>
                <MenuItem className={classes.secondaryTextColor} value={14}>
                  14
                </MenuItem>
                <MenuItem className={classes.secondaryTextColor} value={15}>
                  15
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </Grid>

        <Grid data-test={'RepeatStep-intensePart'} item sm={12} xs={12}>
          <div className={classes.box_top}>
            <Typography
              className={`${classes.box_title} ${classes.priamryTextColor}`}
              variant="h6">
              Intense Part
            </Typography>
          </div>
          <WorkoutInputFields step={step.steps[0]} isRunner={isRunner}/>
          {/* trigger={trigger} /> */}
        </Grid>

        <Grid data-test={'RepeatStep-recoveryPart'} item sm={12} xs={12}>
          <div className={classes.box_top}>
            <Typography
              className={`${classes.box_title} ${classes.priamryTextColor}`}
              variant="h6">
              Recovery Part
            </Typography>
          </div>
          <WorkoutInputFields step={step.steps[1]} isRunner={isRunner}/>
          {/* trigger={trigger} /> */}
        </Grid>
      </Grid>
    </div>
  );
}

export default RepeatStep;
