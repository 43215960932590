
const RecoverPassWord = () => {
  return (
    <div>
      <h1>Recover Password</h1>
    </div>
  )
}

export default RecoverPassWord
