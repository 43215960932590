import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import server from 'api/server';

import {
  parsePhoneNumberFromString,
  isValidPhoneNumber
} from 'libphonenumber-js';

import { SubGroup } from 'components';
import UserContext from 'context/User';
import { displayNotification } from 'api/displayNotification';

export const normalizePhone = value => {
  if (!value) return value;
  const phoneNumber = parsePhoneNumberFromString(value);
  if (!phoneNumber) return `+${value.replace(/\D/g, '')}`;
  return phoneNumber.formatInternational();
};

export const validatePhoneNumber = value => {
  if (!value) return true;
  return isValidPhoneNumber(value);
};

export const validateEmail = value => {
  if (!value) return true;
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
};

const useStyles = makeStyles(() => ({
  root: {}
}));

const AccountDetails = props => {
  const {
    className,
    runner,
    setRunner,
    handleModalClose,
    refresh,
    ...rest
  } = props;
  const user = React.useContext(UserContext)
  const isCreating = Array.isArray(runner)

  const classes = useStyles();

  const [values, setValues] = useState({
    trainerUserId: user.role == 'trainer' ? user._id : null,
    runnerId: runner?.runnerId || null,
    firstName: runner?.firstName || '',
    lastName: runner?.lastName || '',
    email: runner?.email || '',
    mobile: runner?.mobile || '',
    paceMinutes: runner?.basePace?.minutes || 6,
    paceSeconds: runner?.basePace?.seconds || 0,
    runsPerWeek: runner?.runsPerWeek || 0,
    runningGroup: runner?.runningGroup || user?.runningGroup,
    subGroup: runner?.subGroup || 'General',
    isActive: runner?.isActive || false,
    isRegistered: runner?.isActive || false
  });
  // Handling Subgroups

  const [selectedSubgroup, setSelectedSubgroup] = useState(values.subGroup);
  const [hasChanges, setHasChanges] = useState(isCreating);

  const runChangeCheck = () => {
    const keys = Object.keys(values);
    if (isCreating) return
    let hasChanged = false;
    keys.forEach(key => {
      if (key == 'paceMinutes') {
        if (values[key] != runner.basePace.minutes) {
          hasChanged = true;
        }
      }
      else if (key == 'paceSeconds') {
        if (values[key] != runner.basePace.seconds) {
          hasChanged = true;
        }
      }
      else {
        if (values[key] != runner[key]) {
          hasChanged = true;
        }
      }
    });
    setHasChanges(hasChanged);
  }


  const handleChange = event => {
    event.persist();

    if (event.target.name === 'mobile') {
      const { value } = event.target;
      event.target.value = normalizePhone(value);
    }

    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSaveDetails = async () => {
    // Missing error handling
    const response = await server.post(
      'runner',
      {id: values.runnerId, data:values},
      { validateStatus: () => true }
    );
    if (!response.success) {
      displayNotification(response);
      handleModalClose();
      return
    }
    setRunner(prevRunner => ({
      ...prevRunner,
      ...values,
      basePace: { minutes: values.paceMinutes, seconds: values.paceSeconds },
    }));
    handleModalClose();
  };



  useEffect(() => {
    setValues({
      ...values,
      subGroup: selectedSubgroup
    });
  }, [selectedSubgroup]);

  useEffect(() => {
    runChangeCheck();
  }, [values]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate>
        <CardHeader
          action={
            <IconButton onClick={handleModalClose} data-test="close-modal" aria-label="settings">
              <CloseRoundedIcon />
            </IconButton>
          }
          subheader={`Here you can edit ${runner?.name ||
            'new runner'}'s information`}
          title={`Runner's Settings: ${runner?.name || ''}`}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                // helperText="Please specify the first name"
                label="First name"
                margin="dense"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last name"
                margin="dense"
                name="lastName"
                onChange={handleChange}
                // required
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                fullWidth
                label="Pace (minutes)"
                margin="dense"
                name="paceMinutes"
                onChange={handleChange}
                type="number"
                value={values.paceMinutes}
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                fullWidth
                label="Pace (seconds)"
                error={
                  values.paceSeconds < 0 || 59 < values.paceSeconds
                    ? true
                    : false
                }
                helperText={
                  values.paceSeconds < 0 || 59 < values.paceSeconds
                    ? 'seconds should be between 0-59'
                    : ''
                }
                margin="dense"
                name="paceSeconds"
                onChange={handleChange}
                type="number"
                value={values.paceSeconds}
                variant="outlined"
              />
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Runs per week"
                margin="dense"
                name="runsPerWeek"
                onChange={handleChange}
                type="number"
                value={values.runsPerWeek}
                variant="outlined"
              />
            </Grid> */}
            <Grid item md={3} xs={12}>
              <SubGroup
                selectedSubgroup={selectedSubgroup}
                setSelectedSubgroup={setSelectedSubgroup}
                onSubgroupRename={()=>{refresh()}}
                width="100%"
                hideCoach={runner?.role != 'trainer'}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                fullWidth
                disabled={true}
                label="Running Group"
                margin="dense"
                name="runningGroup"
                // type="number"
                value={values.runningGroup}
                variant="outlined"
              />
            </Grid>
            { runner.role != 'trainer' &&<>
            <Grid item md={6} xs={12}>
              <TextField
                error={!validateEmail(values.email) ? true : false}
                helperText={
                  !validateEmail(values.email)
                    ? 'Email address is required'
                    : ''
                }
                fullWidth
                label="Email Address"
                margin="dense"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={!validatePhoneNumber(values.mobile) ? true : false}
                helperText={
                  !validatePhoneNumber(values.mobile)
                    ? 'Mobile number in international format is required (+1 / +44 / +972...)'
                    : null
                }
                fullWidth
                label="Mobile Number"
                margin="dense"
                name="mobile"
                onChange={handleChange}
                // type="number"
                required
                value={values.mobile}
                variant="outlined"
              />
            </Grid></>}
            <Grid item md={6} xs={12}></Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            disabled={
              !validatePhoneNumber(values.mobile) ||
              !values.mobile ||
              !values.email ||
              !validateEmail(values.email) ||
              !values.firstName ||
              !hasChanges
            }
            color="primary"
            variant="contained"
            onClick={handleSaveDetails}>
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
