import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  calendarButton: {
    display: 'flex',
    padding: 0,
    verticalAlign: 'middle',
    height: '80%'
  },
  calendarButtonIcon: {
    fontSize: 15
  },
  calendarText: {
    //   fontSize: 15,
    textAlign: 'center'
  },
  calendarButtonIconWrapper: {
    marginTop: '-10px'
  },
  calendarIcon: {
    width: 5,
    height: 5,
    fontSize: 5,
    color: '#969696'
  },
  activeDate: {
    color: '#5157F8'
  }
  // calendarHeading: {
  //   marginBottom: 6,
  //   display: 'inline-block',
  // },
}));

const Day = props => {
  const { workout } = props;
  const classes = useStyles();

  return (
    <Grid item xs={1} className={classes.calendarText}>
      <Typography
        className={workout.isActive ? classes.activeDate : ''}
        component={'span'}>
        {/* <strong className={classes.calendarHeading}>{day}</strong> */}
        <strong>{workout.dayOfWeek[0]}</strong>
        <br />
        {new Date(workout.dateObject).getDate()}
        {workout.isWorkout && (
          <Box className={classes.calendarButtonIconWrapper}>
            <FiberManualRecordIcon className={classes.calendarIcon} />
          </Box>
        )}
      </Typography>
    </Grid>
  );
};

const MobileCalendar = props => {
  const { workouts, setActiveDate, setSteps } = props;
  const classes = useStyles();

  // const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  const handleActiveDateClick = workout => {
    workouts.map(item => {
      item.isActive = false;
      if (item.date == workout.date) item.isActive = true;
    });
    if (workout.isWorkout && workout.workoutTemplate)
      setSteps(workout.workoutTemplate.steps);
    if (workout.isWorkout && workout.workout) setSteps(workout.workout.steps);
    setActiveDate(workout);
  };

  return (
    <Grid justifyContent="space-between" container style={{ marginTop: 10 }}>
      {/* <Grid item xs={1}>
        <IconButton edge="end" className={classes.calendarButton}>
          <ArrowBackIosIcon className={classes.calendarButtonIcon} />
        </IconButton>
      </Grid> */}
      {workouts.map((workout, index) => (
        <div key={index} onClick={() => handleActiveDateClick(workout)}>
          <Day workout={workout} key={index} />
        </div>
      ))}
      {/* <Grid item xs={1}>
        <IconButton
          edge="end"
          className={classes.calendarButton}
          style={{ marginLeft: 14 }}>
          <ArrowForwardIosIcon className={classes.calendarButtonIcon} />
        </IconButton>
      </Grid> */}
    </Grid>
  );
};

export default MobileCalendar;
