import WatchIcon from '@material-ui/icons/Watch';
import SyncIcon from '@material-ui/icons/Sync';

const WatchSyncIcon = props => {
  const { size, color } = props;
  const WatchSyncIconSize = size; // Base font size for the WatchIcon
  const syncIconSize = WatchSyncIconSize * 0.5; // SyncIcon is 50% of the WatchIcon size

  return (
    <span
      style={{
        position: 'relative',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: color || 'blue' //'#3f51b5'
      }}>
      <WatchIcon
        style={{
          fontSize: `${WatchSyncIconSize}px` // Set relative size
        }}
      />
      <SyncIcon
        style={{
          position: 'absolute',
          fontSize: `${syncIconSize}px` // Set relative size
        }}
      />
    </span>
  );
};

export default WatchSyncIcon;
