import React, { useState, useEffect } from 'react';
import { Snackbar } from '@material-ui/core';
import Alert from '../Alert';

/**
 * Renders a SnackbarNotification component that displays a message with a specified severity and duration.
 * set `isNotificationDisplayed` to true to show the notification and it will hide itself automatically after the specified duration.
 *
 * @param {boolean} isNoficationDisplayed - Indicates whether the notification is displayed.
 * @param {function} setIsNotificationDisplayed - The function to set the notification display state. (dont set to false)
 * @param {number} duration - (Default = 5 seconds) The duration in milliseconds for the notification to be displayed.
 * @param {string} severity - The severity level of the notification (e.g. 'info', 'success', 'error', 'warning').
 * @param {string} message - The message to be displayed in the notification.
 * @param {function} handleClose - (Optional) The function to handle the close event of the notification. 
 * @return {JSX.Element} The rendered SnackbarNotification component.
 * 
 * Paste the example below to get started fast, use the `displayNotification` function
 * @example
 * const [isNotification, setIsNotification] = React.useState(false);
  const [notificationSeverity, setNotificationSeverity] = React.useState('');
  const [notificationMessage, setNotificationMessage] = React.useState('');

  const displayNotification = (severity, message) => {
    setNotificationSeverity(severity);
    setNotificationMessage(message);
    setIsNotification(true);
  };

  return (
  <SnackbarNotification
        isNotificationDisplayed={isNotification}
        setIsNotificationDisplayed={setIsNotification}
        message={notificationMessage}
        severity={notificationSeverity}
      />
  );
 */
function SnackbarNotification({ isNotificationDisplayed, setIsNotificationDisplayed, duration, severity, message, handleClose }) {

  const [timeoutId, setTimeoutId] = useState(null);
  const displayNotification = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setTimeoutId(setTimeout(() => {
      setIsNotificationDisplayed(false);
      setTimeoutId(null)
    }, duration?duration:5000))
  };
  const handleNotificationClose = () => {
    if (timeoutId) {
      setIsNotificationDisplayed(false);
      clearTimeout(timeoutId);
    }
    handleClose && handleClose()
  }

  useEffect(()=>{
    if (isNotificationDisplayed) {
      displayNotification();
    }
  },[isNotificationDisplayed])

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={null}
      onClose={(_, reason)=>{if (reason != 'clickaway') handleNotificationClose()}}
      open={isNotificationDisplayed}>
      <Alert onClose={handleNotificationClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default SnackbarNotification;
