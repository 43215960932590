import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '93%',
    marginRight: 10,
    marginLeft: 10,
  },
}));

export default function MobileWorkoutStepDivder(props) {
  const classes = useStyles();

  return <Divider className={classes.divider} />;
}
