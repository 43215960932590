import React from 'react';
import { makeStyles } from '@material-ui/styles';
// import { Grid, Typography } from '@material-ui/core';

import './assets/css/main.css';
import './assets/css/A.main.css.pagespeed.cf.uEh93Q4DcK.css';
import './assets/css/animate.css';
import './assets/css/lineicons.css';
import './assets/css/A.bootstrap.min.css+lineicons.css+tiny-slider.css+animate.css';
import './assets/js/wow.min.js+main.js.pagespeed.jc.hw4o_gtsSz';
import './assets/css/bootstrap.min.css';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import BenefitsSection from './components/BenefitsSection';
import AboutSection from './components/AboutSection';
// import WhySection from './components/WhySection';
import PricingSection from './components/PricingSection';
import TestimonialSection from './components/TestimonialSection';
// import SubscribeSection from './components/SubscribeSection';
import Footer from './components/Footer';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Navbar />
      <HeroSection />
      <BenefitsSection />
      <AboutSection />
      {/* <WhySection /> */}
      <PricingSection />
      <TestimonialSection />
      {/* <SubscribeSection /> */}
      <Footer />
    </div>
  );
};

export default Home;

// const Home = () => {
//   const classes = useStyles();
//   return (
//     <div className={classes.root}>
//       <Grid container justifyContent="center" spacing={4}>
//         <Grid item lg={6} xs={12}>
//           <div className={classes.content}>
//             <Typography variant="h1">Home page</Typography>
//             <Typography variant="subtitle2">This is home page</Typography>
//           </div>
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

// const Home = () => {
//   const classes = useStyles();

//   return (
//     <div className={classes.root}>
//       {/* <!-- ======== header start ======== --> */}
//       <header className="header">
//         <div className="navbar-area">
//           <div className="container">
//             <div className="row align-items-center">
//               <div className="col-lg-12">
//                 <nav className="navbar navbar-expand-lg">
//                   <a className="navbar-brand" href="index.html">
//                     <img
//                       src={require('./assets/img/logo/run23_logo_white.png')}
//                       alt="Logo"
//                     />
//                   </a>
//                   <button
//                     className="navbar-toggler"
//                     type="button"
//                     data-bs-toggle="collapse"
//                     data-bs-target="#navbarSupportedContent"
//                     aria-controls="navbarSupportedContent"
//                     aria-expanded="false"
//                     aria-label="Toggle navigation">
//                     <span className="toggler-icon"></span>
//                     <span className="toggler-icon"></span>
//                     <span className="toggler-icon"></span>
//                   </button>
//                   <div
//                     className="collapse navbar-collapse sub-menu-bar"
//                     id="navbarSupportedContent">
//                     <ul id="nav" className="navbar-nav ms-auto">
//                       <li className="nav-item">
//                         <a className="page-scroll active" href="#home">
//                           Home
//                         </a>
//                       </li>
//                       <li className="nav-item">
//                         <a className="page-scroll" href="#features">
//                           Features
//                         </a>
//                       </li>
//                       <li className="nav-item">
//                         <a className="page-scroll" href="#about">
//                           About
//                         </a>
//                       </li>
//                       <li className="nav-item">
//                         <a className="page-scroll" href="#why">
//                           Why
//                         </a>
//                       </li>
//                       <li className="nav-item">
//                         <a className="page-scroll" href="#pricing">
//                           Pricing
//                         </a>
//                       </li>
//                       <li className="nav-item">
//                         <a className="page-scroll" href="#testimonials">
//                           Testimonials
//                         </a>
//                       </li>
//                       <li className="nav-item">
//                         <p
//                           className="main-btn border-btn btn-hover wow fadeInUp"
//                           data-wow-delay={'1s'}
//                           onClick={() => {
//                             window.location.replace('/sign-in');
//                           }}
//                           style={{
//                             color: 'white',
//                             borderColor: 'white',
//                             padding: '10px 18px 4px',
//                             fontFamily: 'Arimo'
//                           }}>
//                           Sign-in
//                         </p>
//                       </li>
//                     </ul>
//                   </div>
//                 </nav>
//               </div>
//             </div>
//           </div>
//         </div>
//       </header>
//       {/* <!-- ======== header end ======== --> */}
//       {/* <!-- ======== hero-section start ======== --> */}
//       <section id="home" className="hero-section">
//         <div className="container">
//           <div className="row align-items-center position-relative">
//             <div className="col-lg-6">
//               <div className="hero-content">
//                 <h1 className="wow fadeInUp" data-wow-delay={'1s'}>
//                   Your using free lite version
//                 </h1>
//                 <p className="wow fadeInUp" data-wow-delay={'1s'}>
//                   Please, purchase full version to get all sections, features
//                   and permission to remove footer credit.
//                 </p>
//                 <a
//                   className="main-btn border-btn btn-hover wow fadeInUp"
//                   data-wow-delay={'1s'}
//                   href="/sign-up">
//                   Sign-up
//                 </a>
//                 <a href="#features" className="scroll-bottom">
//                   <i className="lni lni-arrow-down"></i>
//                 </a>
//               </div>
//             </div>
//             <div className="col-lg-6">
//               <div className="hero-img wow fadeInUp" data-wow-delay={'1s'}>
//                 <img src={require('./assets/img/hero/hero-img.png')} alt="" />
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       {/* <!-- ======== hero-section end ======== --> */}
//       {/* <!-- ======== feature-section start ======== --> */}
//       <section id="features" className="feature-section pt-120">
//         <div className="container">
//           <div className="row justify-content-center">
//             <div className="col-lg-4 col-md-8 col-sm-10">
//               <div className="single-feature">
//                 <div className="icon">
//                   <i className="lni lni-bootstrap"></i>
//                 </div>
//                 <div className="content">
//                   <h3>Bootstrap 5</h3>
//                   <p>
//                     Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
//                     diam nonumy eirmod tempor invidunt ut labore
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-4 col-md-8 col-sm-10">
//               <div className="single-feature">
//                 <div className="icon">
//                   <i className="lni lni-layout"></i>
//                 </div>
//                 <div className="content">
//                   <h3>Clean Design</h3>
//                   <p>
//                     Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
//                     diam nonumy eirmod tempor invidunt ut labore
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-4 col-md-8 col-sm-10">
//               <div className="single-feature">
//                 <div className="icon">
//                   <i className="lni lni-coffee-cup"></i>
//                 </div>
//                 <div className="content">
//                   <h3>Easy to Use</h3>
//                   <p>
//                     Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
//                     diam nonumy eirmod tempor invidunt ut labore
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       {/* <!-- ======== feature-section end ======== --> */}
//       {/* <!-- ======== about-section start ======== --> */}
//       <section id="about" className="about-section pt-150">
//         <div className="container">
//           <div className="row align-items-center">
//             <div className="col-xl-6 col-lg-6">
//               <div className="about-img">
//                 <img
//                   src={require('./assets/img/about/about-1.png')}
//                   alt=""
//                   className="w-100"
//                 />
//                 <img
//                   src={require('./assets/img/about/about-left-shape.svg')}
//                   alt=""
//                   className="shape shape-1"
//                 />
//                 <img
//                   src={require('./assets/img/about/left-dots.svg')}
//                   alt=""
//                   className="shape shape-2"
//                 />
//               </div>
//             </div>
//             <div className="col-xl-6 col-lg-6">
//               <div className="about-content">
//                 <div className="section-title mb-30">
//                   <h2 className="mb-25 wow fadeInUp" data-wow-delay={'1s'}>
//                     Perfect Solution Thriving Online Business
//                   </h2>
//                   <p className="wow fadeInUp" data-wow-delay={'1s'}>
//                     Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
//                     dinonumy eirmod tempor invidunt ut labore et dolore magna
//                     aliquyam erat, sed diam voluptua. At vero eos et accusam et
//                     justo duo dolores et ea rebum. Stet clita kasd gubergren, no
//                     sea takimata sanctus est Lorem.Lorem ipsum dolor sit amet.
//                   </p>
//                 </div>
//                 <a
//                   href="#"
//                   className="main-btn btn-hover border-btn wow fadeInUp"
//                   data-wow-delay={'1s'}>
//                   Discover More
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       {/* <!-- ======== about-section end ======== --> */}
//       {/* <!-- ======== about2-section start ======== --> */}
//       <section id="about" className="about-section pt-150">
//         <div className="container">
//           <div className="row align-items-center">
//             <div className="col-xl-6 col-lg-6">
//               <div className="about-content">
//                 <div className="section-title mb-30">
//                   <h2 className="mb-25 wow fadeInUp" data-wow-delay={'1s'}>
//                     Easy to Use with Tons of Awesome Features
//                   </h2>
//                   <p className="wow fadeInUp" data-wow-delay={'1s'}>
//                     Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
//                     diam nonumy eirmod tempor invidunt ut labore et dolore magna
//                     aliquyam erat, sed diam voluptua.
//                   </p>
//                 </div>
//                 <ul>
//                   <li>Quick Access</li>
//                   <li>Easily to Manage</li>
//                   <li>24/7 Support</li>
//                 </ul>
//                 <a
//                   href="#"
//                   className="main-btn btn-hover border-btn wow fadeInUp"
//                   data-wow-delay={'1s'}>
//                   Learn More
//                 </a>
//               </div>
//             </div>
//             <div className="col-xl-6 col-lg-6 order-first order-lg-last">
//               <div className="about-img-2">
//                 <img
//                   src={require('./assets/img/about/about-2.png')}
//                   alt=""
//                   className="w-100"
//                 />
//                 <img
//                   src={require('./assets/img/about/about-right-shape.svg')}
//                   alt=""
//                   className="shape shape-1"
//                 />
//                 <img
//                   src={require('./assets/img/about/right-dots.svg')}
//                   alt=""
//                   className="shape shape-2"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       {/* <!-- ======== about2-section end ======== --> */}
//       {/* <!-- ======== feature-section start ======== --> */}
//       <section id="why" className="feature-extended-section pt-100">
//         <div className="feature-extended-wrapper">
//           <div className="container">
//             <div className="row justify-content-center">
//               <div className="col-xxl-5 col-xl-6 col-lg-8 col-md-9">
//                 <div className="section-title text-center mb-60">
//                   <h2 className="mb-25 wow fadeInUp" data-wow-delay={'1s'}>
//                     Why Choose SaaSpal
//                   </h2>
//                   <p className="wow fadeInUp" data-wow-delay={'1s'}>
//                     Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
//                     diam nonumy eirmod tempor invidunt ut labore et dolore
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-lg-4 col-md-6">
//                 <div className="single-feature-extended">
//                   <div className="icon">
//                     <i className="lni lni-display"></i>
//                   </div>
//                   <div className="content">
//                     <h3>SaaS Focused</h3>
//                     <p>
//                       Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
//                       sed diam nonumy eirmod tempor invidunt ut labore
//                     </p>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-lg-4 col-md-6">
//                 <div className="single-feature-extended">
//                   <div className="icon">
//                     <i className="lni lni-leaf"></i>
//                   </div>
//                   <div className="content">
//                     <h3>Awesome Design</h3>
//                     <p>
//                       Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
//                       sed diam nonumy eirmod tempor invidunt ut labore
//                     </p>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-lg-4 col-md-6">
//                 <div className="single-feature-extended">
//                   <div className="icon">
//                     <i className="lni lni-grid-alt"></i>
//                   </div>
//                   <div className="content">
//                     <h3>Ready to Use</h3>
//                     <p>
//                       Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
//                       sed diam nonumy eirmod tempor invidunt ut labore
//                     </p>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-lg-4 col-md-6">
//                 <div className="single-feature-extended">
//                   <div className="icon">
//                     <i className="lni lni-javascript"></i>
//                   </div>
//                   <div className="content">
//                     <h3>Vanilla JS</h3>
//                     <p>
//                       Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
//                       sed diam nonumy eirmod tempor invidunt ut labore
//                     </p>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-lg-4 col-md-6">
//                 <div className="single-feature-extended">
//                   <div className="icon">
//                     <i className="lni lni-layers"></i>
//                   </div>
//                   <div className="content">
//                     <h3>Essential Sections</h3>
//                     <p>
//                       Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
//                       sed diam nonumy eirmod tempor invidunt ut labore
//                     </p>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-lg-4 col-md-6">
//                 <div className="single-feature-extended">
//                   <div className="icon">
//                     <i className="lni lni-rocket"></i>
//                   </div>
//                   <div className="content">
//                     <h3>Highly Optimized</h3>
//                     <p>
//                       Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
//                       sed diam nonumy eirmod tempor invidunt ut labore
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       {/* <!-- ======== feature-section end ======== --> */}
//       {/* <!-- ======== pricing-section start ======== --> */}
//       <section id="pricing" className="pricing-section pt-120 pb-120">
//         <div className="container">
//           <div className="row justify-content-center">
//             <div className="col-xxl-5 col-xl-6 col-lg-8 col-md-9">
//               <div className="section-title text-center mb-35">
//                 <h2 className="mb-25 wow fadeInUp" data-wow-delay={'1s'}>
//                   Choose a Plan
//                 </h2>
//                 <p className="wow fadeInUp" data-wow-delay={'1s'}>
//                   Lorem ipsum dolor sit amet consetetur sadipscing elitr sswed
//                   diam nonumy eirmod tempor nvidunt.
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="pricing-nav-wrapper mb-60">
//             <ul className="nav nav-pills" id="pills-tab" role="tablist">
//               <li role="presentation">
//                 <a
//                   className="active"
//                   id="pills-month-tab"
//                   data-bs-toggle="pill"
//                   href="#pills-month"
//                   role="tab"
//                   aria-controls="pills-month"
//                   aria-selected="true">
//                   Monthly
//                 </a>
//               </li>
//               <li role="presentation">
//                 <a
//                   id="pills-year-tab"
//                   data-bs-toggle="pill"
//                   href="#pills-year"
//                   role="tab"
//                   aria-controls="pills-year"
//                   aria-selected="false">
//                   Yearly
//                 </a>
//               </li>
//             </ul>
//           </div>
//           <div className="tab-content" id="pills-tabContent">
//             <div
//               className="tab-pane fade show active"
//               id="pills-month"
//               role="tabpanel"
//               aria-labelledby="pills-month-tab">
//               <div className="row justify-content-center">
//                 <div className="col-lg-4 col-md-8 col-sm-10">
//                   <div className="single-pricing">
//                     <div className="pricing-header">
//                       <h1 className="price">$36</h1>
//                       <h3 className="package-name">Basic Account</h3>
//                     </div>
//                     <div className="content">
//                       <ul>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> Unlimited
//                           Acces
//                         </li>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> Single
//                           User
//                         </li>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> Unlimited
//                           Storage
//                         </li>
//                         <li>
//                           <i className="lni lni-close"></i> 24/7 Support
//                         </li>
//                         <li>
//                           <i className="lni lni-close"></i> Free Future Updates
//                         </li>
//                       </ul>
//                     </div>
//                     <div className="pricing-btn">
//                       <a href="#" className="main-btn btn-hover border-btn">
//                         Get Start
//                       </a>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-lg-4 col-md-8 col-sm-10">
//                   <div className="single-pricing">
//                     <div className="pricing-header">
//                       <h1 className="price">$56</h1>
//                       <h3 className="package-name">Standard Account</h3>
//                     </div>
//                     <div className="content">
//                       <ul>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> Unlimited
//                           Acces
//                         </li>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> 20+ Users
//                         </li>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> Unlimited
//                           Storage
//                         </li>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> 24/7
//                           Support
//                         </li>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> Free
//                           Future Updates
//                         </li>
//                       </ul>
//                     </div>
//                     <div className="pricing-btn">
//                       <a href="#" className="main-btn btn-hover">
//                         Get Start
//                       </a>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-lg-4 col-md-8 col-sm-10">
//                   <div className="single-pricing">
//                     <div className="pricing-header">
//                       <h1 className="price">$89</h1>
//                       <h3 className="package-name">Premium Account</h3>
//                     </div>
//                     <div className="content">
//                       <ul>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> Unlimited
//                           Acces
//                         </li>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> Unlimited
//                           Users
//                         </li>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> Unlimited
//                           Storage
//                         </li>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> 24/7
//                           Support
//                         </li>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> Free
//                           Future Updates
//                         </li>
//                       </ul>
//                     </div>
//                     <div className="pricing-btn">
//                       <a href="#" className="main-btn btn-hover border-btn">
//                         Get Start
//                       </a>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div
//               className="tab-pane fade"
//               id="pills-year"
//               role="tabpanel"
//               aria-labelledby="pills-year-tab">
//               <div className="row justify-content-center">
//                 <div className="col-lg-4 col-md-8 col-sm-10">
//                   <div className="single-pricing">
//                     <div className="pricing-header">
//                       <h1 className="price">$136</h1>
//                       <h3 className="package-name">Basic Account</h3>
//                     </div>
//                     <div className="content">
//                       <ul>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> Unlimited
//                           Acces
//                         </li>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> Single
//                           User
//                         </li>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> Unlimited
//                           Storage
//                         </li>
//                         <li>
//                           <i className="lni lni-close"></i> 24/7 Support
//                         </li>
//                         <li>
//                           <i className="lni lni-close"></i> Free Future Updates
//                         </li>
//                       </ul>
//                     </div>
//                     <div className="pricing-btn">
//                       <a href="#" className="main-btn btn-hover border-btn">
//                         Get Start
//                       </a>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-lg-4 col-md-8 col-sm-10">
//                   <div className="single-pricing">
//                     <div className="pricing-header">
//                       <h1 className="price">$156</h1>
//                       <h3 className="package-name">Standard Account</h3>
//                     </div>
//                     <div className="content">
//                       <ul>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> Unlimited
//                           Acces
//                         </li>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> 20+ Users
//                         </li>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> Unlimited
//                           Storage
//                         </li>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> 24/7
//                           Support
//                         </li>
//                         <li>
//                           <i className="lni lni-close"></i> Free Future Updates
//                         </li>
//                       </ul>
//                     </div>
//                     <div className="pricing-btn">
//                       <a href="#" className="main-btn btn-hover">
//                         Get Start
//                       </a>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-lg-4 col-md-8 col-sm-10">
//                   <div className="single-pricing">
//                     <div className="pricing-header">
//                       <h1 className="price">$189</h1>
//                       <h3 className="package-name">Premium Account</h3>
//                     </div>
//                     <div className="content">
//                       <ul>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> Unlimited
//                           Acces
//                         </li>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> Unlimited
//                           Users
//                         </li>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> Unlimited
//                           Storage
//                         </li>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> 24/7
//                           Support
//                         </li>
//                         <li>
//                           <i className="lni lni-checkmark active"></i> Free
//                           Future Updates
//                         </li>
//                       </ul>
//                     </div>
//                     <div className="pricing-btn">
//                       <a href="#" className="main-btn btn-hover border-btn">
//                         Get Start
//                       </a>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       {/* <!-- ======== pricing-section end ======== --> */}
//       {/* <!-- ======== testimonials-section start ======== --> */}
//       <section id="testimonials" className="testimonial-section">
//         <div className="container">
//           <div className="section-title text-center">
//             <h2 className="mb-30">What our customers says</h2>
//           </div>
//           <div className="testimonial-active-wrapper">
//             <div className="shapes">
//               <img
//                 alt=""
//                 className="shape shape-1"
//                 data-pagespeed-url-hash="4007555226"
//                 src={require('./assets/img/testimonial/testimonial-shape.svg')}
//               />
//               <img
//                 alt=""
//                 className="shape shape-2"
//                 data-pagespeed-url-hash="1224640497"
//                 src={require('./assets/img/testimonial/testimonial-dots.svg')}
//               />
//             </div>
//             <div className="tns-outer" id="tns1-ow">
//               <div className="tns-controls" aria-label="Carousel Navigation">
//                 <button type="button" data-controls="prev" aria-controls="tns1">
//                   <i className="lni lni-chevron-left prev"></i>
//                 </button>
//                 <button type="button" data-controls="next" aria-controls="tns1">
//                   <i className="lni lni-chevron-right next"></i>
//                 </button>
//               </div>
//               <div
//                 className="tns-liveregion tns-visually-hidden"
//                 aria-live="polite"
//                 aria-atomic="true">
//                 slide <span className="current">1</span> of 3
//               </div>
//               <div className="tns-inner" id="tns1-iw">
//                 <div
//                   className="testimonial-active tns-slider tns-gallery tns-subpixel tns-calc tns-horizontal"
//                   id="tns1">
//                   <div
//                     className="single-testimonial tns-item tns-slide-active tns-fadeIn"
//                     id="tns1-item0"
//                     style={{ left: '0%' }}>
//                     <div className="row">
//                       <div className="col-xl-5 col-lg-5">
//                         <div className="testimonial-img">
//                           <img
//                             alt=""
//                             src={require('./assets/img/testimonial/xtestimonial-1.png.pagespeed.ic.W3koGgYPK-.webp')}
//                           />
//                           <div className="quote">
//                             <i className="lni lni-quotation"></i>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="col-xl-6 offset-xl-1 col-lg-6 offset-lg-1">
//                         <div className="content-wrapper">
//                           <div className="content">
//                             <p>
//                               Lorem ipsum dolor sit amet, consetetur sadipscing
//                               elitr, sed dinonumy eirmod tempor invidunt ut
//                               labore et dolore magna aliquyam erat, sed diam
//                               voluptua. At vero eos et accusam et justo duo
//                               dolores et ea rebum. Stet clita kasd gubergren, no
//                               sea takimata sanctus est Lorem.
//                             </p>
//                           </div>
//                           <div className="info">
//                             <h4>Jonathon Smith</h4>
//                             <p>Developer and Youtuber</p>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div
//                     className="single-testimonial tns-item tns-normal"
//                     id="tns1-item1"
//                     aria-hidden="true">
//                     <div className="row">
//                       <div className="col-xl-5">
//                         <div className="testimonial-img">
//                           <img
//                             src={require('./assets/img/testimonial/xtestimonial-2.png.pagespeed.ic.W6Fm7Qj6C2.webp')}
//                           />
//                           <div className="quote">
//                             <i className="lni lni-quotation"></i>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="col-xl-6 offset-xl-1">
//                         <div className="content-wrapper">
//                           <div className="content">
//                             <p>
//                               Lorem ipsum dolor sit amet, consetetur sadipscing
//                               elitr, sed dinonumy eirmod tempor invidunt ut
//                               labore et dolore magna aliquyam erat, sed diam
//                               voluptua. At vero eos et accusam et justo duo
//                               dolores et ea rebum. Stet clita kasd gubergren, no
//                               sea takimata sanctus est Lorem.
//                             </p>
//                           </div>
//                           <div className="info">
//                             <h4>Gray Simon</h4>
//                             <p>UIX Designer and Developer</p>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div
//                     className="single-testimonial tns-item tns-normal"
//                     id="tns1-item2"
//                     aria-hidden="true">
//                     <div className="row">
//                       <div className="col-xl-5">
//                         <div className="testimonial-img">
//                           <img
//                             alt=""
//                             src={require('./assets/img/testimonial/xtestimonial-3.png.pagespeed.ic.1_BnZskl1y.webp')}
//                           />
//                           <div className="quote">
//                             <i className="lni lni-quotation"></i>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="col-xl-6 offset-xl-1">
//                         <div className="content-wrapper">
//                           <div className="content">
//                             <p>
//                               Lorem ipsum dolor sit amet, consetetur sadipscing
//                               elitr, sed dinonumy eirmod tempor invidunt ut
//                               labore et dolore magna aliquyam erat, sed diam
//                               voluptua. At vero eos et accusam et justo duo
//                               dolores et ea rebum. Stet clita kasd gubergren, no
//                               sea takimata sanctus est Lorem.
//                             </p>
//                           </div>
//                           <div className="info">
//                             <h4>Michel Smith</h4>
//                             <p>Traveler and Vloger</p>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div
//                     className="single-testimonial tns-item tns-normal tns-slide-cloned"
//                     aria-hidden="true">
//                     <div className="row">
//                       <div className="col-xl-5 col-lg-5">
//                         <div className="testimonial-img">
//                           <img
//                             alt=""
//                             src={require('./assets/img/testimonial/xtestimonial-1.png.pagespeed.ic.W3koGgYPK-.webp')}
//                           />
//                           <div className="quote">
//                             <i className="lni lni-quotation"></i>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="col-xl-6 offset-xl-1 col-lg-6 offset-lg-1">
//                         <div className="content-wrapper">
//                           <div className="content">
//                             <p>
//                               Lorem ipsum dolor sit amet, consetetur sadipscing
//                               elitr, sed dinonumy eirmod tempor invidunt ut
//                               labore et dolore magna aliquyam erat, sed diam
//                               voluptua. At vero eos et accusam et justo duo
//                               dolores et ea rebum. Stet clita kasd gubergren, no
//                               sea takimata sanctus est Lorem.
//                             </p>
//                           </div>
//                           <div className="info">
//                             <h4>Jonathon Smith</h4>
//                             <p>Developer and Youtuber</p>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       {/* <!-- ======== testimonials-section end ======== --> */}
//       {/* <!-- ======== subscribe-section start ======== --> */}
//       <section id="contact" className="subscribe-section pt-120">
//         <div className="container">
//           <div className="subscribe-wrapper img-bg">
//             <div className="row align-items-center">
//               <div className="col-xl-6 col-lg-7">
//                 <div className="section-title mb-15">
//                   <h2 className="text-white mb-25">Subscribe Our Newsletter</h2>
//                   <p className="text-white pr-5">
//                     Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
//                     diam nonumy eirmod tempor
//                   </p>
//                 </div>
//               </div>
//               <div className="col-xl-6 col-lg-5">
//                 <form action="#" className="subscribe-form">
//                   <input
//                     type="email"
//                     name="subs-email"
//                     id="subs-email"
//                     placeholder="Your Email"
//                   />
//                   <button type="submit" className="main-btn btn-hover">
//                     Subscribe
//                   </button>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       {/* <!-- ======== subscribe-section end ======== --> */}
//       {/* <!-- ======== footer start ======== --> */}
//       <footer className="footer">
//         <div className="container">
//           <div className="widget-wrapper">
//             <div className="row">
//               <div className="col-xl-4 col-lg-4 col-md-6">
//                 <div className="footer-widget">
//                   <div className="logo mb-30">
//                     <a href="index.html">
//                       <img src={require('./assets/img/logo/logo.svg')} alt="" />
//                     </a>
//                   </div>
//                   <p className="desc mb-30 text-white">
//                     Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
//                     dinonumy eirmod tempor invidunt.
//                   </p>
//                   <ul className="socials">
//                     <li>
//                       <a href="#">
//                         <i className="lni lni-facebook-filled"></i>
//                       </a>
//                     </li>
//                     <li>
//                       <a href="#">
//                         <i className="lni lni-twitter-filled"></i>
//                       </a>
//                     </li>
//                     <li>
//                       <a href="#">
//                         <i className="lni lni-instagram-filled"></i>
//                       </a>
//                     </li>
//                     <li>
//                       <a href="#">
//                         <i className="lni lni-linkedin-original"></i>
//                       </a>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//               <div className="col-xl-2 col-lg-2 col-md-6">
//                 <div className="footer-widget">
//                   <h3>About Us</h3>
//                   <ul className="links">
//                     <li>
//                       <a href="#">Home</a>
//                     </li>
//                     <li>
//                       <a href="#">Feature</a>
//                     </li>
//                     <li>
//                       <a href="#">About</a>
//                     </li>
//                     <li>
//                       <a href="#">Testimonials</a>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//               <div className="col-xl-3 col-lg-3 col-md-6">
//                 <div className="footer-widget">
//                   <h3>Features</h3>
//                   <ul className="links">
//                     <li>
//                       <a href="#">How it works</a>
//                     </li>
//                     <li>
//                       <a href="#">Privacy policy</a>
//                     </li>
//                     <li>
//                       <a href="#">Terms of service</a>
//                     </li>
//                     <li>
//                       <a href="#">Refund policy</a>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//               <div className="col-xl-3 col-lg-3 col-md-6">
//                 <div className="footer-widget">
//                   <h3>Other Products</h3>
//                   <ul className="links">
//                     <li>
//                       <a href="#">Accounting Software</a>
//                     </li>
//                     <li>
//                       <a href="#">Billing Software</a>
//                     </li>
//                     <li>
//                       <a href="#">Booking System</a>
//                     </li>
//                     <li>
//                       <a href="#">Tracking System</a>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </footer>
//       {/* <!-- ======== footer end ======== --> */}
//       {/* <!-- ======== scroll-top ======== --> */}
//       <a href="#" className="scroll-top btn-hover">
//         <i className="lni lni-chevron-up"></i>
//       </a>
//     </div>
//   );
// };
