import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import { makeStyles } from '@material-ui/styles';
import theme from 'theme';
import { TableCell, TableRow, TableSortLabel } from '@material-ui/core';

const usestyles = makeStyles({
  headCell: {
    padding: theme.spacing(1.5),
    paddingRight: theme.spacing(0.5),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2)
    }
  },
  emptyHeadCell: {
    padding: 0
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
});

function SortableTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const classes = usestyles();
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const headCells = !isMobile
    ? [
        { id: 'name', label: 'Name' },
        { id: 'group', label: 'Sub Group' },
        { id: 'pace', label: 'Base Pace' },
        { id: 'runsPerWeek', label: 'Runs Per Week' },
        { id: 'mobile', label: 'Mobile' }
      ]
    : [
        { id: 'name', label: 'Name' },
        { id: 'group', label: 'Sub Group' },
        { id: 'pace', label: 'Base Pace' },
        { id: 'runsPerWeek', label: 'Runs Per Week' }
      ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            className={classes.headCell}
            key={headCell.id}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell className={classes.emptyHeadCell} />
      </TableRow>
    </TableHead>
  );
}

SortableTableHead.propTypes = {
  // classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default SortableTableHead;
