import { Close } from '@material-ui/icons';
import icon from '../assets/icon.png';
import '../css/header.css';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const Header = props => {
  const { runnerName, workoutSummary, handleModalClose } = props;

  const getTime = () => {
    if (!workoutSummary) return '';

    let date = new Date(workoutSummary.startTimeInSeconds * 1000);
    return `${months[
      date.getMonth()
    ].toUpperCase()} ${date.getDate()}, ${date.getFullYear()} @ ${date.getHours()}:${date.getMinutes()}`;
  };

  return (
    <div className="header">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
        <div>
          <span className="activity-username">{runnerName.toUpperCase()}</span>
          <span className="activity-date"> &nbsp;ON {getTime()}</span>
        </div>
        <Close
          name='close'
          style={{
            cursor: 'pointer'
          }}
          onClick={handleModalClose}
        />
      </div>
      <div className="activity-name">
        <img src={icon} />
        {workoutSummary?.activityName ? workoutSummary.activityName : ''}
      </div>
    </div>
  );
};

export default Header;
