import { parsePhoneNumberFromString, isValidPhoneNumber } from 'libphonenumber-js';

export const normalizePhone = value => {
  if (!value) return value;
  const phoneNumber = parsePhoneNumberFromString(value);
  if (!phoneNumber) return `+${value.replace(/\D/g, '')}`;
  return phoneNumber.formatInternational();
}

export const validatePhoneNumber = (value) => {
  if (!value) return true
  return isValidPhoneNumber(value)
}