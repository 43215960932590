import React from 'react';
import HeroSectImg from '../assets/img/hero/runners_weekly_plan_web.png';
import Fade from '@material-ui/core/Fade';

const HeroSection = () => {
  return (
    <Fade in={true} timeout={1000}>
      <section id="home" className="hero-section">
        <div className="container">
          <div className="row align-items-center position-relative">
            <div className="col-lg-6">
              <div className="hero-content">
                <h1 className="wow fadeInUp" data-wow-delay=".4s">
                  Supercharge your trainings with the power of SUB-X
                </h1>

                <p className="wow fadeInUp" data-wow-delay=".6s">
                  Save time and focus on what matters most. Harness the power of
                  technology to personalize and optimize your training plans.
                </p>
                <a
                  className="main-btn border-btn btn-hover wow fadeInUp"
                  data-wow-delay={'1s'}
                  href="/sign-up">
                  Sign-up
                </a>
                <a href="#benefits" className="scroll-bottom">
                  <i className="lni lni-arrow-down"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hero-img wow fadeInUp" data-wow-delay=".5s">
                <img
                  src={HeroSectImg}
                  style={{ maxWidth: '833px', maxHeight: '614px' }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fade>
  );
};

export default HeroSection;
