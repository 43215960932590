import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
  bgTransparent: {
    backgroundColor: 'transparent'
  },
  grayPaper: {
    backgroundColor: '#d9d9d9',
    boxShadow: 'none',
    marginTop: 5,
    paddingLeft: 3,
    paddingRight: 3
  },
  grayPaperbox: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      width: 100
    }
  },
  workoutName: {
    fontWeight: 'thin',
    fontSize: 20
  }
}));

export default function TableHeading(props) {
  const classes = useStyles();

  return (
    <TableHead className={classes.bgTransparent}>
      <TableRow>
        <TableCell className={classes.workoutName}>Workout Name</TableCell>
        <TableCell align="center">{/* <strong>Name</strong> */}</TableCell>
        <TableCell
          data-test={'desktopWeeklyPlanTableHeading-warmUpTableCell'}
          align="center">
          <strong>Warm Up</strong> <br />
          <Paper className={classes.grayPaper}>Duration</Paper>
        </TableCell>
        <TableCell
          data-test={'desktopWeeklyPlanTableHeading-repetitionsTableCell'}
          align="center">
          <strong>Repetitions</strong> <br />
          <Paper className={classes.grayPaper}>#</Paper>
        </TableCell>
        <TableCell
          data-test={'desktopWeeklyPlanTableHeading-intensePartTableCell'}
          align="center">
          <strong>Intense Part</strong> <br />
          <div className={classes.grayPaperbox}>
            <Paper className={classes.grayPaper}>Duration</Paper> &nbsp;
            <Paper className={classes.grayPaper}>Pace</Paper>
          </div>
        </TableCell>
        <TableCell
          data-test={'desktopWeeklyPlanTableHeading-recoveryPartTableCell'}
          align="center">
          <strong>Recovery Part</strong> <br />
          <div className={classes.grayPaperbox}>
            <Paper className={classes.grayPaper}>Duration</Paper> &nbsp;
            <Paper className={classes.grayPaper}>Pace</Paper>
          </div>
        </TableCell>
        <TableCell
          data-test={'desktopWeeklyPlanTableHeading-coolDownTableCell'}
          align="center">
          <strong>Cool Down</strong> <br />
          <Paper className={classes.grayPaper}>Duration</Paper>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
