import { useEffect, useState } from 'react';

const formatDistance = d => {
  if (d > 980) {
    return `${(d / 1000).toFixed(2)} km`;
  } else {
    return `${Math.round(d)} m`;
  }
};

const convertDecimalPaceToMMSS = paceInDecimal => {
  const minutes = Math.floor(paceInDecimal);
  const decimal = paceInDecimal - minutes;
  const seconds = Math.round(decimal * 60);
  const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
  return `${minutes}:${formattedSeconds}`;
};

const timeFormat = t => {
  let h = Math.floor(t / 3600);
  let m = Math.floor((t % 3600) / 60);
  let s = Math.floor((t % 3600) % 60);
  if (s < 10) s = `0${s}`;
  if (h > 0) {
    if (m < 10) m = `0${m}`;
    return `${h}:${m}:${s}`;
  } else {
    return `${m}:${s}`;
  }
};

const Intervals = props => {
  const { type, samples, laps } = props;

  const color = '#ecd460';
  const [tableData, setTableData] = useState([]);



  useEffect(() => {
    let data = [];
    let cumulatedTime = 0;

    laps.forEach((lap, i) => {
      const start = samples.findIndex(
        sample => sample.startTimeInSeconds >= lap.startTimeInSeconds
      );
      const end =
        i < laps.length - 1
          ? samples.findIndex(
            sample =>
              sample.startTimeInSeconds >= laps[i + 1].startTimeInSeconds
          )
          : samples.length - 1;

      if (start === -1 || end === -1) return;

      const lapSamples = samples.slice(start, end + 1);
      if (lapSamples.length < 3) return;

      const cumulatedHeartrate = lapSamples.reduce(
        (acc, sample) => acc + sample.heartRate,
        0
      );

      const cumulatedSpeed = lapSamples.reduce(
        (acc, sample) => acc + (sample.speedMetersPerSecond || 0),
        0
      );

      const maxHeartrate = Math.max(
        ...lapSamples.map(sample => sample.heartRate)
      );

      const ascent = lapSamples.reduce((acc, sample, index) => {
        if (index === lapSamples.length - 1) return acc;
        const elevationChange =
          lapSamples[index + 1].elevationInMeters - sample.elevationInMeters;
        return elevationChange > 0 ? acc + elevationChange : acc;
      }, 0);

      const descent = lapSamples.reduce((acc, sample, index) => {
        if (index === lapSamples.length - 1) return acc;
        const elevationChange =
          lapSamples[index + 1].elevationInMeters - sample.elevationInMeters;
        return elevationChange < 0 ? acc + Math.abs(elevationChange) : acc;
      }, 0);

      const lapTime = (
        16.666666667 / (cumulatedSpeed / lapSamples.length) * 60
      )*(
        (lapSamples[lapSamples.length - 1].totalDistanceInMeters -
              lapSamples[0].totalDistanceInMeters)/1000
      )

      cumulatedTime += lapTime;
        
      data.push({
        lap: i + 1,
        time: timeFormat(lapTime),
        distance: formatDistance(
          lapSamples[lapSamples.length - 1].totalDistanceInMeters -
            lapSamples[0].totalDistanceInMeters
        ),
        cumulativeTime: timeFormat(cumulatedTime),
        heartrate: Math.round(cumulatedHeartrate / lapSamples.length),
        maxHeartrate,
        totalAscent: `${Math.round(ascent)} m`,
        totalDescent: `${Math.round(descent)} m`,
        cumulativeDistance: formatDistance(
          lapSamples[lapSamples.length - 1].totalDistanceInMeters
        ),
        averagePace: convertDecimalPaceToMMSS(
          (16.666666667 / (cumulatedSpeed / lapSamples.length)).toFixed(2)
        )
      });
    });

    setTableData(data);
  }, [samples, laps]);

  return (
    <div>
      <div className="info">
        <div className="color" style={{ background: color }}></div> Intervals
      </div>
      <table className="workoutAnalysisIntervalTable">
        <tbody>
          <tr>
            <th>Lap</th>
            <th>Time</th>
            <th>Distance</th>
            <th>Avg. Pace</th>
            <th>Avg HR</th>
            <th className="removable">Max HR</th>
            { (type == 'RUNNING') && <th className="removable-small">Total Ascent</th>}
            { (type == 'RUNNING') && <th className="removable">Total Descent</th>}
            <th className="removable">Cumulative Time</th>
            <th className="removable">Cumulative Distance</th>
          </tr>
          {tableData.map((lap, index) => (
            <tr key={index}>
              <td>{lap.lap}</td>
              <td>{lap.time}</td>
              <td>{lap.distance}</td>
              <td>{lap.averagePace}</td>
              <td>{lap.heartrate}</td>
              <td className="removable">{lap.maxHeartrate}</td>
              { (type == 'RUNNING') && <td className="removable-small">{lap.totalAscent}</td>}
              { (type == 'RUNNING') && <td className="removable">{lap.totalDescent}</td>}
              <td className="removable">{lap.cumulativeTime}</td>
              <td className="removable">{lap.cumulativeDistance}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
};

export default Intervals;
