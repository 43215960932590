export default (
  intensity,
  durationType = null,
  durationValue = null,
  targetType = null,
  targetValueLow = null,
  targetValueHigh = null
) => ({
  type: 'WorkoutStep',
  stepOrder: null, // Int number
  intensity, // REST, WARMUP, COOLDOWN, RECOVERY, INTERVAL
  description: null,
  durationType, // TIME, DISTANCE, OPEN
  durationValue,
  durationValueType: null,
  targetType, // PACE
  targetValue: null,
  targetValueLow,
  targetValueHigh,
  targetValueType: null,
  strokeType: null,
  equipmentType: null,
  exerciseCategory: null,
  exerciseName: null,
  weightValue: null,
  weightDisplayUnit: null,
  name: 'Workout Step'
});
