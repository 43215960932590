import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import WorkoutInputFields from './WorkoutInputFields';
import theme from 'theme';

const camelize = str => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

const useStyles = makeStyles(theme => ({
  box: {
    backgroundColor: '#F2F2F2',
    borderRadius: '14px',
    padding: '1rem .8rem',
    marginTop: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      padding: '1.4rem'
    }
  },
  box_top: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  box_title: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
    color: theme.palette.primary.main
  }
}));

function WorkoutStepTemplate(props) {
  // const { name, removeStep, step, trigger } = props;
  const { name, removeStep, step, isRunner } = props;

  const classes = useStyles();

  const handleDeleteWorkout = () => removeStep(step);

  return (
    <div
      data-test={`createWorkout-workoutStepTemplate-${camelize(step.name)}`}
      className={classes.box}>
      <div className={classes.box_top}>
        <Typography variant="h5" className={classes.box_title}>
          {name}
        </Typography>
        { (!isRunner) &&
        <DeleteOutlineIcon
          data-test={'workoutStepTemplate-deleteButton'}
          style={{ cursor: 'pointer', color: theme.palette.secondary.main }}
          onClick={handleDeleteWorkout}
        />
        }
      </div>
      <WorkoutInputFields isRunner={isRunner} step={step} /> {/* trigger={trigger} /> */}
    </div>
  );
}

export default WorkoutStepTemplate;
