import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import SnackbarNotification from 'components/SnackbarNotification';
let RouteWithLayoutDisplayNotification = (severity='info', message, onclose=()=>{}) => {};

const isValidSeverity = (severity) => {
  if (severity === 'info') return true;
  if (severity === 'success') return true;
  if (severity === 'error') return true;
  if (severity === 'warning') return true;
  return false;
}

const RouteWithLayout = props => {
  const {
    layout: Layout,
    component: Component,
    setUser,
    ...rest
  } = props;

  const [isNotification, setIsNotification] = React.useState(false);
  const [notificationMessage, setNotificationMessage] = React.useState('');
  const [notificationSeverity, setNotificationSeverity] = React.useState('');
  const [onNotificationClose, setOnNotificationClose] = React.useState(()=>{});

  
  RouteWithLayoutDisplayNotification = (severity='info', message, onclose=()=>{}) => {
    if (!severity) return;
    if (typeof severity === 'object') {
      if (!severity.name && severity.name == 'AxiosError') {
        if (severity.response) severity = severity.response
        else {
          setNotificationSeverity('error');
          setNotificationMessage('Network Error');
          setIsNotification(true);
          setOnNotificationClose(onclose);
        }
        return
      }
      const { success, message:messageObj, severity:severityObj, hasValue } = severity;
      if (success !== undefined && messageObj !== undefined && severityObj !== undefined && hasValue !== undefined) {
        setNotificationSeverity(severityObj);
        setNotificationMessage(messageObj || severityObj);
        setIsNotification(true);
        setOnNotificationClose(onclose);
        return;
      }
    }
    else {
      if (!message && !isValidSeverity(severity)) {
        setNotificationSeverity('info');
        setNotificationMessage(severity);
        setIsNotification(true);
        setOnNotificationClose(onclose);
        return
      }
      setNotificationSeverity(severity);
      setNotificationMessage(message);
      setIsNotification(true);
      setOnNotificationClose(onclose);
    }
  };

   

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout setUser={setUser}>
          <Component {...matchProps} setUser={setUser} />
          <SnackbarNotification
            handleClose={onNotificationClose}
            isNotificationDisplayed={isNotification}
            message={notificationMessage}
            setIsNotificationDisplayed={setIsNotification}
            severity={notificationSeverity}
          />
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export { RouteWithLayoutDisplayNotification };
export default RouteWithLayout;
