export default (distance = null) => ({
  type: 'WorkoutStep',
  stepOrder: 1,
  intensity: 'WARMUP',
  description: null,
  // durationType: distance ? 'DISTANCE' : 'OPEN',
  // durationValue: distance,
  durationType: null,
  durationValue: null,
  durationValueType: null,
  targetType: null,
  targetValue: null,
  targetValueLow: null,
  targetValueHigh: null,
  targetValueType: null,
  name: 'Warm Up'
});
