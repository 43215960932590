import React, { useState } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import EditOutlinedIcon from '@material-ui/icons/Edit';
import MobileCalendar from './MobileCalendar';
import MobileWorkoutWarmUpStepCard from './MobileWorkoutWarmUpStepCard';
import MobileWorkoutRepeatStepCard from './MobileWorkoutRepeatStepCard';
import MobileWorkoutCoolDownStepCard from './MobileWorkoutCoolDownStepCard';
import MobileWorkoutStepCard from './MobileWorkoutStepCard';
// import MobileButtonsGroup from './MobileButtonsGroup';
// import ConfirmationModal from '../ConfirmationModal';
import WorkoutChangeModal from '../WorkoutChangeModal/WorkoutChangeModal';
import WatchSyncIcon from '../WatchSyncIcon/WatchSyncIcon';
import UserContext from 'context/User';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     maxWidth: '100%',
//     borderRadius: 8,
//     padding: 0,
//     boxShadow: 'none',
//   },
// }));

export default function MobileWeeklyPlan(props) {
  const {
    runnerWeeklyWorkoutPlan,
    updateWeekView,
    type,
    handleWatchSyncIconClick
  } = props;
  const user = React.useContext(UserContext);
  // const classes = useStyles();

  const workoutsData =
    runnerWeeklyWorkoutPlan.runnerWorkoutsPlan ||
    runnerWeeklyWorkoutPlan.workouts;
  const [workouts, setWorkouts] = useState(workoutsData);
  const todayDayNumberOfTheWeek = new Date().getDay();
  const [activeDate, setActiveDate] = useState(
    workouts[todayDayNumberOfTheWeek]
  );
  activeDate.isActive = true;

  // if (type == 'WeeklyPlanPreviewModal')
  //   activeDate.workout = activeDate.workoutTemplate;

  const [steps, setSteps] = useState(
    activeDate.workoutTemplate?.steps || activeDate.workout?.steps || []
  );

  const [workoutName, setWorkoutName] = useState('');

  const [workoutDate, setWorkoutDate] = useState(
    `${activeDate.dayOfWeek}, ${activeDate.date} ${
      activeDate.isWorkout ? '' : ' -> Rest day!'
    }`
  );
  // const [open, setOpen] = useState(false);

  const setWorkout = async workout => {
    const revisedWorkouts = await workouts.map(item => {
      if (item.dateObject == workout.dateObject) {
        item.workout = null;
        item.isWorkout = false;
        if (type == 'WeeklyPlanPreviewModal' && workout.steps?.length > 0) {
          item.workoutTemplate = workout;
          item.isWorkout = true;
        }
        if (!(type == 'WeeklyPlanPreviewModal') && workout.steps?.length > 0) {
          item.workout = workout;
          item.isWorkout = true;
        }

        // if (type == 'WeeklyPlanPreviewModal') {
        //   item.workout = null;
        //   item.isWorkout = false;
        //   if (workout.steps?.length > 0) {
        //     item.workoutTemplate = workout;
        //     item.isWorkout = true;
        //   }
        // }
        // if (!(type == 'WeeklyPlanPreviewModal')) {
        //   item.workout = null;
        //   item.isWorkout = false;
        //   if (workout.steps?.length > 0) {
        //     item.workout = workout;
        //     item.isWorkout = true;
        //   }

        //   // if (workout.workout?.stpes?.length == 0 || !workout.workout?.stpes) {
        //   //   item.workout = null;
        //   //   item.isWorkout = false;
        //   // }
        // }
      }
      return item;
    });

    setWorkouts(revisedWorkouts);
  };

  React.useEffect(() => {
    setWorkoutDate(`${activeDate.dayOfWeek}, ${activeDate.date}`);
  }, [activeDate.date, activeDate.isWorkout]);

  React.useEffect(() => {
    setWorkoutName(activeDate.workout?.workoutName);
  }, [activeDate.workout?.workoutName]);

  const removeStep = async step => {
    let revisedWorkout;
    const revisedSteps = steps.filter(currentStep => currentStep != step);

    if (!revisedSteps.length && activeDate.workoutTemplate) {
      activeDate.isWorkout = false;
      const revisedWorkouts = await workouts.map(item => {
        item.isActive = false;
        if (item.date == activeDate.date) {
          item.isActive = true;
          item.workoutTemplate = null;
        }
        item.isWorkout = false;
        if (item.workoutTemplate?.steps?.length) item.isWorkout = true;
        return item;
      });

      setWorkouts(revisedWorkouts);
    }

    if (!revisedSteps.length && activeDate.workout) {
      activeDate.isWorkout = false;
      const revisedWorkouts = await workouts.map(item => {
        item.isActive = false;
        if (item.date == activeDate.date) {
          item.isActive = true;
          item.workout = null;
        }
        item.isWorkout = false;
        if (item.workout?.steps?.length) item.isWorkout = true;
        return item;
      });

      setWorkouts(revisedWorkouts);
    }

    if (activeDate.workoutTemplate) {
      const revisedWorkouts = await workouts.map(item => {
        if (item.date == activeDate.date) {
          item.workoutTemplate.steps = { ...revisedSteps };
        }
        return item;
      });

      setWorkouts(revisedWorkouts);
    }

    if (activeDate.workout) {
      const revisedWorkouts = await workouts.map(item => {
        if (item.date == activeDate.date) {
          item.workout.steps = { ...revisedSteps };
        }
        return item;
      });

      setWorkouts(revisedWorkouts);
    }

    setSteps(revisedSteps);
  };

  const handleEditWorkout = () => handleModalOpen();

  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  // const handleCancel = () => alert('Cancel');
  // const handleSave = () => alert('Save');
  // const handleDelete = () => alert('Delete');

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <MobileCalendar
        workouts={workouts}
        setActiveDate={setActiveDate}
        setSteps={setSteps}
      />
      <br />
      {user.role == 'trainer' ? (
        <div
          onClick={handleEditWorkout}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '-10px',
            // justifyContent: 'space-between',
            // width: '100%'
            flexWrap: 'wrap'
            // paddingBottom: 7,
          }}>
          <IconButton aria-label="settings">
            <EditOutlinedIcon />
          </IconButton>
          <Typography style={{ fontSize: 20, fontWeight: 400 }}>
            {workoutDate}
          </Typography>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            flexWrap: 'wrap',
            paddingBottom: 7
          }}>
          <Typography style={{ fontSize: 20, fontWeight: 400 }}>
            {workoutDate}
          </Typography>
          {activeDate.isWorkout && user.garminUserId && (
            <div onClick={() => handleWatchSyncIconClick(activeDate)}>
              <WatchSyncIcon color={'blue'} size={24} />
            </div>
          )}
        </div>
      )}
      <Typography
        style={{
          textAlign: 'center',
          padding: 7,
          fontSize: 25,
          fontWeight: 600
        }}>
        {activeDate.isWorkout
          ? <>
          {activeDate?.workout?.workoutName}
          
          <span style={{ fontSize: '14px', display: 'block', marginTop: '5px', fontWeight: 400, textAlign: 'left' }}>
            {activeDate?.workout?.workoutNotes}
          </span>
          </>
          : 'Rest day!'}
      </Typography>

      {activeDate.isWorkout &&
        steps.map((step, index) => {
          if (step.intensity == 'WARMUP')
            return (
              <MobileWorkoutWarmUpStepCard
                removeStep={removeStep}
                step={step}
                key={index}
                type={type}
              />
            );
          if (step.intensity == 'INTERVAL')
            return (
              <MobileWorkoutStepCard
                removeStep={removeStep}
                step={step}
                key={index}
                type={type}
              />
            );
          if (step.type == 'WorkoutRepeatStep')
            return (
              <MobileWorkoutRepeatStepCard
                removeStep={removeStep}
                step={step}
                key={index}
                type={type}
              />
            );
          if (step.intensity == 'COOLDOWN')
            return (
              <MobileWorkoutCoolDownStepCard
                removeStep={removeStep}
                step={step}
                key={index}
                type={type}
              />
            );
        })}
      {/* {!activeDate.isWorkout && (
        <Typography
          style={{
            paddingBottom: 7,
            fontSize: 15,
            fontWeight: 400,
            textAlign: 'center'
          }}>
          {`${activeDate.date} - Rest day!`}
        </Typography>
      )} */}
      {/* <MobileButtonsGroup
        handleCancel={handleCancel}
        handleSave={handleSave}
        handleDelete={handleDelete}
      />
      <br /> */}

      {/* <ConfirmationModal
        open={open}
        handleClose={() => {
          handleClose();
        }}
      /> */}
      <WorkoutChangeModal
        open={isModalOpen}
        workout={activeDate}
        isModalOpen={isModalOpen}
        handleModalClose={handleModalClose}
        type={type}
        updateWeekView={updateWeekView}
        setWorkoutName={setWorkoutName}
        setWorkoutSteps={setSteps}
        setWorkout={setWorkout}
      />
    </div>
  );
}
