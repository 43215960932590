/* eslint-disable react/no-multi-comp */
import React from 'react';
import '../css/summary.css';
const Template = ({title, value, unit}) => {
  return (
    (value != '' && value)?
    <div className="data-item">
        <div className="data-value">{`${value} ${unit?unit:''}`}</div>
        <div className="data-title">{title}</div>
    </div>:<></>
  )
}
const Summary = props => {
  const { workoutSummary } = props;

  const getDistance = () => {
    if (!workoutSummary) return '';
    return (workoutSummary.distanceInMeters / 1000).toFixed(2);
  };

  const getTime = () => {
    if (!workoutSummary) return '';
    let d = Number(workoutSummary.durationInSeconds);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (m < 10) m = `0${m}`;
    if (s < 10) s = `0${s}`;
    return `${h}:${m}:${s}`;
  };

  const getAvgPace = () => {
    if (!workoutSummary) return '';
    // Get the integer part (minutes)
    const minutes = Math.floor(workoutSummary.averagePaceInMinutesPerKilometer);
    // Get the decimal part and convert it to seconds
    const decimal = workoutSummary.averagePaceInMinutesPerKilometer - minutes;
    const seconds = Math.round(decimal * 60);
    // Format the seconds to always have two digits
    const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
    // Return the pace in 'mm:ss' format
    return `${minutes}:${formattedSeconds}`;
  };

  const getTotalAscent = () => {
    if (!workoutSummary) return '';
    return Math.round(workoutSummary.totalElevationGainInMeters);
  };

  const getCalories = () => {
    if (!workoutSummary) return '';
    return Math.round(workoutSummary.activeKilocalories).toLocaleString();
  };

  return (
    <div className="summary">
      <Template title={'Distance'} value={getDistance()} unit={'km'} />
      <Template title={'Time'} value={getTime()} />
      <Template title={'Avg Pace'} value={getAvgPace()} unit={'m/km'} />
      <Template title={'Total Ascent'} value={getTotalAscent()} unit={'m'} />
      <Template title={'Calories'} value={getCalories()} />
    </div>
  );
};

export default Summary;
