import React from 'react';
import Graph from './Graph';

const Pace = ({ samples }) => {
  const fortmatter = (sample)=>{
    return (16.666666667 / sample.speedMetersPerSecond).toFixed(2)
  }
  return (
    <Graph
      color={'#31a3e6'}
      samples={samples}
      reversed
      formatter={fortmatter}
      label={'Pace'}
      unit={'m/km'}
    />
  );
};

export default Pace;
