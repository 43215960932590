import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  modalPaper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 0,
  },
  modalContent: {
    padding: '30px 20px 10px 20px',
  },
  modalCloseBtnBg: {
    position: 'relative',
  },
  modalCloseBtn: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  modalAction: {
    display: 'flex',
    justifyContent: 'end',
  },
  customButton: {
    backgroundColor: '#969696',
    color: '#000000',
    fontWeight: 500,
    // marginLeft: 18,
    boxShadow: 'none',
    textTransform: 'none',
    marginTop: 7,
    paddingLeft: 30,
    paddingRight: 30,
  },
}));

function getModalStyle() {
  const top = 15;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    padding: 0,
    transform: `translateX(-${left}%)`,
  };
}

export default function ConfirmationModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      id="modal-test"
    >
      <div style={modalStyle} className={classes.modalPaper}>
        <div className={classes.modalCloseBtnBg}>
          <IconButton
            aria-label="delete"
            className={classes.modalCloseBtn}
            onClick={props.handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <div className={classes.modalContent}>
          <p id="simple-modal-description">Are you sure you want to delete workout?</p>
          <br />
          <br />
          <div className={classes.modalAction}>
            <Button
              variant="contained"
              size="small"
              className={classes.customButton}
              onClick={props.handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              className={classes.customButton}
              style={{ marginLeft: 5 }}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
