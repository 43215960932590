import { TableCell, TableRow } from '@material-ui/core';
import { styled, withStyles } from '@material-ui/styles';
import React, { useState } from 'react'
import { getWarmupDuration, getCooldownDuration, MainPart, LightTooltip } from 'views/WorkoutPlan/components/DesktopWeeklyPlanWorkoutLine';


const StyledTableCell = styled(TableCell)({
  backgroundColor: '#d9d9d9',
  border: 'solid 0px #3f51b5',
  borderStyle: 'solid none',
})
const FirstTableCell = styled(StyledTableCell)({
  borderLeftStyle: 'solid',
  borderTopLeftRadius: 10,
  borderBottomLeftRadius: 10
})
const FinalTableCell = styled(StyledTableCell)({
  borderRightStyle: 'solid',
  borderTopRightRadius: 10,
  borderBottomRightRadius: 10
})

const TemplateTableRow = ({template, setSelectedTemplate}) => {
  const maxNoteCharCount = 30
  const [hovered, setHovered] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true)
    if (template && (template.workoutNotes.length > maxNoteCharCount)) setShowTooltip(true)
  };

  const handleMouseLeave = () => {
    setShowTooltip(false)
    setHovered(false)
  };

  const handleClick = () => {
    setSelectedTemplate(template)
  }

  return (
    <TableRow
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ cursor: 'pointer', filter: `brightness(${hovered ? 0.9 : 1})`, transition: '.2s' }}
    >
      <LightTooltip
        PopperProps={{
          popperOptions: {
            modifiers: {
              flip: { enabled: false },  
            }
          }
        }} 
        open={showTooltip} placement='bottom-start'
        title={template && template.workoutNotes.trim()}>
        <FirstTableCell
          align="center">
          <strong data-test={'desktopWeeklyPlanWorkoutLine-workoutName'}>
            {template.workoutName}
          </strong>
          <span style={{ fontSize: '12px', textAlign:'center', textWrap: 'nowrap', display: 'block' }}>
            {(template.workoutNotes.length > maxNoteCharCount) ? `${template.workoutNotes.substring(0, maxNoteCharCount)}...`: template.workoutNotes}
          </span>
        </FirstTableCell>
      </LightTooltip>

      <StyledTableCell>
        {/* empty because the heading requires this to be empty for some reason */}
      </StyledTableCell>

      <StyledTableCell>
        {getWarmupDuration(template.steps)}
      </StyledTableCell>

      <MainPart workoutSteps={template.steps} CellComponent={StyledTableCell}/>
      
      <FinalTableCell>
        {getCooldownDuration(template.steps)}
      </FinalTableCell>
    </TableRow>
  )
}

export default TemplateTableRow