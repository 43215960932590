
import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
const Graph = props => {
  const {
    samples,
    unit='',
    formatter,
    label,
    color,
    reversed=false,
    fill=false,
    lineWidth=1,
  } = props
  const graphQuality = 3; //the higher the number the lower the quality
  const chart = useRef(null);
  const [lineData, setLineData] = useState();
  const [options, setOptions] = useState();
  const [tooltip, setTooltip] = useState({
    opacity: 0,
    top: 0,
    left: 0,
    date: '',
    value: ''
  });

  useEffect(() => {
    if (!samples) return
    let dataToBeDisplayed = [];
    let labels = [];
    for (let i = 0; i < samples.length; i += graphQuality) {
      dataToBeDisplayed.push(formatter(samples[i]));
      let d = samples[i].clockDurationInSeconds;
      let h = Math.floor(d / 3600);
      let m = Math.floor((d % 3600) / 60);
      let s = Math.floor((d % 3600) % 60);
      let format = '';
      if (s < 10) s = `0${s}`;
      if (h > 0) {
        if (m < 10) m = `0${m}`;
        format = `${h}:${m}:${s}`;
      } else {
        format = `${m}:${s}`;
      }
      
      labels.push(format);
    }
    setOptions({
      scales: {
        y: {
          reverse: reversed
        },
        x: {
          ticks: {
            callback: function(value, index) {
              return index % 50 == 0 ? this.getLabelForValue(value) : null;
            }
          }
        }
      },
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: false,
          external: context => {
            const tooltipModel = context.tooltip;
            if (!chart || !chart.current) return;
            if (tooltipModel.opacity === 0) {
              if (tooltip.opacity !== 0)
                setTooltip(prev => ({ ...prev, opacity: 0 }));
              return;
            }
            const position = context.chart.canvas.getBoundingClientRect();
            const newTooltipData = {
              opacity: 1,
              left: position.left + tooltipModel.caretX-30,
              top: position.top + tooltipModel.caretY + window.scrollY,
              date: tooltipModel.dataPoints[0].label,
              value: tooltipModel.dataPoints[0].formattedValue
            };

            if (tooltip != newTooltipData) setTooltip(newTooltipData);
          }
        }
      }
    });
    setLineData({
      labels: labels,
      datasets: [
        {
          label: label,
          data: dataToBeDisplayed,
          fill: fill?'start':false,
          borderColor: color,
          borderWidth: lineWidth,
          tension: 0.1,
          pointBorderColor: 'black',
          pointHitRadius: 20,
          pointHoverRadius: 4,
          pointRadius: 0,
          backgroundColor: color
        }
      ]
    });
  }, [samples]);

  const hideTooltip = () => setTooltip(prev => ({ ...prev, opacity: 0 }));

  return (
    <div onMouseLeave={hideTooltip} onTouchEndCapture={hideTooltip}>
      <div className="info">
        <div className="color" style={{ background: color }}></div> {label}
      </div>
      <div className="graph-container">
        {lineData ? (
          <Line
            ref={chart}
            data={lineData}
            className="graph"
            options={options}
          />
        ) : (
          ''
        )}
      </div>
      <div
        className="tooltip"
        style={{
          position: 'absolute',
          // top: tooltip.top,
          left: tooltip.left,
          opacity: tooltip.opacity
        }}>
        <p className="tooltip-date">{tooltip.date} </p>
        <p className="tooltip-value">{tooltip.value} {unit}</p>
      </div>
    </div>
  )
}

export default Graph