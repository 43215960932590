import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  Typography
} from '@material-ui/core';
import theme from 'theme';

const useStyles = makeStyles(() => ({
  // root: {
  //   gridColumn: '1 / 3',
  //   [theme.breakpoints.down('sm')]: {
  //     gridColumn: '1 / 2'
  //   },
  // }
}));

// Future Plan to make it possible to subscribe to service again after unsubscribing

const Subscribe = props => {
  const { className, loading } = props;
  const classes = useStyles();

  return (
    <>
      <Card className={clsx(classes.root, className)}>
        <CardHeader subheader="You are not Subscribed to Sub-X" title="Not Subscribed" />
        <Divider />
        <CardContent>

        </CardContent>
        {/* <Divider /> */}
        <CardActions>
          {/* <Button
            color="primary"
            disabled={loading}
            variant="outlined">
          </Button> */}
        </CardActions>
      </Card>
    </>
  );
};


export default Subscribe;
