import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';

const CustomDialog = ({ open, onClose, title, onYesClick }) => {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogActions>
        <Button
          color="primary"
          data-test={'components-customDialog-yes'}
          onClick={onYesClick}
          variant="contained">
          Yes
        </Button>
        <Button
          color="primary"
          data-test={'components-customDialog-no'}
          onClick={onClose}
          variant="outlined">
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
