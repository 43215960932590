import axios from 'axios';
import { initiatedFirebaseApp } from 'config/firebase-config';
import { getAuth } from 'firebase/auth';

const auth = getAuth(initiatedFirebaseApp);

const server = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}`,
});

const formatResponse = (res) => {
  if (!res.data) {
    return res
  }
  const { success, message, severity, hasValue } = res.data;
  if (
    success !== undefined && 
    message !== undefined && 
    severity !== undefined && 
    hasValue !== undefined
  ) {
    res = {
      ...res,
      ...res.data
    }
    return res
  }
}

server.interceptors.response.use((res) => {
  return formatResponse(res)
}, (err) => {
  err.success = false
  err.message = ''
  err.severity = 'error'
  err.hasValue = false
  if (!err.response) {
    return Promise.reject(err);
  }
  
  if (err.response.status === 401) {
    // auth.currentUser.getIdToken().then((token) => {
    //   console.log('refreshing token');
    //   server.defaults.headers.common['Authorization'] = `Bearer ${token}`

    //   server(err.config).then(res => {
    //     return res
    //   })
    // })
    if (err.response.data.message == 'expired') {
      location.reload()
    }
  }


  err.response = formatResponse(err.response)
  return Promise.reject(err);
});

export default server;



