import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  imgContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  img: {
    height: '100%',
    backgroundImage: 'url(/images/auth.png)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    filter: 'brightness(85%)'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  contentHeader: {
    flex: 2,
    minHeight: '220px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  headerText: {
    fontWeight: '900'
  },
  contentBody: {
    flex: 3,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBototm: 100,
    height: '100%',
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }
  },
  formHeader: {
    marginBottom: theme.spacing(5)
  },
  linkWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(1)
  },
  forgotPwdLink: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: '#000',
    }
  },
  formFooter: {
    textAlign: 'center',
    marginBottom: theme.spacing(4)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0),
    textTransform: 'none',
    '&:disabled': {
      color: 'black'
    }
  }
}));

export default useStyles;