import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  Typography,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@material-ui/core';
import server from 'api/server';
import { CustomDialog } from '../../../../components';
import UserContext from 'context/User';
import { displayNotification } from 'api/displayNotification';
import theme from 'theme';

const useStyles = makeStyles(() => ({
  // root: {
  //   gridColumn: '1 / 3',
  //   [theme.breakpoints.down('sm')]: {
  //     gridColumn: '1 / 2'
  //   },
  // }
}));

const Unsubscribe = props => {
  const { className, loading, reload } = props;
  const classes = useStyles();
  const user = React.useContext(UserContext)
  
  const [openCustomDialog, setOpenCustomDialog] = useState(false);


  const onOpenCustomDialog = () => {
    setOpenCustomDialog(true);
  };

  const onCloseCustomDialog = () => {
    setOpenCustomDialog(false);
  };

  const onYesClickCustomDialog = async () => {
    setOpenCustomDialog(false);
    const response = await unsubscribeTrainer();
    displayNotification(response);
    if (response.success) reload();
  };

  const unsubscribeTrainer = () => {
    const response = server.post(
      'payment/unsubscribe',
      {}, { validateStatus: () => true }
    );
    return response;
  };

  return (
    <>
      <Card className={clsx(classes.root, className)}>
        <CardHeader subheader="Unsubscribe from Sub-X" title="Unsubscribe" />
        <Divider />
        <CardContent>
          <Typography variant="body1">
            If you want to, you can unsubscribe from Sub-X here, but we would be
            very sad to see you go.
          </Typography>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            onClick={onOpenCustomDialog}
            disabled={loading}
            variant="outlined">
            Unsubscribe
          </Button>
        </CardActions>
      </Card>
      <Dialog open={openCustomDialog} >
        <DialogTitle>Unsubscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Are you sure you want to Unsubscribe from Sub-X? 
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <Button
            variant='outlined'
            onClick={onYesClickCustomDialog}
          >
          Unsubscribe
          </Button>
          <Button
            color="primary"
            onClick={onCloseCustomDialog}
            variant="contained">
          Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

Unsubscribe.propTypes = {
  className: PropTypes.string
};

export default Unsubscribe;
