import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Input } from '@material-ui/core';
import server from 'api/server';
import UserContext from 'context/User';
import React from 'react';


const InviteGroupModal = props => {
  const { isModalOpen, handleModalClose, setNotificationMessage, setNotificationSeverity } = props;
  const user = React.useContext(UserContext)
  const url = `${location.origin}/sign-up/${user._id}`
  const open = isModalOpen;
  const handleCopy = async () => {
    await navigator.clipboard.writeText(url);
    handleModalClose()
  }
  const handleClose = () => {
    handleModalClose()
  }

  const handleShare = async () => {
    const shareData = {
      title: 'Sub.cx Invitation',
      text: `${user.firstName} has invited you to join!`,
      url: url,
    };
    try {
      await navigator.share(shareData);
    } catch (err) {
      setNotificationSeverity('error');
      setNotificationMessage('failed to share link');
    }
  }

  return (
    <Dialog open={open}><DialogTitle>Group invitation link</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Here's your group invitation link!
          You can copy it and send it to your runners.
          Once they fill out their information, you'll see them added to your runner list.
        </DialogContentText>
        <Input disabled fullWidth value={url}/>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
            Close
        </Button>
        <Button autoFocus color="primary" onClick={handleCopy}>
            Copy
        </Button>
        {navigator.canShare &&
        <Button autoFocus color="primary" onClick={handleShare}>
            Share
        </Button>
        }
      </DialogActions>
    </Dialog>
  );
};

export default InviteGroupModal;
