import React, { useState } from 'react';
import Logo from '../assets/img/logo/sub-x-logo-white.png';
import LogoBlue from '../assets/img/logo/sub-x-logo-blue.png';
import '../assets/css/navbar.css';

const Navbar = () => {
  const [navbar, setNavbar] = useState(false);
  const [mobileNavbarOpen, setMobileNavBarOpen] = useState(false);

  const handleMobileNavbarToggle = () => setMobileNavBarOpen(!mobileNavbarOpen);

  const getSignInStyle = () => {
    // if (window.innerWidth < 767)
    if (window.innerWidth < 992)
      return {
        color: '#5157F8',
        padding: '10px 18px 4px',
        fontFamily: 'Arimo',
        marginBottom: 0,
        marginTop: '3px',
        marginLeft: '7px'
      };

    if (navbar) {
      return {
        color: '#5157F8',
        borderColor: '#5157F8',
        padding: '10px 18px 4px',
        fontFamily: 'Arimo'
      };
    }
    return {
      color: 'white',
      borderColor: 'white',
      padding: '10px 18px 4px',
      fontFamily: 'Arimo'
    };
  };

  const changeBackground = () => {
    if (window.scrollY >= 20) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  // window.addEventListener('scroll', changeBackground);

  React.useEffect(() => {
    window.removeEventListener('scroll', changeBackground);
    window.addEventListener('scroll', changeBackground, { passive: true });
    return () => window.removeEventListener('scroll', changeBackground);
  }, [window.scrollY]);

  return (
    <div>
      <header className="header">
        <div className={navbar ? 'navbar-area active' : 'navbar-area'}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg">
                  {navbar ? (
                    <a className="navbar-brand" href="/">
                      <img src={LogoBlue} alt="Logo" />
                    </a>
                  ) : (
                    <a className="navbar-brand" href="/">
                      <img src={Logo} alt="Logo" />
                    </a>
                  )}
                  <button
                    className={
                      mobileNavbarOpen
                        ? 'navbar-toggler active'
                        : 'navbar-toggler'
                    }
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="true"
                    aria-label="Toggle navigation"
                    onClick={handleMobileNavbarToggle}>
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                  </button>
                  <div
                    className={
                      mobileNavbarOpen
                        ? 'collapse navbar-collapse sub-menu-bar show'
                        : 'collapse navbar-collapse sub-menu-bar'
                    }
                    id="navbarSupportedContent">
                    <ul id="nav" className="navbar-nav ms-auto">
                      <li className="nav-item">
                        <a
                          className="page-scroll active"
                          id={navbar ? 'linkblack' : 'linkwhite'}
                          href="/">
                          Home
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="page-scroll"
                          id={navbar ? 'linkblack' : 'linkwhite'}
                          href="#benefits">
                          Benefits
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="page-scroll"
                          id={navbar ? 'linkblack' : 'linkwhite'}
                          href="#about">
                          About
                        </a>
                      </li>
                      {/* <li className="nav-item">
                        <a
                          className="page-scroll"
                          id={navbar ? 'linkblack' : 'linkwhite'}
                          href="#why">
                          Why
                        </a>
                      </li> */}
                      <li className="nav-item">
                        <a
                          className="page-scroll"
                          id={navbar ? 'linkblack' : 'linkwhite'}
                          href="#pricing">
                          Pricing
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="page-scroll"
                          id={navbar ? 'linkblack' : 'linkwhite'}
                          href="#testimonials">
                          Testimonials
                        </a>
                      </li>
                      <li className="nav-item">
                        <p
                          className="main-btn border-btn btn-hover wow fadeInUp"
                          data-wow-delay={'1s'}
                          onClick={() => {
                            window.location.replace('/sign-in');
                          }}
                          style={getSignInStyle()}>
                          Sign-in
                        </p>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
