import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Elevation from './components/graphs/Elevation';
import Heartbeat from './components/graphs/Heartbeat';
import Intervals from './components/graphs/Intervals';
import Pace from './components/graphs/Pace';
import Header from './components/Header';
import MapComponent from './components/MapComponent';
import Summary from './components/Summary';
import Chart from 'chart.js/auto'; // dont remove this
import './css/app.css';
import './css/graphs.css';

const useStyles = makeStyles(theme => ({
  workoutAnalysis: {
    padding: '2%',
    borderRadius: '5px',
    boxShadow: 'inset 0px 0px 0px 3px #3f51b5'
  }
}));


function WorkoutAnalysis({ handleModalClose, workoutAnalysisData }) {
  const classes = useStyles();
  const [samples, setSamples] = useState([]);
  const [laps, setLaps] = useState([]);
  const [type, setType] = useState('');
  useEffect(() => {
    if (
      workoutAnalysisData 
    ) setType(workoutAnalysisData.summary.activityType)
    if (
      workoutAnalysisData &&
      workoutAnalysisData.samples &&
      workoutAnalysisData.laps
    ) {
      const validSamples = workoutAnalysisData.samples.filter(
        sample =>
          sample.startTimeInSeconds &&
          sample.timerDurationInSeconds &&
          sample.clockDurationInSeconds
      );
      setSamples(validSamples);

      const validLaps = workoutAnalysisData.laps.filter((lap, index) => {
        const endTimeInSeconds =
          index < workoutAnalysisData.laps.length - 1
            ? workoutAnalysisData.laps[index + 1].startTimeInSeconds
            : Infinity;

        const lapSamples = validSamples.filter(
          sample =>
            sample.startTimeInSeconds >= lap.startTimeInSeconds &&
            sample.startTimeInSeconds < endTimeInSeconds
        );

        return lapSamples.length >= 3;
      });

      setLaps(validLaps);
    }
  }, [workoutAnalysisData]);

  if (!workoutAnalysisData) {
    return null;
  }

  return (
    <div className={classes.workoutAnalysis}>
      <Header
        handleModalClose={handleModalClose}
        runnerName={workoutAnalysisData.runnerName}
        workoutSummary={workoutAnalysisData.summary}
      />
      <Summary workoutSummary={workoutAnalysisData.summary} />
      {(type=='RUNNING') && <MapComponent samples={samples} />}
      {(type=='RUNNING') && <Elevation samples={samples} />}
      <Pace samples={samples} />
      <Heartbeat samples={samples} />
      <Intervals type={type} laps={laps} samples={samples} /> 
    </div>
  );
}

WorkoutAnalysis.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  workoutAnalysisData: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        runnerName: PropTypes.string.isRequired,
        summary: PropTypes.object.isRequired,
        samples: PropTypes.array.isRequired,
        laps: PropTypes.array.isRequired
      })
    ),
    PropTypes.shape({
      runnerName: PropTypes.string.isRequired,
      summary: PropTypes.object.isRequired,
      samples: PropTypes.array.isRequired,
      laps: PropTypes.array.isRequired
    })
  ]).isRequired
};

export default WorkoutAnalysis;
