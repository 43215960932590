import React from 'react';
import RunnersTable from './components/RunnersTable';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  Box,
  Button,
  IconButton,
  Paper,
  Typography
} from '@material-ui/core';
import { AddCircleOutline, Link } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    // margin: theme.spacing(2),
    height: '100%',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2)
    }
  },
  paper: {
    brderRadius: '4px',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr'
  },
  header: {
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
      paddingBottom: theme.spacing(5)
    },
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    position: 'relative'
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2)
    }
  },
  addRunnerIconWrapper: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(4),
    fontSize: '2rem'
  },
  inviteRunnerIconWrapper: {
    position: 'absolute',
    right: theme.spacing(8),
    top: theme.spacing(4),
    fontSize: '2rem'
  },
  addRunnerIcon: {
    fontSize: '2rem'
  },
  addRunnerBtn: {
    textTransform: 'none'
  }
}));

const RunnersList = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const [inviteGroupLinkTrigger, setInviteGroupLinkTrigger ] = React.useState(false);
  const [inviteRunnerTrigger, setInviteRunnerTrigger] = React.useState(false);

  const handleAddRunner = () => {
    setInviteRunnerTrigger(true);
  };
  
  const handleInviteRunner = () => {
    setInviteGroupLinkTrigger (true);
  };
  

  return (
    <Box className={classes.root}>
      <Paper className={classes.paper}>
        <Box className={classes.header}>
          <Typography className={classes.title} noWrap variant="h3">
            Runners List
          </Typography>
          
          {isMobile ? (
            <>
            <IconButton
              className={classes.addRunnerIconWrapper}
              onClick={handleAddRunner}>
              <AddCircleOutline className={classes.addRunnerIcon} />
            </IconButton>
            <IconButton
              className={classes.inviteRunnerIconWrapper}
              onClick={handleInviteRunner}>
              <Link className={classes.addRunnerIcon} />
            </IconButton>
          </>
          ) : (<Box style={{ gap: '8px', display: 'flex' }}>
            <Button
              className={classes.addRunnerBtn}
              color="primary"
              variant="contained"
              onClick={handleInviteRunner}>
                <Link/> Group Invite
            </Button>
            <Button
              className={classes.addRunnerBtn}
              color="primary"
              variant="contained"
              onClick={handleAddRunner}>
                + Add Runner
            </Button>
          </Box>
          )}
        </Box>
        
        <RunnersTable
          inviteRunnerTrigger={inviteRunnerTrigger}
          setInviteRunnerTrigger={setInviteRunnerTrigger}
          inviteGroupLinkTrigger={inviteGroupLinkTrigger}
          setInviteGroupLinkTrigger={setInviteGroupLinkTrigger }
        />
      </Paper>
    </Box>
  );
};

export default RunnersList;
