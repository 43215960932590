import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  Card
} from '@material-ui/core';
import UserContext from 'context/User';
// import { updatePassword } from 'firebase/auth';


const Password = props => {
  const user = React.useContext(UserContext)


  const [values, setValues] = useState({
    username: user?.email || '',
    password: '',
    confirm: ''
  });

  const handleSubmit = event => {
    event.preventDefault();
    // updatePassword(values.password);
    console.log(values);
  };

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Card>
      <form onSubmit={handleSubmit}>
        <CardHeader subheader="Update password" title="Password" />
        <Divider />
        <CardContent>
          <TextField
            autoComplete="username"
            fullWidth
            label="Username"
            name="username"
            onChange={handleChange}
            style={{ display: 'none' }} // Hidden field
            type="text"
            value={values.username}
            variant="outlined"
          />
          <TextField
            autoComplete="new-password"
            fullWidth
            label="Password"
            name="password"
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            autoComplete="new-password"
            fullWidth
            label="Confirm password"
            name="confirm"
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            type="password"
            value={values.confirm}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Button color="primary" type="submit" variant="outlined">
            Update
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string,
};

export default Password;
