import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Paper } from '@material-ui/core';

import { Password, Unsubscribe } from './components';
import UserContext from 'context/User';
import DeleteAccount from './components/DeleteAccount';
import server from 'api/server';
import CardSkeleton from './components/CardSkeleton';
import Subscribe from './components/Subscribe';
import StartMonday from './components/StartDay';
// import { Notifications } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    display: 'grid',
    gridTemplateRows: '',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
      },
    },
    '& .MuiCard-root': {
      display: 'flex',
      flexDirection: 'column',
      '& .MuiCardContent-root': {
        flexGrow: 1
      }
    }
  }
}));

const Settings = () => {
  const user = React.useContext(UserContext)
  const classes = useStyles();
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [loading, setLoading] = useState(true);

  const getSubscriptionStatus = async () => {
    const response = await server.get('/payment/status', {
      validateStatus: () => true
    })
    if (response.status === 200) {
      setSubscriptionStatus(response.message);
    }
  }

  const reload = async () => {
    setLoading(true);
    await getSubscriptionStatus();
    setLoading(false);
  }

  useEffect(() => {
    reload();
  }, [])

  return (
    <div className={classes.root}>
      <Password />
      {user.role === 'trainer' && (
        loading ? 
          <CardSkeleton/> :
          (subscriptionStatus === 'ACTIVE' ? 
            <Unsubscribe loading={loading} reload={reload}/> : 
            <Subscribe loading={loading} />
          )
      )}
      <DeleteAccount loading={loading} subscriptionStatus={subscriptionStatus} />
      <StartMonday />
      {/*
        <Notifications />
      */}
    </div>
  );
};


export default Settings;
