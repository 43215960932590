import React, { useState } from 'react';
import { SupportRequestModal } from 'components';

const PricingSection = () => {
  const [isMonth, setIsMonth] = useState(true);

  const handleMonthlyClick = () => setIsMonth(true);
  const handleYearlyClick = () => setIsMonth(false);

  const [isSupportRequestModalOpen, setIsSupportRequestModalOpen] = useState(
    false
  );
  const handleSupportRequestModalOpen = () => {
    setIsSupportRequestModalOpen(true);
  };

  const handleSupportRequestModalClose = () => {
    setIsSupportRequestModalOpen(false);
  };

  return (
    <section id="pricing" className="pricing-section pt-30 pb-30">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-9">
            <div className="section-title text-center mb-30">
              <h2 className="wow fadeInUp" data-wow-delay=".2s">
                Start Today!
              </h2>
              <div style={{ textAlign: 'left' }}>
                <h4>
                  Enjoy your <b>FREE first month</b> to explore all our features
                  and see how Sub-X can supercharge your training plans. <br />
                  <b>No credit card needed.</b>
                  <br />
                  <div style={{ textAlign: 'center' }}>
                    Start today and experience the difference!
                  </div>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="pricing-nav-wrapper mb-20">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li role="presentation">
              <a
                onClick={handleMonthlyClick}
                className={isMonth ? 'active' : ''}
                style={{ cursor: 'pointer' }}
                id="pills-month-tab"
                data-bs-toggle="pill"
                // href="#pills-month"
                role="tab"
                aria-controls="pills-month"
                aria-selected="true">
                Monthly
              </a>
            </li>
            <li role="presentation">
              <a
                className={isMonth ? '' : 'active'}
                onClick={handleYearlyClick}
                id="pills-year-tab"
                data-bs-toggle="pill"
                // href="#pills-year"
                role="tab"
                style={{ cursor: 'pointer' }}
                aria-controls="pills-year"
                aria-selected="false">
                Yearly
              </a>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-month"
            role="tabpanel"
            aria-labelledby="pills-month-tab">
            <div className="row justify-content-center">
              {/* <div className="col-lg-4 col-md-8 col-sm-10">
                <div className="single-pricing">
                  <div className="pricing-header">
                    {isMonth ? (
                      <h1 className="price">
                        <s>$9.99</s>
                      </h1>
                    ) : (
                      <h1 className="price">
                        <s>
                          <s style={{ color: 'lightGrey' }}>$120</s> $100
                        </s>
                      </h1>
                    )}
                    <h3 className="package-name">Basic Account</h3>
                  </div>
                  <div className="content">
                    <ul>
                      <li>
                        <i className="lni lni-checkmark active"></i>
                        All Capabilities & Features
                      </li>
                      <li>
                        <i className="lni lni-checkmark active"></i>
                        Up To 20 trainees
                      </li>
                      <li>
                        <i className="lni lni-checkmark active"></i>
                        Single Trainer Per Group
                      </li>
                      <li>
                        <i className="lni lni-checkmark active"></i>
                        Your Own Workouts
                      </li>
                      <li>
                        <i className="lni lni-checkmark active"></i>
                        Community Support
                      </li>
                    </ul>
                  </div>
                  <div className="pricing-btn">
                    <a
                      onClick={() => (window.location.href = '/sign-up')}
                      // href="/sign-up"
                      className="main-btn btn-hover border-btn">
                      Get Started
                    </a>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-4 col-md-8 col-sm-10">
                <div className="single-pricing">
                  <div className="pricing-header">
                    {isMonth ? (
                      <h1 className="price">
                        <s style={{ color: 'lightGrey' }}>$39.99</s>&nbsp;$19.99
                      </h1>
                    ) : (
                      <h1 className="price">
                        <s style={{ color: 'lightGrey' }}>$480</s>&nbsp;$239
                      </h1>
                    )}
                    <h3 className="package-name">Business Account</h3>
                  </div>
                  <div className="content">
                    <ul>
                      <li>
                        <i className="lni lni-checkmark active"></i>
                        All Features & Capabilities
                      </li>
                      <li>
                        <i className="lni lni-checkmark active"></i>
                        Unlimited Trainees
                      </li>
                      <li>
                        <i className="lni lni-checkmark active"></i>
                        No Extra Costs
                      </li>
                      {/* <li>
                        <i className="lni lni-checkmark active"></i>
                        +100 Ready Workouts
                      </li> */}
                      <li>
                        <i className="lni lni-checkmark active"></i>
                        Email Support
                      </li>
                    </ul>
                  </div>
                  <div className="pricing-btn">
                    <a
                      onClick={() => (window.location.href = '/sign-up')}
                      // href="/sign-up"
                      className="main-btn btn-hover">
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-8 col-sm-10">
                <div className="single-pricing">
                  <div className="pricing-header">
                    <h1 className="price">$</h1>
                    <h3 className="package-name">Enterprise Account</h3>
                  </div>
                  <div className="content">
                    <ul>
                      <li>
                        <i className="lni lni-question-circle active"></i>
                        Need something else?
                      </li>
                      <li>
                        <i className="lni lni-information active"></i>
                        We are right here
                      </li>
                      <li>
                        <i className="lni lni-thumbs-up active"></i>
                        Cotact us and let's discuss:
                      </li>
                      <li>
                        <i className="lni lni-envelope active"></i>
                        info@sub.cx
                      </li>
                      {/* <li>
                        <i className="lni lni-whatsapp active"></i>
                        +972-54-8312543
                      </li> */}
                    </ul>
                  </div>
                  <div className="pricing-btn">
                    <a
                      onClick={handleSupportRequestModalOpen}
                      className="main-btn btn-hover border-btn">
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SupportRequestModal
        key={Date.now().toString()}
        handleSupportRequestModalClose={handleSupportRequestModalClose}
        isSupportRequestModalOpen={isSupportRequestModalOpen}
      />
    </section>
  );
};

export default PricingSection;
