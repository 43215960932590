import React, { useEffect, useState } from 'react';
import server from 'api/server';
import {
  AccordionDetails,
  AccordionSummary,
  Card,
  MenuItem,
  MenuList,
  Typography,
  withStyles
} from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';

import { ExpandMoreOutlined } from '@material-ui/icons';
import { Draggable } from '@fullcalendar/interaction';

const Accordion = withStyles({
  root: {
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiAccordion);

const DraggableCard = withStyles({
  root: {
    cursor: 'grab',
    padding: '10px',
    margin: '10px 0',
    background: '#3788d8',
    color: 'white'
  }
})(Card);

const WorkoutTemplateSelection = ({
  subgroups,
  selectedSubgroup,
  handleWorkoutTemplateDisplay,
  monthView,
  forceUpdateTrigger
}) => {
  const [workoutTemplates, setWorkoutTemplates] = useState({});
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const [lastExpandedIndex, setLastExpandedIndex] = useState(null);
  const [draggables, setDraggables] = useState([]);

  const fetchWorkoutTemplates = async () => {
    const response = await server.get(
      'workout/templatesFromDB'
    );
    return response.value;
  };

  const toggleIndex = index => {
    const currentExpandedIndexes = [...expandedIndexes];
    if (currentExpandedIndexes.includes(index)) {
      const newExpandedIndexes = currentExpandedIndexes.filter(
        expandedIndex => expandedIndex != index
      );
      setExpandedIndexes(newExpandedIndexes);
      return newExpandedIndexes;
    } else {
      const newExpandedIndexes = [...currentExpandedIndexes, index];
      setExpandedIndexes(newExpandedIndexes);
      return newExpandedIndexes;
    }
  };

  // const addIndex = (index) => {
  //   const currentExpandedIndexes = [...expandedIndexes];
  //   if (!currentExpandedIndexes.includes(index)) {
  //     const newExpandedIndexes = [...currentExpandedIndexes, index]
  //     setExpandedIndexes(newExpandedIndexes);
  //     return newExpandedIndexes
  //   }
  // };

  // const removeIndex = (index) => {
  //   const currentExpandedIndexes = [...expandedIndexes];
  //   if (currentExpandedIndexes.includes(index)) {
  //     const newExpandedIndexes = currentExpandedIndexes.filter((expandedIndex) => expandedIndex != index);
  //     setExpandedIndexes(newExpandedIndexes);
  //     return newExpandedIndexes
  //   }
  // };

  const handleChange = index => {
    toggleIndex(index);
    setLastExpandedIndex(null);
  };

  const init = async () => {
    // Inefficient way of doing this but very accurate and work break
    const formattedObject = {};
    for (const subgroup of subgroups) {
      formattedObject[subgroup] = [];
    }
    const templates = await fetchWorkoutTemplates();
    templates.forEach(template => {
      formattedObject[template.subGroup] = [];
    });
    templates.forEach(template => {
      formattedObject[template.subGroup] = [
        ...formattedObject[template.subGroup],
        template
      ];
    });
    setWorkoutTemplates(formattedObject);
  };

  useEffect(() => {
    init();
  }, [subgroups, forceUpdateTrigger]);

  useEffect(() => {
    if (monthView) {
      draggables.forEach(draggable => {
        draggable.destroy();
      });

      const draggablesArray = [];
      document.querySelectorAll('.draggable-card').forEach(card => {
        const template =
          workoutTemplates[card.getAttribute('data-subgroup')][
            card.getAttribute('data-index')
          ];
        let draggable = new Draggable(card, {
          eventData: {
            title: template.workoutName,
            extendedProps: { template: template }
          }
        });
        draggablesArray.push(draggable);
      });
      setDraggables(draggablesArray);
    }
  }, [workoutTemplates]);

  useEffect(() => {
    if (selectedSubgroup) {
      let newExpandedIndexes = expandedIndexes;
      if (
        lastExpandedIndex !== null &&
        lastExpandedIndex !== subgroups.indexOf(selectedSubgroup)
      ) {
        newExpandedIndexes = newExpandedIndexes.filter(
          expandedIndex => expandedIndex != lastExpandedIndex
        );
      }
      setLastExpandedIndex(subgroups.indexOf(selectedSubgroup));
      if (!newExpandedIndexes.includes(subgroups.indexOf(selectedSubgroup))) {
        newExpandedIndexes = [
          ...newExpandedIndexes,
          subgroups.indexOf(selectedSubgroup)
        ];
      }
      setExpandedIndexes(newExpandedIndexes);
    }
  }, [selectedSubgroup]);

  return (
    <div data-test={'WorkoutTemplateSelection-componentDiv'}>
      {Object.keys(workoutTemplates).map((subgroup, subgroupIndex) => (
        <Accordion
          data-test={`WorkoutTemplateSelection-accordion-${subgroup}`}
          expanded={expandedIndexes.includes(subgroupIndex)}
          key={subgroupIndex}
          onChange={() => handleChange(subgroupIndex)}
          square={false}>
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <Typography variant="h5">{subgroup}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MenuList style={{ width: '100%' }}>
              {workoutTemplates[subgroup].length > 0
                ? workoutTemplates[subgroup].map((template, index) =>
                  monthView ? (
                    <DraggableCard
                      className="draggable-card"
                      data-index={index}
                      data-test={'WorkoutTemplateSelection-draggableCard'}
                      data-subgroup={subgroup}
                      key={index}>
                      {template.workoutName}
                    </DraggableCard>
                  ) : (
                    <MenuItem
                      data-test={`WorkoutTemplateSelection-menuItem-${template.workoutName}`}
                      key={index}
                      onClick={
                        () =>
                          handleWorkoutTemplateDisplay({
                            target: { value: template } // this is to make it work
                          }) // will be changed later (if i remember to)
                      }
                      style={{ width: '100%' }}>
                      {template.workoutName}
                    </MenuItem>
                  )
                )
                : 'This subgroup is empty'}
            </MenuList>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default WorkoutTemplateSelection;
