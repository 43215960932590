import React from 'react';

const BenefitsSection = () => {
  return (
    <section id="benefits" className="feature-section pt-30">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-8 col-sm-10">
            <div className="single-feature">
              <div className="icon">
                <i className="lni lni-grow"></i>
              </div>
              <div className="content">
                <h3>Individual Pace Setting</h3>
                <p>
                  Save time and effort with automatic calculations. Ensure
                  optimal training for every individual trainee.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-8 col-sm-10">
            <div className="single-feature">
              <div className="icon">
                <i className="lni lni-dashboard"></i>
              </div>
              <div className="content">
                <h3>Performance Monitoring</h3>
                <p>
                  Get all your trainees actual workout performance in a single
                  screen. No need to swipe left and right.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-8 col-sm-10">
            <div className="single-feature">
              <div className="icon">
                <i className="lni lni-coffee-cup"></i>
              </div>
              <div className="content">
                <h3>Simple and Easy to Use</h3>
                <p>
                  Easily set personalized paces for each trainee based on their
                  abilities and goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitsSection;
