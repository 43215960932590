const validateWorkout = workout => {
  const response = {
    isValidWorkout: false,
    notificationSeverity: 'error',
    notificationMessage: ''
  };

  if (!workout.workoutName) {
    return { ...response, notificationMessage: 'Please enter a workout name' };
  }

  if (workout.steps.length === 0) {
    return {
      ...response,
      notificationMessage: 'Please add at least one step to the workout'
    };
  }
  let warmupsPassed = false
  let cooldownsPassed = false
  for (let i = 0; i < workout.steps.length; i++) {
    const step = workout.steps[i];
    if (step.intensity == 'WARMUP' && warmupsPassed) {
      
      return {
        ...response,
        notificationMessage:
          'Please make sure that warmup steps are the first steps in the workout'
      };
    }
    if (step.intensity == 'COOLDOWN') {
      cooldownsPassed = true
    }
    if (step.intensity != 'WARMUP') {
      if (!warmupsPassed) {
        warmupsPassed = true
      }
    }
    if (step.intensity != 'COOLDOWN') {
      if (cooldownsPassed) {
        return {
          ...response,
          notificationMessage:
            'Please make sure that cooldown steps are the last steps in the workout'
        };
      }
    }
  }

  for (let i = 0; i < workout.steps.length; i++) {
    const step = workout.steps[i];
    if (step.type === 'WorkoutStep') {
      if (!step.intensity) {
        return {
          ...response,
          notificationMessage: 'Please fill in the step intensity at step number '+(i+1)
        };
      }
      if (!step.durationType) {
        return {
          ...response,
          notificationMessage: 'Please fill in the step duration type at step number '+(i+1)
        };
      }

      if (step.durationType === 'TIME') {
        if (!step.durationValueType) {
          return {
            ...response,
            notificationMessage: 'Please fill in the step units at step number '+(i+1)
          };
        }
        if (!step.durationValue) {
          return {
            ...response,
            notificationMessage: 'Please fill in the step duration at step number '+(i+1)
          };
        }
      }

      if (step.durationType === 'DISTANCE') {
        if (!step.durationValueType) {
          return {
            ...response,
            notificationMessage: 'Please fill in the step units at step number '+(i+1)
          };
        }
        if (!step.durationValue) {
          return {
            ...response,
            notificationMessage: 'Please fill in the step duration at step number '+(i+1)
          };
        }
      }
    }

    if (step.type === 'WorkoutRepeatStep') {
      if (!step.repeatValue) {
        return {
          ...response,
          notificationMessage: 'Please fill in the number of interval repeats at step number '+(i+1)
        };
      }

      for (let j = 0; j < step.steps.length; j++) {
        const repeatedStep = step.steps[j];
        if (!repeatedStep.intensity) {
          return {
            ...response,
            notificationMessage: 'Please fill in the step intensity at step number '+(i+1)
          };
        }

        if (!repeatedStep.durationType) {
          return {
            ...response,
            notificationMessage: 'Please fill in the step duration type at step number '+(i+1)
          };
        }

        if (repeatedStep.durationType === 'TIME') {
          if (!repeatedStep.durationValueType) {
            return {
              ...response,
              notificationMessage: 'Please fill in the step units at step number '+(i+1)
            };
          }
          if (!repeatedStep.durationValue) {
            return {
              ...response,
              notificationMessage: 'Please fill in the step duration at step number '+(i+1)
            };
          }
        }

        if (repeatedStep.durationType === 'DISTANCE') {
          if (!repeatedStep.durationValueType) {
            return {
              ...response,
              notificationMessage: 'Please fill in the step units at step number '+(i+1)
            };
          }
          if (!repeatedStep.durationValue) {
            return {
              ...response,
              notificationMessage: 'Please fill in the step duration at step number '+(i+1)
            };
          }
        }
      }
    }
  }
  return { ...response, isValidWorkout: true };
};

export default validateWorkout;
