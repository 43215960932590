import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import DesktopWeeklyPlan from './DesktopWeeklyPlan';
import MobileWeeklyPlan from './MobileView/MobileWeeklyPlan';
import server from 'api/server';
import UserContext from 'context/User';
import { displayNotification } from 'api/displayNotification';

const useStyles = makeStyles(theme => ({
  accordion: {
    backgroundColor: '#d9d9d9',
    marginBottom: 10,
    marginTop: 10
  },
  accordionSummary: {
    backgroundColor: '#d9d9d9',
    color: '#000000',
    boxShadow: 'none',
    textTransform: 'none',
    padding: 5,
    paddingLeft: 40,
    paddingRight: 20,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,

    [theme.breakpoints.down('xs')]: {
      backgroundColor: '#ffffff',
      border: '1px solid #404040',
      paddingLeft: 17,
      paddingRight: 17
    }
  },
  accordionDetails: {
    backgroundColor: '#efefef',
    width: '100%',
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      backgroundColor: '#e0e0e0',
      display: 'block'
    }
  },
  accordionSummaryText: {
    fontWeight: 500
  },
  smOnly: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  mdPlusOnly: {
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
}));

export default function RunnerWeeklyPlan(props) {
  const { runnerWeeklyWorkoutPlan, updateWeekView, type } = props;
  const user = React.useContext(UserContext);

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(
    user.role == 'runner' ? 'panel1' : ''
  );
  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleWatchSyncIconClick = async workout => {
    const response = await server.post('workout/garminSync', workout);

    displayNotification(response);
  };

  return (
    <div>
      <Accordion
        data-test={`runnerWeeklyPlan-accordion-${runnerWeeklyWorkoutPlan.firstName}${runnerWeeklyWorkoutPlan.lastName}`}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlinedIcon style={{ color: '#000000' }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummary}>
          <Typography variant="h5" className={classes.accordionSummaryText}>
            {`${runnerWeeklyWorkoutPlan.firstName} ${runnerWeeklyWorkoutPlan.lastName}`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div className={classes.mdPlusOnly}>
            <DesktopWeeklyPlan
              handleWatchSyncIconClick={handleWatchSyncIconClick}
              updateWeekView={updateWeekView}
              runnerWeeklyWorkoutPlan={runnerWeeklyWorkoutPlan}
              type={type}
            />
          </div>
          <div className={classes.smOnly}>
            <MobileWeeklyPlan
              updateWeekView={updateWeekView}
              handleWatchSyncIconClick={handleWatchSyncIconClick}
              runnerWeeklyWorkoutPlan={runnerWeeklyWorkoutPlan}
              type={type}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
