import React, { useEffect } from 'react';
import useStyles from '../../styles';
import PropTypes from 'prop-types';

import { Button, Typography, Box } from '@material-ui/core';

const SelectRole = props => {
  const { setFormState, formState, runner, isInvitedByGroupLink } = props;
  const classes = useStyles();

  React.useEffect(() => {
    setFormState({
      ...formState,
      values: { ...formState.values, role: runner.id ? 'runner' : 'trainer' }
    });
  }, []);

  const handleSelectRole = value => {
    setFormState({
      ...formState,
      values: { ...formState.values, role: value }
    });
  };

  return (
    <Box className={(classes.options, classes.optionsTitle)}>
      <Typography className={classes.content} variant="h4">
        Who are you?
      </Typography>
      <Box className={classes.buttonsWrapper}>
        <Button
          className={`${
            formState.values?.role === 'trainer'
              ? classes.selectedBtn
              : classes.optionButton
          }`}
          data-test={'SignUp-components-SelectRole-trainerButton'}
          disabled={!!runner._id || isInvitedByGroupLink}
          name="optionSelected"
          onClick={() => handleSelectRole('trainer')}
          variant="contained">
          I'm a Trainer
        </Button>
        <Button
          className={`${
            formState.values?.role === 'runner'
              ? classes.selectedBtn
              : classes.optionButton
          }`}
          data-test={'SignUp-components-SelectRole-runnerButton'}
          disabled={!runner._id && !isInvitedByGroupLink}
          name="optionSelected"
          onClick={() => handleSelectRole('runner')}
          variant="contained">
          I'm a Runner
        </Button>
      </Box>
    </Box>
  );
};

export default SelectRole;

SelectRole.propTypes = {
  formState: PropTypes.object,
  setFormState: PropTypes.func
};
