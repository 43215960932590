import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Paper, Tab, Tabs } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import CreateWorkout from './components/CreateWorkout';
import MonthView from './components/MonthView/MonthView';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2)
  }
}));

const CreateWorkoutPlan = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [workoutSelectedByMonthView, setWorkoutSelectedByMonthView] = useState()
  const [selectedSubgroup, setSelectedSubgroup] = useState('General');
  const [selectedDateByWeekview, setSelectedDateByWeekview] = useState();
  const [updateTrigger, setUpdateTrigger] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setUpdateTrigger(!updateTrigger);
  };
  return (<>
    <Paper square>
      <Tabs
        indicatorColor="primary"
        onChange={handleChange}
        textColor="primary"
        value={value}>
        <Tab label="Weekly View" />
        <Tab label="Monthly View" />
      </Tabs>
    </Paper>
    <div style={{ display: value===0 ? 'block' : 'none' }} data-test="weekView">
      <Paper className={classes.root} square>
        <CssBaseline />
        <CreateWorkout
          updateTrigger={updateTrigger}
          selectedSubgroup={selectedSubgroup} 
          setSelectedDateByWeekview={setSelectedDateByWeekview}
          setSelectedSubgroup={setSelectedSubgroup}
          workoutSelectedByMonthView={workoutSelectedByMonthView}
        />
      </Paper>
    </div>
    <div style={{ display: value===1 ? 'block' : 'none' }} data-test="monthView">
      <Grid container justifyContent="center" >
        <MonthView 
          updateTrigger={updateTrigger}
          selectedDateByWeekview={selectedDateByWeekview} 
          selectedSubgroup={selectedSubgroup} 
          setSelectedSubgroup={setSelectedSubgroup}
          setValue={setValue}
          setWorkoutSelectedByMonthView={setWorkoutSelectedByMonthView}
        />
      </Grid>
    </div>
    </>
  );
};

export default CreateWorkoutPlan;
