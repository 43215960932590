const schema = {
  runningGroup: {
    // presence: { allowEmpty: true, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  firstName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  lastName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  mobile: {
    presence: { allowEmpty: false, message: 'is required' },
    // format: {
    //   pattern: /^[+]{1}(?:[0-9\-\(\)\/\.]\s?){6, 15}[0-9]{1}$/,
    //   message: 'number in international format is required (+972...)'
    // },
    length: {
      maximum: 25
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
      minimum: 6
    }
  }
};

export default schema;
