import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Grid } from '@material-ui/core';
import server from 'api/server';

const useStyles = makeStyles(theme => {
  return {
    menu: {
      borderRadius: '4px'
    },
    menuText: {
      fontWeight: 'bold'
    }
  };
});

function MobileWorkoutTemplateSelection(props) {
  const { handleWorkoutTemplateDisplay, selectedSubgroup } = props;
  const classes = useStyles();
  const [workoutTemplates, setWorkoutTemplates] = useState([]);

  const fetchWorkoutTemplates = async () => {
    const response = await server.get(
      'workout/templatesFromDB'
    );
    return response.value;
  };

  const filterWorkoutTemplates = (templates, subgroup) => {
    return templates.filter(obj => obj.subGroup === subgroup);
  };

  useEffect(() => {
    let isMounted = true
    const fetchData = async () => {
      const templatesFromDB = await fetchWorkoutTemplates();
      const filteredTemplates = filterWorkoutTemplates(
        templatesFromDB,
        selectedSubgroup
      );
      if (isMounted) setWorkoutTemplates(filteredTemplates);
    };

    fetchData();
    return () => {
      isMounted = false
    };
  }, [selectedSubgroup]);
  return (
    <FormControl
      variant="outlined"
      disabled={!workoutTemplates.length}
      className={classes.menu}
      fullWidth>
      <InputLabel
        id="select-workout-template"
        margin="dense"
        className={classes.menuText}>
        {workoutTemplates.length
          ? 'Select from existing templates'
          : 'No templates. Please create some'}
      </InputLabel>
      <Select
        data-test={'workoutTemplateSelection-select'}
        labelId="workoutTemplateSelection-select"
        id="workoutTemplateSelection-select"
        // value={workout}
        value={''}
        label="Select from existing workout template"
        margin="dense"
        onChange={handleWorkoutTemplateDisplay}>
        {workoutTemplates.length ? (
          workoutTemplates.map(workoutTemplate => (
            <MenuItem
              data-test={'workoutTemplateSelection-menuItem'}
              key={workoutTemplate._id}
              value={workoutTemplate}>
              {workoutTemplate.workoutName}
            </MenuItem>
          ))
        ) : (
          <MenuItem value={0}>No available templates</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

export default MobileWorkoutTemplateSelection;
