import React from 'react';
import Graph from './Graph';

const Elevation = ({ samples }) => {
  const fortmatter = (sample)=>{
    return sample.elevationInMeters
  }
  return (
    <Graph
      color={'#65b44a'}
      samples={samples}
      fill
      formatter={fortmatter}
      label={'Elevation'}
      unit={'M'}
      />
    );
};

export default Elevation;
