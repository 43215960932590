import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';
import RepeatIcon from '@material-ui/icons/Repeat';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import TrendingDownOutlinedIcon from '@material-ui/icons/TrendingDownOutlined';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import server from 'api/server';

import RepeatStep from '../../../CreateWorkoutPlan/components/RepeatStep';
import WorkoutStepTemplate from '../../../CreateWorkoutPlan/components/WorkoutStepTemplate';
import getWarmupStepTemplate from '../../../CreateWorkoutPlan/components/Templates/warmupStepTemplate';
import getCooldownStepTemplate from '../../../CreateWorkoutPlan/components/Templates/cooldownStepTemplate';
import getNewWorkoutStep from '../../../CreateWorkoutPlan/components/Templates/workoutStepTemplate';
import getNewRepeatStep from '../../../CreateWorkoutPlan/components/Templates/workoutRepeatStepTemplate';
import workoutTemplate from 'views/CreateWorkoutPlan/components/Templates/workoutTemplate';
// import getWorkoutTemplate from '../../../CreateWorkoutPlan/components/Templates/workoutTemplate';
import validateWorkout from '../../../CreateWorkoutPlan/components/ValidateWorkout';
import UserContext from 'context/User';
import { displayNotification } from 'api/displayNotification';

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  content: {
    textAlign: 'start',
    flexGrow: 1,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10)
    }
  },
  subTitle: {
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3)
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(2),
    backgroundColor: '#A4A4A4',
    color: '#000',
    boxShadow: 'none',
    textTransform: 'none',
    [theme.breakpoints.up('sm')]: {
      width: 'fit-content',
      minWidth: '18.91rem'
    },
    '&:hover': {
      backgroundColor: '#a4a4a4a3'
    }
  },
  fit_button: {
    flex: '1',
    [theme.breakpoints.up('sm')]: {
      width: 'fit-content',
      minWidth: 'unset'
    }
  },
  btn_margin: {
    marginRight: theme.spacing(2)
  },
  btn_padding: {
    padding: '0 2rem',
    [theme.breakpoints.up('sm')]: {
      padding: '0'
    }
  },
  iconButton: {
    marginRight: '8px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  textField: {
    width: '100%',
    maxWidth: 'unset',
    marginTop: theme.spacing(2)

    // [theme.breakpoints.up('sm')]: {
    //   maxWidth: '18.91rem',
    // },
  },
  buttons: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'inline-table'
    }
  },
  cancel_save_buttons: {
    display: 'flex',

    [theme.breakpoints.up('sm')]: {
      maxWidth: '18.9rem'
    }
  },
  box_title: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    marginBottom: '1rem'
  },
  textArea: {
    borderRadius: '4px',
    width: '100%',
    backgroundColor: 'transparent',
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'text',
    display: 'inline-flex',
    position: 'relative',
    fontSize: '1rem',
    boxSizing: 'border-box',
    alignItems: 'center',
    fontFamily: 'inherit',
    fontWeight: '400',
    lineHeight: '1.1876em',
    letterSpacing: '0.00938em',
    padding: '10.5px 14px'
  },
  mTop3: {
    marginTop: theme.spacing(3)
  },
  hoverPointer: {
    cursor: 'pointer'
  },
  workoutTitle: {
    marginBottom: 0,
    fontSize: '1.3rem',
    fontWeight: 'bold'
  }
}));

function WorkoutChangeLayout(props) {
  const {
    workout,
    handleModalClose,
    type,
    updateWeekView,
    setWorkoutName,
    setWorkoutSteps,
    setWorkout,
    updateWorkouts
    // setActiveDate
  } = props;
  const user = React.useContext(UserContext);

  const classes = useStyles();
  const [workoutData, setWorkoutData] = useState(
    workout.workout || workout.workoutTemplate || workout
  );
  const [workoutName, _setWorkoutName] = useState(
    workoutData?.workoutName || ''
  );
  const [steps, setSteps] = useState(workoutData?.steps || []);
  const [isRunner, setIsRunner] = useState(user.role == 'runner');
  const [workoutNotes, setWorkoutNotes] = useState(
    workoutData?.workoutNotes || ''
  );
  const [
    isDeleteWorkoutPlanModalOpen,
    setIsDeleteWorkoutPlanModalOpen
  ] = useState(false);

  // // This trigger is a helper variable for calling a function from child components
  // const [trigger, setTrigger] = useState(false);

  const addWarmUp = () =>
    setSteps([{ ...getWarmupStepTemplate(), runnerPace: 'EASY' }, ...steps]);

  const addWorkoutStep = () =>
    setSteps([
      ...steps,
      { ...getNewWorkoutStep('INTERVAL'), runnerPace: 'EASY' }
    ]);

  const addRepeatStep = () => {
    const newRepeatStep = getNewRepeatStep();
    newRepeatStep.steps[0].runnerPace = 'EASY';
    newRepeatStep.steps[1].runnerPace = 'EASY';
    setSteps([...steps, newRepeatStep]);
  };

  const addCoolDown = () =>
    setSteps([...steps, { ...getCooldownStepTemplate(), runnerPace: 'EASY' }]);

  const handleWorkoutNameChange = event => _setWorkoutName(event.target.value);
  const handleWorkoutNotesChange = event => setWorkoutNotes(event.target.value);

  const removeStep = step => {
    const revisedSteps = steps.filter(currentStep => currentStep != step);
    setSteps(revisedSteps);
  };

  const removeWorkoutFromDB = async workout => {
    const removedWorkout = await server.post(
      'workout/removeRunnerWorkoutFromDB',
      { ...workout }
    );
  };

  const handleRemoveWorkoutFromRunner = async () => {
    const newWorkout = { ...workout };
    if (type == 'WeeklyPlanPreviewModal') {
      newWorkout.workoutTemplate = null;
    } else {
      await removeWorkoutFromDB(workout);
      newWorkout.workout = null;
    }

    newWorkout.isWorkout = false;
    setWorkout(newWorkout);
    updateWorkouts(newWorkout);
    handleModalClose && handleModalClose();
    setIsDeleteWorkoutPlanModalOpen(false);
  };

  const updateWorkoutDisplay = workout => {
    if (type == 'MonthlyPlanModal') {
      updateWeekView();
    } else {
      // setWorkoutName(workout.workoutTemplate.workoutName);
      setWorkoutName(workoutName);
      setWorkout(workout);
      // setWorkoutSteps(workout.workoutTemplate.steps);
      ///setWorkoutSteps(steps);
    }
  };
  const saveWorkoutToDB = async workout => {
    const savedWorkout = await server.post(
      'workout/saveRunnerWorkoutToDB',
      { ...workout }
    );
  };

  const handleSaveWorkoutToRunner = async () => {
    let updatedWorkout;
    const newWorkout = {
      description: null,
      sport: 'RUNNING',
      steps,
      trainerUserId: user._id,
      workoutName,
      workoutNotes
    };

    const workoutValidationResponse = validateWorkout(newWorkout);
    if (!workoutValidationResponse.isValidWorkout) {
      displayNotification(
        workoutValidationResponse.notificationSeverity,
        workoutValidationResponse.notificationMessage
      );
      return;
    }

    const isWorkout = steps.length ? true : false;

    if (type == 'WeeklyPlanPreviewModal') {
      // workout.workoutTemplate = newWorkout;
      // workout.isWorkout = isWorkout;
      updatedWorkout = {
        ...workout,
        workoutTemplate: newWorkout,
        isWorkout: isWorkout
      };
      updateWorkouts(updatedWorkout);
      // setWorkout(updatedWorkout);
    } else if (type == 'MonthlyPlanModal') {
      updatedWorkout = {
        ...workout,
        isWorkout,
        workout: {
          ...workout.workout,
          ...newWorkout,
          runnerId: workout.runnerId
        }
      };
      await saveWorkoutToDB(updatedWorkout);
      updateWorkouts();
    } else {
      updatedWorkout = {
        ...workout,
        isWorkout,
        workout: {
          ...workout.workout,
          ...newWorkout,
          runnerId: workout.runnerId
        }
      };
      await saveWorkoutToDB(updatedWorkout);
    }
    updateWorkoutDisplay(updatedWorkout);
    handleModalClose();
  };

  return (
    <main className={classes.content} data-test={'workoutChangeLayout-main'}>
      <Grid
        direction="row"
        justifyContent="space-between" // Add it here :)
        container
        alignItems="center">
        <Grid item>
          <Typography className={classes.subTitle} variant="h3">
            <p className={classes.workoutTitle}>Update workout:</p>
            <p className={classes.workoutTitle}>
              {workout.dayOfWeek} - {workout.date}
            </p>
          </Typography>
        </Grid>
        <Grid item>
          <CloseRoundedIcon
            data-test={'workoutChangeLayout-closeRoundedIcon'}
            className={classes.hoverPointer}
            onClick={handleModalClose}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          {isRunner ? (
            workoutName
          ) : (
            <TextField
              className={classes.textField}
              data-test={'workoutChangeLayout-workoutNameTextField'}
              id="workoutName"
              label={workoutName ? '' : 'Workout name'}
              margin="dense"
              onChange={handleWorkoutNameChange}
              value={workoutName}
              variant="outlined"
            />
          )}
        </Grid>
      </Grid>
      {!isRunner && (
        <div className={classes.buttons}>
          <Button
            className={`${classes.button} ${classes.fit_button} ${classes.btn_margin}`}
            onClick={addWarmUp}
            variant="contained">
            <TrendingUpOutlinedIcon className={classes.iconButton} />
            Add Warm Up
          </Button>

          <Button
            className={`${classes.button} ${classes.fit_button} ${classes.btn_margin}`}
            onClick={addRepeatStep}
            variant="contained">
            <RepeatIcon className={classes.iconButton} />
            Add repeat
          </Button>

          <Button
            className={`${classes.button} ${classes.fit_button} ${classes.btn_margin}`}
            onClick={addWorkoutStep}
            variant="contained">
            <AddOutlinedIcon className={classes.iconButton} />
            Add step
          </Button>

          <Button
            className={`${classes.button} ${classes.fit_button}`}
            onClick={addCoolDown}
            variant="contained">
            <TrendingDownOutlinedIcon className={classes.iconButton} />
            Add Cool Down
          </Button>
        </div>
      )}

      {steps.map((step, index) => {
        if (step.type == 'WorkoutStep')
          return (
            <WorkoutStepTemplate
              // trigger={trigger}
              key={index}
              name={step.name}
              removeStep={removeStep}
              step={step}
              isRunner={isRunner}
            />
          );
        if (step.type == 'WorkoutRepeatStep')
          return (
            <RepeatStep
              // trigger={trigger}
              key={index}
              removeStep={removeStep}
              step={step}
              isRunner={isRunner}
            />
          );
      })}

      <div className={classes.mTop3}>
        <Typography className={classes.box_title} variant="h6">
          Notes
        </Typography>
        <TextareaAutosize
          aria-label="minimum height"
          disabled={isRunner}
          className={classes.textArea}
          minRows={4}
          onChange={handleWorkoutNotesChange}
          placeholder=""
          value={workoutNotes}
        />
      </div>
      {!isRunner && (
        <div className={classes.cancel_save_buttons}>
          <Button
            className={`${classes.button} ${classes.fit_button} ${classes.btn_margin}`}
            data-test={'workoutChangeLayout-removeButton'}
            style={{
              backgroundColor: '#ffffff',
              border: '1px solid #000000'
            }}
            onClick={() => setIsDeleteWorkoutPlanModalOpen(true)}
            variant="contained">
            Remove
          </Button>
          <Button
            className={`${classes.button} ${classes.fit_button}`}
            data-test={'workoutChangeLayout-saveButton'}
            onClick={handleSaveWorkoutToRunner}
            variant="contained">
            Save
          </Button>
        </div>
      )}
      <Dialog
        open={isDeleteWorkoutPlanModalOpen}
        onClose={() => setIsDeleteWorkoutPlanModalOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Remove this workout?</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this workout?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            data-test="WorkoutPlan-Components-WorkoutChangeLayout-Dialog-DialogActions-cancelRemoveWorkoutButton"
            onClick={() => setIsDeleteWorkoutPlanModalOpen(false)}>
            Cancel
          </Button>
          <Button
            data-test="WorkoutPlan-Components-WorkoutChangeLayout-Dialog-DialogActions-removeWorkoutButton"
            onClick={() => handleRemoveWorkoutFromRunner()}
            autoFocus>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </main>
  );
}

export default WorkoutChangeLayout;
