import React, { useState } from 'react';
import testimonial_shape from '../assets/img/testimonial/testimonial-shape.svg';
import testimonial_dots from '../assets/img/testimonial/testimonial-dots.svg';
import testimonial1 from '../assets/img/testimonial/sarah.svg';
import testimonial2 from '../assets/img/testimonial/mark.svg';
import testimonial3 from '../assets/img/testimonial/yael.svg';
import testimonial4 from '../assets/img/testimonial/alex.svg';

const testimonials = [
  {
    image: testimonial1,
    name: 'Sarah',
    title: 'Running Coach',
    text:
      'Sub-X revolutionized the way I train my groups. It is a game-changer!'
  },
  {
    image: testimonial2,
    name: 'Mark',
    title: 'Marathon Trainer',
    text:
      'With Sub-X, I can easily track my trainees progress and provide personalized guidance.'
  },
  {
    image: testimonial3,
    name: 'Yael',
    title: 'Fitness Enthusiast',
    text:
      'Sub-X has been an incredible tool for my fitness journey. The personalized workout plans and feedback have helped me stay motivated and achieve my goals faster than ever before.'
  },
  {
    image: testimonial4,
    name: 'Alex',
    title: 'Amatuere Athlete',
    text:
      'I can not imagine my training routine without Sub-X. Its intuitive interface, data analytics, and simplicity have optimized my performance, making my trainings far more effeciant.'
  }
];

const TestimonialSection = () => {
  const [index, setIndex] = useState(0);

  const handleNextTestimonial = () => {
    setIndex((index + 1) % testimonials.length);
  };

  const handlePreviousTestimonial = () => {
    setIndex((index - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <section id="testimonials" className="testimonial-section">
      <div className="container">
        <div className="section-title text-center">
          <h2 className="mb-30">What our customers say</h2>
        </div>
        <div className="testimonial-active-wrapper">
          <div className="shapes">
            <img
              alt=""
              className="shape shape-1"
              data-pagespeed-url-hash="4007555226"
              src={testimonial_shape}
            />
            <img
              alt=""
              className="shape shape-2"
              data-pagespeed-url-hash="1224640497"
              src={testimonial_dots}
            />
          </div>

          <div className="tns-outer" id="tns1-ow">
            <div
              className="tns-controls"
              aria-label="Carousel Navigation"
              tabIndex="0">
              <button
                type="button"
                data-controls="prev"
                tabIndex="-1"
                aria-controls="tns1"
                onClick={handlePreviousTestimonial}>
                <i className="lni lni-chevron-left prev"></i>
              </button>
              <button
                type="button"
                data-controls="next"
                tabIndex="-1"
                aria-controls="tns1"
                onClick={handleNextTestimonial}>
                <i className="lni lni-chevron-right next"></i>
              </button>
            </div>
            <div
              className="tns-liveregion tns-visually-hidden"
              aria-live="polite"
              aria-atomic="true">
              slide <span className="current">{index + 1}</span> of
              {testimonials.length}
            </div>
            <div className="tns-inner" id="tns1-iw">
              <div
                className="testimonial-active tns-slider tns-gallery tns-subpixel tns-calc tns-horizontal"
                id="tns1">
                <div
                  className="single-testimonial tns-item tns-slide-active tns-fadeIn"
                  id="tns1-item0"
                  style={{ left: '0%', display: 'block' }}>
                  <div className="row">
                    <div className="col-xl-5 col-lg-5">
                      <div className="testimonial-img">
                        <img alt="" src={testimonials[index].image} />
                        <div className="quote">
                          <i className="lni lni-quotation"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 offset-xl-1 col-lg-6 offset-lg-1">
                      <div className="content-wrapper">
                        <div className="content">
                          <p>{testimonials[index].text}</p>
                        </div>
                        <div className="info">
                          <h4>{testimonials[index].name}</h4>
                          <p>{testimonials[index].title}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
