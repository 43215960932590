import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  imgContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  img: {
    height: '100%',
    backgroundImage: 'url(/images/auth.png)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    filter: 'brightness(85%)'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  contentHeader: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingTop: theme.spacing(2),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flex: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    flexBasis: 700,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  formFooter: {
    textAlign: 'center',
    paddingBottom: theme.spacing(3)
  },
  optionsTitle: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(1)
  },
  buttonsWrapper: {
    display: 'flex'
  },
  optionButton: {
    flex: 1,
    margin: theme.spacing(1),
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white
    }
  },
  selectedBtn: {
    flex: 1,
    margin: theme.spacing(1),
    textTransform: 'none',
    background: theme.palette.primary.main,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white
    }
  },
  signUpButton: {
    margin: theme.spacing(2, 0),
    textTransform: 'none',
    '&:disabled': {
      color: theme.palette.black
    }
  }
}));

export default useStyles;
