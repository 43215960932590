/* eslint-disable react/no-multi-comp */

import {
  Backdrop,
  IconButton,
  Paper,
  Popover,
  TextField,
  Typography
} from '@material-ui/core';
import { ArrowLeftRounded, ArrowRightRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import PreferencesContext from 'context/Preferences/PreferencesContext';
import React, { useEffect, useState } from 'react';
const useStyles = makeStyles(theme => ({
  popover: {
    '& .MuiPaper-root': {
      borderRadius: '5px',
      padding: '15px 5px',
      height: '365px'
    },
    '& .weekselector-day-names': {
      display: 'flex',
      '& .weekselector-day-name': {
        width: '40px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        opacity: '0.5'
      }
    },
    '& .weekselector-week': {
      display: 'flex',
      background: 'transparent',
      transition: '0.3s ease',
      borderRadius: '5px',
      '&:hover': {
        background: '#f5f5f5',
        cursor: 'pointer'
      },
      '&.weekselector-selected:hover': {
        background: theme.palette.primary.main,
        cursor: 'default'
      },
      '& .weekselector-day': {
        width: '40px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        position: 'relative'
      },

      '& .weekselector-disabled': {
        opacity: '0.5'
      },
      '& .weekselector-highlight': {
        display: 'block',
        width: '4px',
        height: '4px',
        borderRadius: '50%',
        position: 'absolute',
        bottom: '5px',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }
    },
    '& .weekselector-week:not(.weekselector-selected)': {
      '& .weekselector-highlighted p': {
        color: theme.palette.primary.main,
        fontWeight: 'bold'
      },
      '& .weekselector-highlight': {
        background: theme.palette.primary.main
      }
    },
    '& .weekselector-selected': {
      background: theme.palette.primary.main,
      '& .MuiTypography-root': { color: 'white' },
      '& .weekselector-highlighted p': {
        color: 'white',
        fontWeight: 'bold'
      },
      '& .weekselector-highlight': {
        background: 'white'
      }
    },
    '& .weekselector-header-actions': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '10px',
      '& .weekselector-month': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
      }
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const Header = ({ startDay, currentDate, setCurrentDate }) => {
  const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  const shiftedDays = days.slice(startDay).concat(days.slice(0, startDay));
  return (
    <>
      <div className="weekselector-header-actions">
        <IconButton
          onClick={() =>
            setCurrentDate(
              new Date(currentDate.getTime() - 1000 * 60 * 60 * 24 * 30)
            )
          }
          size="small">
          <ArrowLeftRounded fontSize="large" />
        </IconButton>
        <div
          className="weekselector-month"
          data-test={'WeekSelector-headerComponent'}>
          <Typography variant="h5">
            {currentDate.toLocaleString('default', { month: 'long' })}
          </Typography>
          <Typography variant="body2">{currentDate.getFullYear()}</Typography>
        </div>

        <IconButton
          onClick={() =>
            setCurrentDate(
              new Date(currentDate.getTime() + 1000 * 60 * 60 * 24 * 30)
            )
          }
          size="small">
          <ArrowRightRounded fontSize="large" />
        </IconButton>
      </div>
      <div className="weekselector-day-names">
        {shiftedDays.map((day, index) => (
          <div className="weekselector-day-name" key={index}>
            <Typography variant="body2">{day}</Typography>
          </div>
        ))}
      </div>
    </>
  );
};

const Row = ({ week, selectedWeek, setSelectedWeek, highlightedDates }) => {
  const days = [];
  for (let i = 0; i < 7; i++) {
    const date = new Date(week.start.getTime() + i * 24 * 60 * 60 * 1000);
    days.push([date.getDate(), date]); // used an array for efficincy
  }

  const isHighlighted = date => {
    if (!highlightedDates) {
      return false;
    }

    for (let i = 0; i < highlightedDates.length; i++) {
      if (
        new Date(highlightedDates[i].dateObject).getTime() === date.getTime()
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <div
      className={`weekselector-week ${
        week.start.getTime() === selectedWeek.start.getTime()
          ? 'weekselector-selected'
          : ''
      }`}
      onClick={() => setSelectedWeek(week)}>
      {days.map((day, index) => (
        <div
          className={`weekselector-day ${
            week.disabledDays.includes(index) ? 'weekselector-disabled' : ''
          } ${isHighlighted(day[1]) ? 'weekselector-highlighted' : ''}`}
          key={index}>
          <Typography variant="body2">{day[0]}</Typography>
          {isHighlighted(day[1]) && (
            <div className="weekselector-highlight"></div>
          )}
        </div>
      ))}
    </div>
  );
};

const WeekSelector = ({
  setDate,
  highlightedDates = [],
  startDate = new Date(),
  dialog = false,
  onClose = () => {}
}) => {
  const classes = useStyles();
  const preferences = React.useContext(PreferencesContext);
  const startDay = preferences.get('startDay');
  const [intilized, setIntilized] = useState(false);
  const [currentDate, setCurrentDate] = useState(startDate);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [weeks, setWeeks] = useState([]);
  const getMonthStart = date => {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    return firstDayOfMonth;
  };

  const getMonthEnd = date => {
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return lastDayOfMonth;
  };

  const selectWeek = week => {
    setSelectedWeek(week);
    setDate(week.start);
    setAnchorEl(null);
    onClose();
  };

  const splitMonthToWeeks = (monthStart, monthEnd) => {
    const weekStarts = [];
    let currentDate = new Date(monthStart);
    while (currentDate <= monthEnd) {
      if (currentDate.getDay() === startDay) {
        weekStarts.push(new Date(currentDate));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    if (weekStarts.length > 0 && weekStarts[0].getDate() !== 1) {
      weekStarts.unshift(
        new Date(weekStarts[0].getTime() - 7 * 24 * 60 * 60 * 1000)
      );
    }

    const weeks = weekStarts.map(weekStart => {
      const weekEnd = new Date(
        new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000).getTime() - 1
      );
      const daysOutsideMonth = [];
      for (let i = 0; i < 7; i++) {
        const date = new Date(weekStart.getTime() + i * 24 * 60 * 60 * 1000);
        if (date.getMonth() !== monthStart.getMonth()) {
          daysOutsideMonth.push(i);
        }
      }
      return {
        start: weekStart,
        end: weekEnd,
        disabledDays: daysOutsideMonth // this is for gui only
      };
    });
    return weeks;
  };

  const formatWeekInput = week => {
    if (!week) return 'Select a week';
    const start = week.start.toLocaleDateString('default', {
      month: 'short',
      day: 'numeric'
    });
    const end = week.end.toLocaleDateString('default', {
      month: 'short',
      day: 'numeric'
    });
    return `${start} - ${end}`;
  };

  useEffect(() => {
    const monthStartDate = getMonthStart(currentDate);
    const monthEndDate = getMonthEnd(currentDate);
    const weeks = splitMonthToWeeks(monthStartDate, monthEndDate);
    setWeeks(weeks);
    if (intilized) return;
    if (weeks.length < 1) return;

    const presentWeek = weeks.find(week => {
      const weekStart = new Date(week.start.getTime());
      const weekEnd = new Date(week.end.getTime());
      return (
        currentDate.getTime() >= weekStart.getTime() &&
        currentDate.getTime() <= weekEnd.getTime()
      );
    });
    if (presentWeek) {
      setSelectedWeek(presentWeek);
      if (!dialog) setDate(presentWeek.start);
    }
    setIntilized(true);
  }, [currentDate]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      {dialog && (
        <Backdrop open={dialog} onClick={onClose} className={classes.backdrop}>
          <div
            className={classes.popover + ' weekselector-popover'}
            data-test={'WeekSelector-datePickerComponent'}
            onClick={e => e.stopPropagation()}>
            <Paper>
              <Header
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
                startDay={startDay}
              />
              {weeks.map((week, index) => (
                <Row
                  key={index}
                  selectedWeek={selectedWeek}
                  highlightedDates={highlightedDates}
                  setSelectedWeek={selectWeek}
                  week={week}
                />
              ))}
            </Paper>
          </div>
        </Backdrop>
      )}
      {!dialog && (
        <>
          <TextField
            label="Select Week"
            onClick={handleClick}
            readOnly
            value={formatWeekInput(selectedWeek)}
          />
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            className={classes.popover + ' weekselector-popover'}
            id={'week-selector'}
            onClose={handleClose}
            open={Boolean(anchorEl)}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}>
            <Header
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              startDay={startDay}
            />
            {weeks.map((week, index) => (
              <Row
                key={index}
                selectedWeek={selectedWeek}
                setSelectedWeek={selectWeek}
                highlightedDates={highlightedDates}
                week={week}
              />
            ))}
          </Popover>
        </>
      )}
    </React.Fragment>
  );
};

export default WeekSelector;
