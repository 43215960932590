import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
// import TableCell from '@material-ui/core/TableCell';
// import TableRow from '@material-ui/core/TableRow';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
// import DesktopButtonsGroup from './DesktopButtonsGroup';
import ConfirmationModal from './ConfirmationModal';
import DesktopWeeklyPlanTableHeading from './DesktopWeeklyPlanTableHeading';
import DesktopWeeklyPlanWorkoutLine from './DesktopWeeklyPlanWorkoutLine';

const useStyles = makeStyles(theme => ({
  // table: { width: '100%' },
  tableStyle: {
    border: 'none',
    // backgroundColor: '#efefef',
    '& > *': { border: 'none' }
  }
  // tblRow: { '& > *': { padding: 5 } },
  // tblCell: { border: 0, paddingTop: 12, paddingBottom: 12 },
}));

export default function DesktopWeeklyPlan(props) {
  const {
    runnerWeeklyWorkoutPlan,
    updateWeekView,
    type,
    handleWatchSyncIconClick
  } = props;
  const classes = useStyles();

  const [workouts, setWorkouts] = useState(
    runnerWeeklyWorkoutPlan.runnerWorkoutsPlan ||
      runnerWeeklyWorkoutPlan.workouts
  );


  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateWorkouts = updatedWorkout => {
    const updatedWorkouts = workouts.map(workout => {
      if (updatedWorkout.date === workout.date) {
        workout = { ...updatedWorkout };
      }
      return workout;
    });
    runnerWeeklyWorkoutPlan.runnerWorkoutsPlan = [...updatedWorkouts];
    
    setWorkouts([...updatedWorkouts]);
  };

  return (
    <>
      <Grid
        justifyContent="space-between"
        container
        style={{ paddingTop: 20, paddingBottom: 20 }}>
        <Grid item xs={6}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"></IconButton>
        </Grid>
        {/* <Grid item>
          <DesktopButtonsGroup
            handleOpen={() => {
              handleOpen();
            }}
          />
        </Grid> */}

        <Grid item xs={12}>
          <TableContainer>
            <Table className={classes.tableStyle}>
              <DesktopWeeklyPlanTableHeading />
              <TableBody>
                {workouts.map((workout, index) => (
                  <DesktopWeeklyPlanWorkoutLine
                    handleWatchSyncIconClick={handleWatchSyncIconClick}
                    workoutData={workout}
                    key={index}
                    updateWeekView={updateWeekView}
                    updateWorkouts={updateWorkouts}
                    type={type}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/* <ConfirmationModal
        open={open}
        handleClose={() => {
          handleClose();
        }}
      /> */}
    </>
  );
}
