import React from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import server from 'api/server';

import RunnerWeeklyPlan from './RunnerWeeklyPlan';
import DatePicker from './DatePicker/DatePicker';
import UserContext from 'context/User';
import WeekSelector from 'components/WeekSelector';

const useStyles = makeStyles(theme => ({
  mainBox: {
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20
    }
  },

  content: {
    flexGrow: 1,
    width: '100%',
    padding: theme.spacing(2)
    // marginTop: 80,
    // backgroundColor: '#e6e6e6'
  },

  headingContent: {
    // color: '#5157F8',
    // fontWeight: 500,
    // marginLeft: 18,
    [theme.breakpoints.down('sm')]: {
      // fontWeight: 400,
      // fontSize: 20,
      marginLeft: 0
    }
  },
  // mainGrid: {
  //   paddingTop: 20,
  //   paddingBottom: 20,
  //   [theme.breakpoints.down('sm')]: {
  //     paddingTop: 0,
  //     paddingBottom: 0
  //   }
  // },

  smOnly: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  mdPlusOnly: {
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  customButton: {
    // backgroundColor: '#5157F8',
    // color: '#FFFFFF',
    fontWeight: 500,
    boxShadow: 'none',
    textTransform: 'none',
    marginTop: 7,
    paddingLeft: 30,
    paddingRight: 30
  },
  marginLeftAuto: {
    marginLeft: 'auto'
  },
  marginRightAuto: {
    marginRight: 'auto'
  },
  icon: {
    cursor: 'pointer'
  },
  hoverPointer: {
    cursor: 'pointer'
  }
}));

const MainWeeklyWorkoutPlan = props => {
  const { weeklyWorkoutPlan, handleModalClose, updateWeekView, type, setDate } = props;
  const user = React.useContext(UserContext);

  const history = useHistory();
  const classes = useStyles();

  const handleSendToRunners = async () => {
    const response = await server.post(
      'workout/toRunners',
      { weeklyWorkoutPlan }
    );
    history.push('/workoutPlan');
  };

  const getWeeklyPlanTitle = () => {
    if (0 == weeklyWorkoutPlan.length)
      return '- No workouts are planned for this week';
    const weekDates =
      type == 'WeeklyPlanPreviewModal'
        ? `-> ${weeklyWorkoutPlan[0].runnerWorkoutsPlan[0].date} to ${weeklyWorkoutPlan[0].runnerWorkoutsPlan[6].date}`
        : `-> ${weeklyWorkoutPlan[0].workouts[0].date} to ${weeklyWorkoutPlan[0].workouts[6].date}`;
    return type == 'WeeklyPlanPreviewModal'
      ? `Weekly Plan Preview ${weekDates}`
      : `Weekly Plan ${weekDates}`;
  };

  return (
    <main className={classes.content}>
      <Grid
        direction="row"
        justifyContent="space-between" // Add it here :)
        container
        alignItems="center"
        // className={classes.mainGrid}
      >
        <Grid item xs={9}>
          <Typography variant="h3" className={classes.headingContent}>
            {getWeeklyPlanTitle()}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {user.role == 'trainer' && !type ? (
            <WeekSelector setDate={setDate} />
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={1} style={{ textAlign: 'right' }}>
          {type == 'WeeklyPlanPreviewModal' ? (
            <CloseRoundedIcon
              className={classes.hoverPointer}
              onClick={handleModalClose}
            />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Box className={classes.mainBox}>
        {weeklyWorkoutPlan.map((runnerWeeklyWorkoutPlan, index) => {
          return (
            <RunnerWeeklyPlan
              updateWeekView={updateWeekView}
              key={index}
              runnerWeeklyWorkoutPlan={runnerWeeklyWorkoutPlan}
              type={type}
            />
          );
        })}
        {type == 'WeeklyPlanPreviewModal' && (
          <div className={classes.smOnly}>
            <Button
              className={classes.customButton}
              color="primary"
              data-test={'mainWeeklyWorkoutPlan-mobileView-sendToRunnersButton'}
              variant="contained"
              size="small"
              style={{
                display: 'block',
                width: '100%',
                fontSize: 16,
                paddingTop: 7,
                paddingBottom: 7
              }}
              onClick={handleSendToRunners}>
              Send to Runners
            </Button>
          </div>
        )}
      </Box>
      <Grid container justifyContent="center">
        <Grid item className={classes.mdPlusOnly}>
          {type == 'WeeklyPlanPreviewModal' &&
            !weeklyWorkoutPlan.filter(e => e.workouts).length > 0 && (
            <Button
              color="primary"
              className={classes.customButton}
              data-test={
                'mainWeeklyWorkoutPlan-wideScreenView-sendToRunnersButton'
              }
              variant="contained"
              size="small"
              onClick={handleSendToRunners}>
                Send to Runners
            </Button>
          )}
        </Grid>
      </Grid>
    </main>
  );
};

export default MainWeeklyWorkoutPlan;
